import React, { useEffect } from "react";
import GetInTouch from "../../components/GetInTouch";
import CustomLink from "../../components/Common/CustomLink";
import BannerImage from "../../assets/images/tmp/blog-detail.png";
import Facebook from "../../assets/images/social-icons/facebook.svg";
import Instagram from "../../assets/images/social-icons/instagram.svg";
import Twitter from "../../assets/images/social-icons/twitter.svg";
import Linkedin from "../../assets/images/social-icons/linkedin.svg";

// import "../../assets/css/common.scss";
import "../../assets/css/pages/blog-detail.scss";
import { useDispatch, useSelector } from "react-redux";
import { blogDetailsActions } from "../../Stores/Actions/blogAction";
import { Link, useNavigate, useParams } from "react-router-dom";
const BlogDetailPage = () => {
  const dispatch = useDispatch();
  // const params = useParams();
  const blogId =  localStorage.getItem("blogId");
   let navigate = useNavigate();

  // get blog details data from store
  const blogDetail = useSelector(
    (state) => state?.blogReducer?.blogDetails?.data
  );

  useEffect(() => {
    dispatch(blogDetailsActions(blogId));
  }, [dispatch]);

  console.log("blogDetail",blogDetail)
  return (
   <div className="wrapper">
     { blogDetail && <div className="page-blog-detail">
        <div className="blog-banner-main">
          <div
            className="blog-banner"
            style={{ backgroundImage: `url(${BannerImage})` }}
          >
            <div className="container">
              <div className="banner-content">
                <div className="section-title">
                  <h1>Insights — Blogs</h1>
                  <h3 title={blogDetail?.detail?.title}>{blogDetail?.detail?.title.substring(0, 100) + (blogDetail?.detail?.title.length > 80 ? "...": "")}</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="banner-content-image-main">
            <div className="container">
              <div
                className="banner-content-image"
                style={{ backgroundImage: `url(${blogDetail?.detail?.image})` }}
              ></div>
            </div>
          </div>
        </div>
        <div className="blog-detail-content">
          <div className="container">
            <div
              dangerouslySetInnerHTML={{
                __html: blogDetail?.detail?.description,
              }}
            />
          </div>
        </div>
        <div className="blog-pagination">
          <div className="container">
          {blogDetail?.related_post?.length > 1 && <div className="row-wrap">
              <div className="no-gutters row">
                <div className="col blog-col blog-title">
                  <button type="button" className="btn-next">
                    Next
                  </button>
                </div>
                <div className="col blog-col">
                  <h3>
                    <Link
                      to={
                        // blogDetail?.related_post?.[1] ?
                        `${blogDetail?.related_post?.[1]?.title}`
                        
                      }
                      onClick={()=>{
                        localStorage.setItem("blogId",blogDetail?.related_post?.[1]?.id);
                        // window.location.href = `insights/blog/${blogDetail?.related_post?.[1]?.title}`
                        // navigate(`insights/blog/${blogDetail?.related_post?.[1]?.title}` )
                      }
                       
                        
                        }
                    >
                      {blogDetail?.related_post?.[1]?.title}
                    </Link>
                  </h3>
                </div>
                <div className="col blog-col">
                  <p>
                    {blogDetail?.related_post?.[1]?.description?.substring(0, 230)+ (blogDetail?.related_post?.[1]?.description?.length > 200 ? "..." : "")}
                  </p>
                </div>
              </div>
             <div className="no-gutters row">
                <div className="col blog-col blog-title">
                  <button type="button" className="btn-pre">
                    Prev
                  </button>
                </div>
                <div className="col blog-col">
                  <h3>
                    <Link  
                      to={
                        // blogDetail?.related_post?.[0] ?
                        `${blogDetail?.related_post?.[0]?.title}` 
                      }
                      onClick={()=>{
                        localStorage.setItem("blogId",blogDetail?.related_post?.[0]?.id)
                        // navigate(`insights/blog/${blogDetail?.related_post?.[0]?.title}` )
                      }}
                    >
                      {blogDetail?.related_post?.[0]?.title}
                    </Link>
                  </h3>
                </div>
                <div className="col blog-col">
                  <p>
                    {blogDetail?.related_post?.[0]?.description?.substring(0, 230)+ (blogDetail?.related_post?.[1]?.description?.length > 200 ? "..." : "")}
                  </p>
                </div>
              </div>
            </div>}
            <div className="explore-btn">
              <div className="row no-gutters">
                <div className="col blog-title"></div>
                <div className="col">
                  <CustomLink to="/insights" renderSvg={true}>
                    <h5>Explore the Journal </h5>
                  </CustomLink>
                </div>
                <div className="col"></div>
              </div>
            </div>
          </div>
        </div>
      </div>}
      <GetInTouch />
    </div>
  );
};
export default BlogDetailPage;
