import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { ReactComponent as RightArrow } from "../assets/images/icon/gray-arrow-right.svg";
const GetInTouch = () => {
    const [scroll, setScroll] = useState(false);

    useEffect(() => {
        const element = document.getElementById("getInTouch");
        let pos = element.offsetTop - (window.innerHeight);
        window.addEventListener("scroll", () => {
            if (!scroll && window.scrollY > pos) {
                setScroll(true)
            }
        });
        return () => {
            window.removeEventListener('scroll', () => {
                setScroll(false)
            })
        }
    }, []);
    return (
        <section id="getInTouch" className={scroll ? "section-get-in-touch animate" : "section-get-in-touch"}>
            <div className="container">
                <div className="row">
                    <div className="col left-content">
                        <div className="content-wrap">
                            <h2>Take the first step</h2>
                            <p>
                            Let’s talk about how digital can work for your business.{" "}
                            </p>
                        </div>
                    </div>
                    <div className="col right-content">
                        <div className="get-in-touch-btn">
                            <Link to="/contact-us">
                                Get in touch <RightArrow className="svg-animation"/>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default GetInTouch;
