import React, { useState } from 'react'
import { Link } from "react-router-dom";
import BannerContent from '../../components/BannerContent'
import BannerImage from "../../assets/images/refer-frd/banner.png";
import Accsideimg from "../../assets/images/refer-frd/accordian-img.png";
import "../../assets/css/pages/refer-frd.scss";
import "../../assets/css/component/about-section.scss";
import "../../assets/css/pages/about.scss";
import CustomLink from "../../components/Common/CustomLink";
import refer1 from "../../assets/images/refer-frd/refer2.png";
import refer2 from "../../assets/images/refer-frd/refer1.png";
import { ReactComponent as PaperClipIcon } from "../../assets/images/icon/paperclip.svg";
import { ReactComponent as RightArrow } from "../../assets/images/icon/gray-arrow-right.svg";
import { Select } from "antd";
import { BsChevronDown } from "react-icons/bs";
import plus from "../../assets/images/refer-frd/plus.svg"

const ReferFriend = () => {

    // accordians start

    const [showAccordion, setAccordion] = useState(null);

    const toggleAccordion = (index) => {
        if (showAccordion === index) {
            setAccordion(null);
        } else {
            setAccordion(index);
        }
    };
    // accordians end

    // select start
    const provinceData = [
        'Senior Software Engineer',
        'Web Developer',
        'Frontend Developer',
        'QA'
    ];
    // select end

    return (
        <div className='main-refer-frd'>
            <BannerContent BackgroundImages={BannerImage} title={'Referral Program '} subTitle={'Don’t hesitate to provide us with a referral!'}>
            </BannerContent>

            <div className="refer-frd-accordian ">
                <div className='accordion-wrapper'>
                    {/* <div className='container'> */}
                        <div className="row">
                            <div className="col refer-acc-col">
                                <div className='refer-frd-title'>
                                    <div className="section-title">
                                        <h2> How it works?</h2>
                                        <h3>Program Rules</h3>
                                    </div>

                                </div>
                                <div className="accordion">
                                    <div className="card">
                                        <div className={"card-title"}>
                                            <button
                                                onClick={() => {
                                                    toggleAccordion(1);
                                                }}
                                                className={
                                                    showAccordion === 1
                                                        ? "faq-btn open-btn"
                                                        : "faq-btn"
                                                }
                                            >
                                                How can I submit a referral?
                                            </button>
                                        </div>
                                        <div
                                            className={
                                                showAccordion === 1
                                                    ? `card-content-wrap open-accordion`
                                                    : "card-content-wrap"
                                            }
                                        >
                                            <div className="card-content">
                                                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Impedit perspiciatis optio distinctio odit quaerat cupiditate delectus quo veniam velit error accusamus reiciendis, beatae quos nisi amet reprehenderit totam deserunt quas.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className={"card-title"}>
                                            <button
                                                onClick={() => {
                                                    toggleAccordion(2);
                                                }}
                                                className={
                                                    showAccordion === 2
                                                        ? "faq-btn open-btn"
                                                        : "faq-btn"
                                                }
                                            >
                                                Requirements for the referral
                                            </button>
                                        </div>
                                        <div
                                            className={
                                                showAccordion === 2
                                                    ? `card-content-wrap open-accordion`
                                                    : "card-content-wrap"
                                            }
                                        >
                                            <div className="card-content">
                                                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Impedit perspiciatis optio distinctio odit quaerat cupiditate delectus quo veniam velit error accusamus reiciendis, beatae quos nisi amet reprehenderit totam deserunt quas.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className={"card-title"}>
                                            <button
                                                onClick={() => {
                                                    toggleAccordion(3);
                                                }}
                                                className={
                                                    showAccordion === 3
                                                        ? "faq-btn open-btn"
                                                        : "faq-btn"
                                                }
                                            >
                                                What kind of bonus will I receive?
                                            </button>
                                        </div>
                                        <div
                                            className={
                                                showAccordion === 3
                                                    ? `card-content-wrap open-accordion`
                                                    : "card-content-wrap"
                                            }
                                        >
                                            <div className="card-content">
                                                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Impedit perspiciatis optio distinctio odit quaerat cupiditate delectus quo veniam velit error accusamus reiciendis, beatae quos nisi amet reprehenderit totam deserunt quas.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className={"card-title"}>
                                            <button
                                                onClick={() => {
                                                    toggleAccordion(4);
                                                }}
                                                className={
                                                    showAccordion === 4
                                                        ? "faq-btn open-btn"
                                                        : "faq-btn"
                                                }
                                            >
                                                You will not receive the bonus if:
                                            </button>
                                        </div>
                                        <div
                                            className={
                                                showAccordion === 4
                                                    ? `card-content-wrap open-accordion`
                                                    : "card-content-wrap"
                                            }
                                        >
                                            <div className="card-content">
                                                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Impedit perspiciatis optio distinctio odit quaerat cupiditate delectus quo veniam velit error accusamus reiciendis, beatae quos nisi amet reprehenderit totam deserunt quas.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col ">
                                <div className="acc-side-img">
                                    <img src={Accsideimg} alt="" />
                                </div>
                            {/* </div> */}
                        </div>
                    </div>
                </div>


            </div>

            <div className="refer-bonus-amt">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className='refer-frd-title'>
                                <div className="section-title">
                                    <h2> Clientale</h2>
                                    <h3>Bonus Amount</h3>
                                    <p>Founded in 2013, Betacraft is a leading global technology consultancy <br />hat integrates strategy, design and software engineering.</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className='refer-slider-wrap'>
                    <div className="sliders-wrap">

                        <div id="css2Marquee1" className="slider-first horizontalMarque">
                            {/* <Slider {...slider_first}> */}
                            <div className="slider-item">
                                <div className="slider-content">
                                    <div className="slide-cont-ttl">
                                        <h5>Software Engineer	</h5>
                                        <p>Middle / Senior</p>
                                    </div>
                                    <div className="slide-cont-ttl">
                                        <h5>$ 500 / 750</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <div className="slide-cont-ttl">
                                        <h5>Test Engineer		</h5>
                                        <p>Middle / Senior</p>
                                    </div>
                                    <div className="slide-cont-ttl">
                                        <h5>$ 500 / 750</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <div className="slide-cont-ttl">
                                        <h5>Test Lead	</h5>
                                        <p>Middle / Senior</p>
                                    </div>
                                    <div className="slide-cont-ttl">
                                        <h5>$ 500 / 750</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <div className="slide-cont-ttl">
                                        <h5>Technocal lead</h5>
                                        <p>Middle / Senior</p>
                                    </div>
                                    <div className="slide-cont-ttl">
                                        <h5>$ 500 / 750</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <div className="slide-cont-ttl">
                                        <h5>Software Engineer	</h5>
                                        <p>Middle / Senior</p>
                                    </div>
                                    <div className="slide-cont-ttl">
                                        <h5>$ 500 / 750</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <div className="slide-cont-ttl">
                                        <h5>Software Engineer	</h5>
                                        <p>Middle / Senior</p>
                                    </div>
                                    <div className="slide-cont-ttl">
                                        <h5>$ 500 / 750</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <div className="slide-cont-ttl">
                                        <h5>Software Engineer	</h5>
                                        <p>Middle / Senior</p>
                                    </div>
                                    <div className="slide-cont-ttl">
                                        <h5>$ 500 / 750</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <div className="slide-cont-ttl">
                                        <h5>Software Engineer	</h5>
                                        <p>Middle / Senior</p>
                                    </div>
                                    <div className="slide-cont-ttl">
                                        <h5>$ 500 / 750</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <div className="slide-cont-ttl">
                                        <h5>Software Engineer	</h5>
                                        <p>Middle / Senior</p>
                                    </div>
                                    <div className="slide-cont-ttl">
                                        <h5>$ 500 / 750</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <div className="slide-cont-ttl">
                                        <h5>Software Engineer	</h5>
                                        <p>Middle / Senior</p>
                                    </div>
                                    <div className="slide-cont-ttl">
                                        <h5>$ 500 / 750</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <div className="slide-cont-ttl">
                                        <h5>Software Engineer	</h5>
                                        <p>Middle / Senior</p>
                                    </div>
                                    <div className="slide-cont-ttl">
                                        <h5>$ 500 / 750</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <div className="slide-cont-ttl">
                                        <h5>Software Engineer	</h5>
                                        <p>Middle / Senior</p>
                                    </div>
                                    <div className="slide-cont-ttl">
                                        <h5>$ 500 / 750</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <div className="slide-cont-ttl">
                                        <h5>Software Engineer	</h5>
                                        <p>Middle / Senior</p>
                                    </div>
                                    <div className="slide-cont-ttl">
                                        <h5>$ 500 / 750</h5>
                                    </div>
                                </div>
                            </div>
                            {/* </Slider> */}
                        </div>
                        <div id="css2Marquee2" className="slider-first horizontalMarque horizontalMarque2">
                            {/* <Slider {...slider_second}> */}
                            <div className="slider-item">
                                <div className="slider-content">
                                    <div className="slide-cont-ttl">
                                        <h5>Software Engineer	</h5>
                                        <p>Middle / Senior</p>
                                    </div>
                                    <div className="slide-cont-ttl">
                                        <h5>$ 500 / 750</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <div className="slide-cont-ttl">
                                        <h5>Software Engineer	</h5>
                                        <p>Middle / Senior</p>
                                    </div>
                                    <div className="slide-cont-ttl">
                                        <h5>$ 500 / 750</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <div className="slide-cont-ttl">
                                        <h5>Software Engineer	</h5>
                                        <p>Middle / Senior</p>
                                    </div>
                                    <div className="slide-cont-ttl">
                                        <h5>$ 500 / 750</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <div className="slide-cont-ttl">
                                        <h5>Software Engineer	</h5>
                                        <p>Middle / Senior</p>
                                    </div>
                                    <div className="slide-cont-ttl">
                                        <h5>$ 500 / 750</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <div className="slide-cont-ttl">
                                        <h5>Software Engineer	</h5>
                                        <p>Middle / Senior</p>
                                    </div>
                                    <div className="slide-cont-ttl">
                                        <h5>$ 500 / 750</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <div className="slide-cont-ttl">
                                        <h5>Software Engineer	</h5>
                                        <p>Middle / Senior</p>
                                    </div>
                                    <div className="slide-cont-ttl">
                                        <h5>$ 500 / 750</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <div className="slide-cont-ttl">
                                        <h5>Software Engineer	</h5>
                                        <p>Middle / Senior</p>
                                    </div>
                                    <div className="slide-cont-ttl">
                                        <h5>$ 500 / 750</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <div className="slide-cont-ttl">
                                        <h5>Software Engineer	</h5>
                                        <p>Middle / Senior</p>
                                    </div>
                                    <div className="slide-cont-ttl">
                                        <h5>$ 500 / 750</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <div className="slide-cont-ttl">
                                        <h5>Software Engineer	</h5>
                                        <p>Middle / Senior</p>
                                    </div>
                                    <div className="slide-cont-ttl">
                                        <h5>$ 500 / 750</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <div className="slide-cont-ttl">
                                        <h5>Software Engineer	</h5>
                                        <p>Middle / Senior</p>
                                    </div>
                                    <div className="slide-cont-ttl">
                                        <h5>$ 500 / 750</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <div className="slide-cont-ttl">
                                        <h5>Software Engineer	</h5>
                                        <p>Middle / Senior</p>
                                    </div>
                                    <div className="slide-cont-ttl">
                                        <h5>$ 500 / 750</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <div className="slide-cont-ttl">
                                        <h5>Software Engineer	</h5>
                                        <p>Middle / Senior</p>
                                    </div>
                                    <div className="slide-cont-ttl">
                                        <h5>$ 500 / 750</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <div className="slide-cont-ttl">
                                        <h5>Software Engineer	</h5>
                                        <p>Middle / Senior</p>
                                    </div>
                                    <div className="slide-cont-ttl">
                                        <h5>$ 500 / 750</h5>
                                    </div>
                                </div>
                            </div>
                            {/* </Slider> */}
                        </div>
                        
                    </div>
                </div>
            </div>

            <div className="refer-now-form">
                <div className='refer-frd-title'>
                    <div className="section-title">
                        <h2> Refer Now</h2>
                        <h3>Referring a Friend</h3>
                    </div>
                </div>
                <div className="refer-frd-form-cont ">
                    <div className="section-csr">

                        <div className="csr-slider-wrap">
                            <div className="row">
                                <div className="col left-image-col ">
                                    <div className="left-image bg-image" style={{ backgroundImage: `url(${refer1})` }}></div>
                                </div>
                                <div className="col refer-form-main-sec">
                                    <div className='refer-form-des contact-form-popup career-detail-form-popup'>
                                        <div className="content-box">
                                            <form>
                                                <div className="form-group-row row ">
                                                    <div className="col">
                                                        <Select
                                                            suffixIcon={<BsChevronDown />}
                                                            defaultValue={provinceData[0]}
                                                            options={provinceData.map((province) => ({ label: province, value: province }))}
                                                        />  
                                                    </div>
                                                </div>

                                                <div className="form-group-row row ">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <h5>Your Info</h5>
                                                            <input
                                                                type="text"
                                                                placeholder="Your Full Name *"
                                                                className="form-control"
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <h5>Referral’s Info</h5>
                                                            <input
                                                                type="text"
                                                                placeholder="Referral’s Full Name *"
                                                                className="form-control"
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group-row row ">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                placeholder="Your Email *"
                                                                className="form-control"
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                placeholder="Referral’s Linkedin"
                                                                className="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-group file-group checkbox-group">
                                                    <div className="file-group-wrap">
                                                        <div className="form-group-row row">
                                                            <div className="col main-ref-cust-checkbox">
                                                                <div className="custom-checkbox">
                                                                    <input type="checkbox" id="checkbox" />
                                                                    <span className="checkmark"></span>
                                                                    <label htmlFor="checkbox">
                                                                        I have read the
                                                                        <span>
                                                                            <Link>referring rules and conditions</Link > 
                                                                            </span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col input-box refer-attach-file-doc">
                                                                <span className='ref-att-icon'><PaperClipIcon /></span>
                                                                <h5>
                                                                    <small>Attach your referal’s CV</small>
                                                                    <input
                                                                        type="file"
                                                                        className="form-control"
                                                                        accept=".doc, .docx,.txt,.pdf"
                                                                    />
                                                                </h5>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p>
                                                    <small>
                                                        We will add your contact details provided in this form to our system for contacting you regarding your request. For more info please consult our <span><Link>privacy policy</Link></span>
                                                    </small>
                                                </p>

                                                <button type="button" className="submit-btn">Submit
                                                    <RightArrow className="right-arrow" />
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="col right-image-col">
                                    <div className="right-image bg-image" style={{ backgroundImage: `url(${refer2})` }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section id="getInTouch" className="section-get-in-touch referral-section ref-get-in-touch">
                <div className="container">
                    <div className="row">
                        <div className="col left-content">
                            <div className="content-wrap">
                                <h2>Ready to get started?</h2>
                                <p>Talk to an expert about how Betacraft meets your business needs. </p>
                            </div>
                        </div>
                        <div className="col right-content">
                            <div className="get-in-touch-btn">
                                <Link to="/contact-us">
                                    Get in touch<RightArrow className="svg-animation" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </div >
    );
}

export default ReferFriend;
