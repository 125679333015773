import { Link } from "react-router-dom";
import CustomLink from "../components/Common/CustomLink";
import CookiesPopup from "../components/CookiesPopup";
import { ReactComponent as FooterLogoPath } from "../assets/images/logo/DG_alpha_dark.svg";
import { ReactComponent as Instagram } from "../assets/images/icon/instagram.svg";
import { ReactComponent as RightArrow } from "../assets/images/icon/gray-arrow-right.svg";

import { FaYoutube } from "react-icons/fa";
import { AiFillFacebook, AiFillInstagram, AiFillLinkedin, AiOutlineTwitter, AiOutlineDribbble } from "react-icons/ai";
const Footer = () => {
    return <footer>
        <div className="container">
            <div className="row footer-main-row no-gutters">
                <div className="col footer-left-info">
                    <div className="info-content-wrap">
                        <div className="info-content">
                            <h4>At Digialpha,<br />We build extraordinary and challenge the status quo.</h4>
                            <h3>Find Your Perfect Role...</h3>
                            <CustomLink to="/careers" renderSvg={true}>
                                <h5>Explore Careers </h5>
                            </CustomLink>
                        </div>
                        <div className="footer-logo-content mobileHide">
                            <div className="footer-logo">
                                <Link to="/"> <FooterLogoPath /></Link>
                            </div>
                            <ul>
                                <li> <Link to="/">India</Link></li>
                                <li> <Link to="/">US</Link></li>
                                {/* <li> <Link to="/">UK</Link></li> */}
                                <li> <Link to="/">Singapore</Link></li>
                                <li> <Link to="/">UAE</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col footer-right-info">
                    <div className="row no-gutters main-row">
                        <div className="col bold-links">
                            <ul>
                                <li> <Link to="/client-success">Client Success</Link></li>
                                <li> <Link to="/insights"> PR and News</Link></li>
                                <li> <Link to="/about-digialpha">Company </Link></li>
                                <li> <Link to="/about-us">Partners</Link></li>
                                <li> <Link to="/insights">Insights</Link></li>
                                <li> <Link to="/about-digialpha/#corporate-social-responsibility">CSR</Link></li>
                                <li> <Link to="/contact-us">Contact us</Link></li>
                            </ul>
                        </div>
                        <div className="col footer-links col-services">
                            <h4>Services</h4>
                            <ul>
                                <li> <Link to="/services/customer-experience-and-design">Customer Experience <br />& Design </Link></li>
                                <li> <Link to="/services/digital-product-engineering">Digital Product Engineering</Link></li>
                                <li> <Link to="/services/digital-engineering">Digital Engineering</Link></li>
                                <li> <Link to="/services/data-and-analytics">Data & Analytics</Link></li>
                                <li> <Link to="/services/application-services">Application Services</Link></li>
                                <li> <Link to="/services/performance-marketing">Performance Marketing</Link></li>
                            </ul>
                        </div>
                        <div className="col footer-links col-industries">
                            {/* <h4>Industries</h4>
                            <ul>
                                <li> <Link to="/industries/travel">Travel</Link></li>
                                <li> <Link to="/">Martech</Link></li>
                                <li> <Link to="/">Blockchain </Link></li>
                                <li> <Link to="/">Finance</Link></li>
                                <li> <Link to="/">GIS</Link></li>
                                <li> <Link to="/">E-commerce</Link></li>
                                <li> <Link to="/">Software & Hi-Tech</Link></li>
                                <li> <Link to="/">elearning</Link></li>
                            </ul> */}
                        </div>
                    </div>
                    <div className="desktop-sitemap-links">
                        <div className="row no-gutters social-icon-row">
                            <div className="sitemap-links">
                                <ul>
                                    {/* <li> <Link to="/">Sitemap </Link></li> */}
                                    <li> <Link to="/cookie-policy"> Cookie Policy</Link></li>
                                    <li> <Link to="/privacy-policy">Privacy Policy</Link></li>
                                    <li> <Link to="/do-not-sell-my-personal-information">Do Not Sell My Info (CA)</Link></li>
                                </ul>
                            </div>
                            <div className="social-media-icon mobileHide">
                                <ul>
                                    <li> <a target="_blank" href="https://www.facebook.com/digialphaglobal"><AiFillFacebook /></a></li>
                                    <li> <a target="_blank" href="https://www.instagram.com/digialphaglobal/"><Instagram /> </a></li>
                                    <li> <a target="_blank" href="https://www.linkedin.com/company/digialphaglobal"><AiFillLinkedin /></a></li>
                                    <li> <a target="_blank" href="#"><AiOutlineTwitter /></a></li>
                                    <li> <a target="_blank" href="#"><FaYoutube /></a></li>
                                    <li><a target="_blank" href="#"><AiOutlineDribbble /></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col footer-left-info mobileShow">
                    <div className="info-content-wrap">
                        <div className="footer-logo-content">
                            <div className="footer-logo">
                                <Link to="/"> <FooterLogoPath /></Link>
                            </div>
                            <ul>
                                <li> <Link to="/">India</Link></li>
                                <li> <Link to="/">US</Link></li>
                                {/* <li> <Link to="/">UK</Link></li> */}
                                <li> <Link to="/">Singapore</Link></li>
                                <li> <Link to="/">UAE</Link></li>
                            </ul>

                        </div>
                    </div>
                    <div className="social-media-icon">
                        <ul>
                            <li> <a target="_blank" href="https://www.facebook.com/digialphaglobal"><AiFillFacebook /></a></li>
                            <li> <a target="_blank" href="https://www.instagram.com/digialphaglobal/"><Instagram /> </a></li>
                            <li> <a target="_blank" href="https://www.linkedin.com/company/digialphaglobal"><AiFillLinkedin /></a></li>
                            <li> <a target="_blank" href="#"><AiOutlineTwitter /></a></li>
                            <li> <a target="_blank" href="#"><FaYoutube /></a></li>
                            <li><a target="_blank" href="#"><AiOutlineDribbble /></a></li>
                        </ul>
                    </div>
                </div>
                <div className="mobile-sitemap-links footer-right-info">
                    <div className="social-icon-row">
                        <div className=" sitemap-links">
                            <ul>
                                {/* <li> <Link to="/">Sitemap </Link></li> */}
                                <li> <Link to="/cookie-policy">Cookie Policy</Link></li>
                                <li> <Link to="/privacy-policy">Privacy Policy</Link></li>
                                <li> <Link to="/do-not-sell-my-personal-information">Do Not Sell My Info (CA)</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright-content">
                <div className="col footer-left-info"><p>&copy; 2022 Digialpha. All Rights Reserved.</p></div>
            </div>
        </div>
        {/* <CookiesPopup /> */}
    </footer >
}

export default Footer;