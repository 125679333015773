import React, { useEffect, useState } from 'react';
import rightSizeIcon from "../../assets/images/icon/right-size.svg";
import humanIcon from "../../assets/images/icon/human.svg";
import recordGraphic from "../../assets/images/icon/record-graph.svg";
import regionalIcon from "../../assets/images/icon/regional.svg";
import "../../assets/css/component/whyBetaCraft.scss";

const WhyBetaCraft = () => {
    const [scroll, setScroll] = useState(false);
    const [scrolDifferentiatorsSection, setScrolDifferentiatorsSection] = useState(false);

    useEffect(() => {
        let pos3 = document.getElementById("differentiatorsSection").offsetTop - (window.innerHeight/2);
        window.addEventListener("scroll", () => {
            if (!scroll && window.scrollY > pos3) {
                setScrolDifferentiatorsSection(true);
            }
        });
        return () => {
            window.removeEventListener('scroll', () => {
                setScrolDifferentiatorsSection(false)
            })
        }
    }, []);
  return (
    <div id="differentiatorsSection"  className={scrolDifferentiatorsSection ? "differentiators-section animate" : "differentiators-section"}>
    <div className="container pd-top">
        <h5><div className='line' ><div className='lineIn'>Core Differentiators </div></div></h5>
        <h1><div className='line' ><div className='lineIn'>Why Digialpha?</div></div></h1>
        <div className="content-wrapper d-flex">
            <div className="row">
                <div className="col diff-content">
                    <div className="icon-wrap">
                        <img src={rightSizeIcon}
                            alt="rifht-size-icon" />

                            </div>
                    <h3>Proven Track Record</h3>
                    <p>Over the last 10 years plus our Customers have highly <span>appreciated our solutions and delivery quality. All work</span> we delivered is referenceable.</p>
                </div>
                <div className="col diff-content">
                    <div className="icon-wrap"><img src={humanIcon} alt="Human Icon"/></div>
                    <h3>Human Centric</h3>
                    <p>Our People is our asset and we are best in class in <span>retention helping us to deliver uninterrupted business.</span></p>
                </div>
                <div className="col diff-content">
                    <div className="icon-wrap"><img src={recordGraphic}  alt="Record Icon"/></div>
                    <h3>Win-Win Partnerships</h3>
                    <p>We believe that our customers must win by partnering <span>with us and off-course we will win if our customer’s win.</span> Thus it cerates a long term win-win partnership.</p>
                </div>
                <div className="col diff-content">
                    <div className="icon-wrap"><img src={regionalIcon}  alt="Rehiona Icon" /></div>
                    <h3>Regional Presence</h3>
                    <p>We have strong presence in North America, Europe,<span> United Kingdom, Middle East, Asia, understand region</span> well and a ready-team providing a head start.</p>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}
export default WhyBetaCraft;