import http from "./http";

function jobPostService(payload) {
  return http
    .post(
      `${process.env.REACT_APP_BASE_URL}/wp-json/careers/v1/list`,
      {
        ...payload,
        params: {
          use_access_token: false
        }
      }
    )
    .then((response) => response);
}


export const careerService = {
  jobPostService,
};
