import React, { useEffect, useRef, useState } from "react";
import { Helmet } from 'react-helmet';
import Slider from "react-slick";
import BannerContent from "../../components/BannerContent";
import PartnerEchoSystem from "../../components/Common/PartnerEchoSystem";
import GetInTouch from "../../components/GetInTouch";
import CoreValues from "../../components/CoreValues";
import debounce from 'lodash.debounce'
import CustomLink from "../../components/Common/CustomLink";
import BannerImage from "../../assets/images/about/banner.png";
import OurPurposeImage from "../../assets/images/about/our-purpose.png";
import ServiceSliderImage from "../../assets/images/about/service-slider/1.png";

import AppServicesCard from "../../assets/images/about/service-slider/application-services-card.png";
import CustomerImage from "../../assets/images/about/service-slider/customer-experience-and-design.jpg";
import AnalyticsImage from "../../assets/images/about/service-slider/data-and-Analytics.jpg";
import DigitalImage from "../../assets/images/about/service-slider/digital-engineering-and-services.jpg";
import MvpImage from "../../assets/images/about/service-slider/performance-marketing.jpg";
import DigitalProdImage from "../../assets/images/about/service-slider/Digital-Prod-Engg.jpg";

import Linkedin from "../../assets/images/icon/linkedin.png";
import CeoImage from "../../assets/images/team/ceo.png";
import CooImage from "../../assets/images/team/coo.png";
import CtoImage from "../../assets/images/team/cto.png";
import VpbImage from "../../assets/images/team/vpb.png";
import CdImage from "../../assets/images/team/cd.png";
import CfoImage from "../../assets/images/team/cfo.png";
import DirectorImage from "../../assets/images/team/director.png";
import SvpdImage from "../../assets/images/team/svpd.png";
import AdvisorImage from "../../assets/images/team/advisor.png";
import ShiftLeftArrow from "../../assets/images/icon/shift-left-arrow.svg";
import ShiftRightArrow from "../../assets/images/icon/shift-right-arrow.svg";

import CsrImage1 from "../../assets/images/about/Gender.jpg";
import CsrImage2 from "../../assets/images/about/Health.jpg";
import MarqueeImg1 from "../../assets/images/client-logos/zensar.png";
import MarqueeImg2 from "../../assets/images/client-logos/bigbinary.png";
import MarqueeImg3 from "../../assets/images/client-logos/ccr.png";
import MarqueeImg4 from "../../assets/images/client-logos/fixit.png";
import MarqueeImg5 from "../../assets/images/client-logos/ford-8.png";
import MarqueeImg6 from "../../assets/images/client-logos/forgeahead-main-logo.png";
import MarqueeImg7 from "../../assets/images/client-logos/Geoawseome.png";
import MarqueeImg8 from "../../assets/images/client-logos/Gimmonix.png";
import MarqueeImg9 from "../../assets/images/client-logos/InT.png";
import MarqueeImg10 from "../../assets/images/client-logos/Kyocera.png";
import MarqueeImg11 from "../../assets/images/client-logos/logo-tavisca-new.png";
import MarqueeImg12 from "../../assets/images/client-logos/midnight.png";
import MarqueeImg13 from "../../assets/images/client-logos/Msx.png";
import MarqueeImg14 from "../../assets/images/client-logos/Nomad_Logo_black.png";
import MarqueeImg15 from "../../assets/images/client-logos/officialnft.png";
import MarqueeImg16 from "../../assets/images/client-logos/precision-flight.png";
import MarqueeImg17 from "../../assets/images/client-logos/precision-hawk.png";
import MarqueeImg18 from "../../assets/images/client-logos/Real-park.png";
import MarqueeImg19 from "../../assets/images/client-logos/Royal-gulf.png";
import MarqueeImg20 from "../../assets/images/client-logos/TAconnections.png";
import MarqueeImg21 from "../../assets/images/client-logos/terraserver.png";
import MarqueeImg22 from "../../assets/images/client-logos/Tlx.png";
import MarqueeImg23 from "../../assets/images/client-logos/tripello.png";
import MarqueeImg24 from "../../assets/images/client-logos/vTravel.png";
import MarqueeImg25 from "../../assets/images/client-logos/webonoise.png";
import MarqueeImg26 from "../../assets/images/client-logos/TripStreak.png";
// import MarqueeImg27 from "../../assets/images/client-logos/Recruiter.png";

import "../../assets/css/pages/about.scss";
import { Route, Link, Routes, useLocation } from 'react-router-dom';

const AboutUs = () => {
    const divRef = useRef();
    const scollToCompanyOverviewRef = useRef(null);
    const scollToLeadershipRef = useRef(null);
    const csrsliderRef = useRef(null);
    const scollToCSRRef = useRef(null);
    const scollToOurPurposeRef = useRef(null);
    const scollToOurValuesRef = useRef(null);
    const [leftMargin, setMarginLeft] = useState(0)
    const [divHeight, setDivHeight] = useState(0)
    const location = useLocation();

    const [scroll, setScroll] = useState(false);
    const [scrollLeadership, setScrollLeadership] = useState(false);
    const [scrollCeo, setScrollCeo] = useState(false);

    const [scrollServicesSlider, setScrollServicesSlider] = useState(false);

    useEffect(() => {
        const element = document.getElementById("ourPurposeSection");
        let pos = element.offsetTop - (window.innerHeight / 2);

        window.addEventListener("scroll", () => {
            if (!scroll && window.scrollY > pos) {
                setScroll(true)
            }
        });
        return () => {
            window.removeEventListener('scroll', () => {
                setScroll(false)
            })
        }
    }, []);


    useEffect(() => {
        const element = document.getElementById("leadership-team");
        if (element) {
            let pos = element.offsetTop - (window.innerHeight);

            window.addEventListener("scroll", () => {
                if (!scroll && window.scrollY > pos) {
                    setScrollLeadership(true)
                }
            });
            return () => {
                window.removeEventListener('scroll', () => {
                    setScrollLeadership(false)
                })
            }
        }
    }, []);

    useEffect(() => {
        const element = document.getElementById("ceoSection");
        let pos = element.offsetTop - (window.innerHeight);

        window.addEventListener("scroll", () => {
            if (!scroll && window.scrollY > pos) {
                setScrollCeo(true)
            }
        });
        return () => {
            window.removeEventListener('scroll', () => {
                setScrollCeo(false)
            })
        }
    }, []);


    useEffect(() => {
        const element = document.getElementById("servicesSliderSection");
        let pos = element.offsetTop - (window.innerHeight / 2);

        window.addEventListener("scroll", () => {
            if (!scroll && window.scrollY > pos) {
                setScrollServicesSlider(true)
            }
        });
        return () => {
            window.removeEventListener('scroll', () => {
                setScrollServicesSlider(false)
            })
        }
    }, []);

    useEffect(() => {
        setInterval(function () {
            css2Marquee1()
        }, 10000)
        css2Marquee1()
    }, []);
    const css2Marquee1 = () => {
        let ele = document.getElementById('css2Marquee1').getElementsByClassName('slider-item')[0];
        document.getElementById('css2Marquee1').append(ele)

        let ele2 = document.getElementById('css2Marquee2').getElementsByClassName('slider-item')[0];
        document.getElementById('css2Marquee2').append(ele2)
    }
    useEffect(() => {
        if (["/about-us"].indexOf(window.location.pathname) > -1) {
            document.querySelector("body").classList.add("page-about-main-wrapper");
            return () => {
                document.querySelector("body").classList.remove("page-about-main-wrapper");
            };
        }
        window.addEventListener('resize', function () {
            setOffetMargin()
        });

        return () => {
            window.removeEventListener('resize', setOffetMargin);
        }
    }, []);

    const smoothScroll = () =>{
        if (window.location.href.includes("#corporate-values")) {
            scollToOurValuesRef?.current?.scrollIntoView();
        } else if (window.location.href.includes("#corporate-social-responsibility")) {
            scollToCSRRef?.current?.scrollIntoView();
        } else if (window.location.href.includes("#our-purpose")) {
            scollToOurPurposeRef?.current?.scrollIntoView();
        }
        else if (window.location.href.includes("#our-vision")) {
            scollToOurPurposeRef?.current?.scrollIntoView();
        }
        else if (window.location.href.includes("#leadership-team")) {
            scollToLeadershipRef?.current?.scrollIntoView();
        }
        else if (window.location.href.includes("company-overview")) {
            scollToCompanyOverviewRef?.current?.scrollIntoView();
        }
    }

    useEffect(() => {
        const height = document.getElementById('csr-slider-wrap-id').clientHeight;
        //console.log(height);
        setDivHeight(height);
        setTimeout(smoothScroll,150)

        
        //console.log(":::::::",location);

    }, [location])


    const setOffetMargin = () => {
        if (divRef?.current) {
            setMarginLeft(divRef?.current.offsetLeft)
        }
    }
    useEffect(() => {
        setOffetMargin();

    }, [divRef])

    const sliderRef = useRef();
    const [slideIndex, setSlideIndex] = useState(0);
    const nextSlide = () => {
        sliderRef.current.slickNext();
    }
    const prevSlide = () => {
        sliderRef.current.slickPrev();
    }

    function NextArrow(props) {
        const { className, onClick } = props;
        return (
            <button type="button" className={className} onClick={onClick}>
                <img src={ShiftRightArrow} alt="icon" />
            </button>
        );
    }

    function PrevArrow(props) {
        const { className, onClick } = props;
        return (
            <button type="button" disabled={slideIndex === 0} className={className} onClick={onClick}><img src={ShiftLeftArrow} alt="Shift Left Arrow" /></button>
        );
    }
    const services_settings = {
        dots: true,
        fade: false,
        autoplay: false,
        infinite: false,
        autoplaySpeed: 4000,
        speed: 3000,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        centerMode: false,
        centerPadding: '0px',
        variableWidth: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1121,
                settings: {
                    variableWidth: false,
                }
            },

            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            }
        ]

    };
    const slider_first = {
        dots: false,
        fade: false,
        autoplay: true,
        infinite: true,
        autoplaySpeed: "0",
        cssEase: "linear",
        speed: 9000,
        slidesToShow: 5,
        variableWidth: true,
        slidesToScroll: 1,
        arrows: false,
        centerMode: false,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4
                }
            }
        ]

    };
    const slider_second = {
        dots: false,
        fade: false,
        autoplay: true,
        infinite: true,
        autoplaySpeed: "0",
        cssEase: "linear",
        speed: 9000,
        slidesToShow: 5,
        variableWidth: true,
        slidesToScroll: 1,
        arrows: false,
        centerMode: false,
        rtl: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4
                }
            }
        ]

    };
    const slider_csr = {
        autoplay: false,
        dots: false,
        fade: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        vertical: true,
        verticalSwiping: true,
        swipeToSlide: true,
        autoplaySpeed: "0",
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 9999,
                settings: "unslick"
            },
            {
                breakpoint: 767,
                settings: {
                    vertical: false,
                    verticalSwiping: false,
                    swipeToSlide: false,
                    slidesToShow: 2,
                    variableWidth: true,
                    //centerMode: true,
                }
            }
        ]
    };

    const onWheelSlider = debounce((e, ref) => {
        //console.log(e);

        if (!sliderRef?.current) return;

        if (e.deltaY > 0) {
            sliderRef.current.slickNext();
            //console.log(e);
        } else if (e.deltaY < 0) {
            sliderRef.current.slickPrev();
            //console.log(ref);
        }
    }, 20);
    return (
        <div className="wrapper">
            <Helmet>
                <title>Company Overview | Digialpha</title>
            </Helmet>
            <div className="page-about-us" ref={scollToCompanyOverviewRef}>
                <BannerContent BackgroundImages={BannerImage} title={'About Digialpha '} subTitle={'Creating value for people and business through Total Experience (TX).'}>
                    <div className="content-wrap">
                        <div className="row">
                            <div className="col ">
                                <div className="content">
                                    <p>Digialpha is a global technology consulting and services company and a partner of choice for many of the world’s leading enterprises, SMEs and technology challengers. We design and deliver new digital experiences, revenue streams and business models for high-growth companies to meet rising employees and customers expectations and accelerate their growth.</p>
                                </div>
                            </div>
                            <div className="col right-content-col">
                                <div className="content">
                                    <p>Our expertise addresses the growth needs of companies in dynamic industries such as Software & Hi-Tech, Travel, Martech, Insurance, Blockchain, Finance, GIS, E-commerce and eLearning.</p>
                                    <p>We leverage our vast experience, latest technologies, methodologies and solutions to create unique competitive advantage for our clients to keep pace with with the accelerating rate of digital disruption.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </BannerContent>
                <div ref={scollToOurValuesRef} ></div>
                <CoreValues />
                <div ref={scollToOurPurposeRef} id="ourPurposeSection" className={scroll ? "our-purpose-section animate" : "our-purpose-section"} style={{ backgroundImage: `url(${OurPurposeImage})` }}>
                    <div className="container" ref={divRef}>
                        <div className="row">
                            <div id="#our-purpose" className="col col-6">
                                <div className="card-main">
                                    <div className="card black-bg">
                                        <div className="card-content">
                                            <h2>Mission</h2>
                                            <h3>Empower our clients to thrive in digital era.</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="#our-vision" className="col col-6">
                                <div className="card-main">
                                    <div className="card">
                                        <div className="card-content">
                                            <h2>Vision</h2>
                                            <h3>Leaders in digital innovation.</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* our purpose section */}
                <div id="servicesSliderSection" className={scrollServicesSlider ? "services-slider-section animate" : "services-slider-section"} >
                    <div className="container">
                        <div className="section-title">
                            <h2>Digialpha Services</h2>
                            <p>Discover how Digialpha services and solutions can help your enterprise turn digital challenges into business success.</p>
                        </div>
                    </div>
                    <div className="slider-wrapper">
                        <div className="container" style={{ marginLeft: `${leftMargin}px` }}>
                            <Slider {...services_settings} ref={sliderRef} afterChange={(index) => {
                                setSlideIndex(index)
                            }}>
                                <div className="col slider-item">
                                    <div className="card">
                                        <div className="card-content">
                                            <h3>Customer Experience & Design</h3>
                                            <div className="content">
                                                <p>Customer Experience focus is a must for the business to be successful today and hence we address it through our experienced professionals and solutions.</p>
                                                <CustomLink to="/services/customer-experience-and-design" renderSvg={true}>
                                                    Learn More
                                                </CustomLink>

                                            </div>
                                            <div className="card-image">
                                                <img src={CustomerImage} alt="slider image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col slider-item">
                                    <div className="card">
                                        <div className="card-content">
                                            <h3>Digital Product Engineering</h3>
                                            <div className="content">
                                                <p>Using modern day design thinking approach and frameworks, integrated with devops toolchain and with a wide variety of technology choices, Product Engineering is being taken to the next level of evolution delivering Digital products with great transparency.</p>
                                                <CustomLink to="/services/digital-product-engineering" renderSvg={true}>
                                                    Learn More
                                                </CustomLink>

                                            </div>
                                            <div className="card-image">
                                                <img src={DigitalProdImage} alt="slider image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col slider-item">
                                    <div className="card">
                                        <div className="card-content">
                                            <h3>Digital Engineering</h3>
                                            <div className="content">
                                                <p>Our solutions and offerings drive the Digital Journey of an organisation making them truly digital delivering the benefits.</p>
                                                <CustomLink to="services/digital-engineering" renderSvg={true}>
                                                    Learn More
                                                </CustomLink>

                                            </div>
                                            <div className="card-image">
                                                <img src={DigitalImage} alt="slider image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col slider-item">
                                    <div className="card">
                                        <div className="card-content">
                                            <h3>Data & Analytics</h3>
                                            <div className="content">
                                                <p>Data and Analytics Services and solutions help build the data foundation to the analytics layer unleashing the power of data in an organisation.</p>
                                                <CustomLink to="/services/data-and-analytics" renderSvg={true}>
                                                    Learn More
                                                </CustomLink>

                                            </div>
                                            <div className="card-image">
                                                <img src={AnalyticsImage} alt="slider image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col slider-item">
                                    <div className="card">
                                        <div className="card-content">
                                            <h3>Application Services</h3>
                                            <div className="content">
                                                <p>Truly end to end services in the custom applications, ERP and Quality (testing) delivering “keep the lights” (BAU), modernization, upgrade, and “Testing as a service”.</p>
                                                <CustomLink to="/services/application-services" renderSvg={true}>
                                                    Learn More
                                                </CustomLink>

                                            </div>
                                            <div className="card-image">
                                                <img src={AppServicesCard} alt="slider image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col slider-item">
                                    <div className="card">
                                        <div className="card-content">
                                            <h3>Performance Marketing</h3>
                                            <div className="content">
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                <CustomLink to="/services/performance-marketing" renderSvg={true}>
                                                    Learn More
                                                </CustomLink>

                                            </div>
                                            <div className="card-image">
                                                <img src={MvpImage} alt="slider image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
                <div ref={scollToLeadershipRef}></div>
                <div id="ceoSection" className={scrollCeo ? "ceo-section animate" : "ceo-section"} >
                    <div className="container">
                        <div className="row">
                            <div className="col img-col">
                                <div className="bg-image" style={{ backgroundImage: `url(${CeoImage})` }}>
                                    <a href="https://www.linkedin.com/in/pushkarajshelke/" target="_blank"><img src={Linkedin} alt="social icon" /> </a>
                                </div>
                            </div>
                            <div className="col right-content">
                                <div className="content">
                                    <h2>Word from our CEO</h2>
                                    <h3>“Commitment to diversity and inclusion can change not only what we’re capable of achieving at Digialpha—but also what the whole world can achieve.”</h3>
                                    <div className="signature">
                                        <h4><span>—</span> Pushkraj Shelke</h4>
                                        <h5><span></span> Founder & CEO</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div id="leadership-team" className={scrollLeadership ? "leadership-team-section animate" : "leadership-team-section"} >
                    <div className="container">
                        <div className="leadership-team-wrap">
                            <div className="leadership-item">
                                <div className="section-title">
                                    <h2>Leadership Team</h2>
                                </div>
                                <div className="row">
                                    <div className="col card-col">
                                        <div className="card">
                                            <div className="card-image">
                                                <img src={CooImage} alt="image" />
                                            </div>
                                            <h5>Prasad Deshpande</h5>
                                            <h6>COO</h6>
                                        </div>
                                    </div>
                                    <div className="col card-col">
                                        <div className="card">
                                            <div className="card-image">
                                                <img src={CtoImage} alt="image" />
                                            </div>
                                            <h5>Guido van Rossum</h5>
                                            <h6>CTO</h6>
                                        </div>
                                    </div>
                                    <div className="col card-col">
                                        <div className="card">
                                            <div className="card-image">
                                                <img src={VpbImage} alt="image" />
                                            </div>
                                            <h5>James Riebel</h5>
                                            <h6>VP - Business Development</h6>
                                        </div>
                                    </div>
                                    <div className="col card-col">
                                        <div className="card">
                                            <div className="card-image">
                                                <img src={CdImage} alt="image" />
                                            </div>
                                            <h5>Jaime Summers</h5>
                                            <h6>Creative Director</h6>
                                        </div>
                                    </div>
                                    <div className="col card-col">
                                        <div className="card">
                                            <div className="card-image">
                                                <img src={CfoImage} alt="image" />
                                            </div>
                                            <h5>Renee Groves</h5>
                                            <h6>CFO</h6>
                                        </div>
                                    </div>
                                    <div className="col card-col">
                                        <div className="card">
                                            <div className="card-image">
                                                <img src={DirectorImage} alt="image" />
                                            </div>
                                            <h5>Priyanka Shelke</h5>
                                            <h6>Director </h6>
                                        </div>
                                    </div>

                                    <div className="col card-col">
                                        <div className="card">
                                            <div className="card-image">
                                                <img src={SvpdImage} alt="image" />
                                            </div>
                                            <h5>Srinivas Kolhapure</h5>
                                            <h6>SVP - Delivery</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="leadership-item">
                                <div className="section-title">
                                    <h2>Our Advisors</h2>
                                </div>
                                <div className="row">
                                    <div className="col card-col">
                                        <div className="card">
                                            <div className="card-image">
                                                <img src={AdvisorImage} alt="image" />
                                            </div>
                                            <h5>Nii A. Ahene</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <PartnerEchoSystem />
                <div className="clientele-section" >
                    <div className="container">
                        <div className="section-title">
                            <h2>Over 1,000+ Positive Outcomes</h2>
                            <h3>Who we work with</h3>
                            <p>We are proud to partner with clients of all stages, sizes and industries on meaningful work.</p>
                            <div className="mobile-hide">
                                <CustomLink to="#" renderSvg={true}>
                                    Here are their stories
                                </CustomLink>
                            </div>
                        </div>
                    </div>
                    <div className="sliders-wrap">

                        <div id="css2Marquee1" className="slider-first horizontalMarque">
                            {/* <Slider {...slider_first}> */}
                            <div className="slider-item">
                                <div className="slider-content">
                                    <img src={MarqueeImg1} alt="marquee img" ></img>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <img src={MarqueeImg2} alt="marquee img"></img>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <img src={MarqueeImg3} alt="marquee img"></img>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <img src={MarqueeImg4} alt="marquee img"></img>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <img src={MarqueeImg5} alt="marquee img"></img>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <img src={MarqueeImg6} alt="marquee img"></img>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <img src={MarqueeImg7} alt="marquee img"></img>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <img src={MarqueeImg8} alt="marquee img"></img>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <img src={MarqueeImg9} alt="marquee img"></img>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <img src={MarqueeImg10} alt="marquee img"></img>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <img src={MarqueeImg11} alt="marquee img"></img>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <img src={MarqueeImg12} alt="marquee img"></img>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <img src={MarqueeImg13} alt="marquee img"></img>
                                </div>
                            </div>
                            {/* </Slider> */}
                        </div>
                        <div id="css2Marquee2" className="slider-first horizontalMarque horizontalMarque2">
                            {/* <Slider {...slider_second}> */}
                            <div className="slider-item">
                                <div className="slider-content">
                                    <img src={MarqueeImg14} alt="marquee img"></img>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <img src={MarqueeImg15} alt="marquee img"></img>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <img src={MarqueeImg16} alt="marquee img"></img>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <img src={MarqueeImg17} alt="marquee img"></img>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <img src={MarqueeImg18} alt="marquee img"></img>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <img src={MarqueeImg19} alt="marquee img"></img>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <img src={MarqueeImg20} alt="marquee img"></img>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <img src={MarqueeImg21} alt="marquee img"></img>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <img src={MarqueeImg22} alt="marquee img"></img>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <img src={MarqueeImg23} alt="marquee img"></img>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <img src={MarqueeImg24} alt="marquee img"></img>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <img src={MarqueeImg25} alt="marquee img"></img>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="slider-content">
                                    <img src={MarqueeImg26} alt="marquee img"></img>
                                </div>
                            </div>
                            {/* </Slider> */}
                        </div>
                        <div className="desktop-hide mobile-show">
                            <div className="container">
                                <CustomLink to="#" renderSvg={true}>
                                    Here are their stories
                                </CustomLink>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-csr">
                    <div ref={scollToCSRRef} className="section-title">
                        <h2>Corporate Social Responsibility</h2>
                        <h3>Digialpha has pledged four United Nations Sustainable Development Goals</h3>
                    </div>
                    <div ref={csrsliderRef} id="csr-slider-wrap-id" className="csr-slider-wrap">
                        <div className="row no-gutters">
                            <div className="col left-image-col ">
                                {/* <div className="image-box">
                                    <img src={CsrImage1} alt="csr image" />
                                </div> */}
                                <div className="left-image bg-image" style={{ backgroundImage: `url(${CsrImage1})` }}>
                                    {/* <div className="image-box">
                                    <img src={CsrImage1} alt="csr image" />
                                </div> */}
                                </div>
                            </div>
                            <div className="col slider-section">
                                <div className="slider-verticle" onWheel={onWheelSlider}>
                                    <Slider ref={sliderRef} {...slider_csr}>
                                        <div className="slider-item">
                                            <h3><span className="green-text">Gender</span> Equality</h3>
                                            {/* <CustomLink to="#" renderSvg={true}>
                                                Learn More
                                            </CustomLink> */}
                                        </div>
                                        <div className="slider-item">
                                            <h3><span className="green-text">Reduced</span> Inequality</h3>
                                            {/* <CustomLink to="#" renderSvg={true}>
                                                Learn More
                                            </CustomLink> */}
                                        </div>
                                        <div className="slider-item">
                                            <h3><span className="green-text">Good Health</span> and <br/>Well-Being</h3>
                                            {/* <CustomLink to="#" renderSvg={true}>
                                                Learn More
                                            </CustomLink> */}
                                        </div>
                                        <div className="slider-item">
                                            <h3><span className="green-text">Industry, Innovation</span><br/>and Infrastructure</h3>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                            <div className="col right-image-col">
                                {/* <div className="image-box">
                                    <img src={CsrImage2} alt="csr image" />
                                </div> */}
                                <div className="right-image bg-image" style={{ backgroundImage: `url(${CsrImage2})` }}></div>
                            </div>
                        </div>
                    </div>
                </div>
                <GetInTouch />
            </div >
        </div >
    );
}

export default AboutUs;
