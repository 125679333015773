import React, { useEffect, useState, useRef } from "react";
// import Select from "react-select";
import { Link, useSearchParams, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { VscClose } from "react-icons/vsc";
import { IoInformationCircle } from "react-icons/io5";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";
import { Pagination, Select } from "antd";
import { IoCloseOutline } from "react-icons/io5";

import BannerContent from "../../components/BannerContent";
import { ReactComponent as RightArrow2 } from "../../assets/images/icon/right-arrow2.svg";
import { ReactComponent as LeftArrow2 } from "../../assets/images/icon/left-arrow2.svg";
import { ReactComponent as ShareIcon } from "../../assets/images/icon/share.svg";
import { ReactComponent as LocationIcon } from "../../assets/images/icon/location.svg";
import { ReactComponent as PaperClipIcon } from "../../assets/images/icon/paperclip.svg";
import { ReactComponent as LeftArrowIcon } from "../../assets/images/icon/left-arrow2.svg";
import { ReactComponent as RightArrow } from "../../assets/images/icon/gray-arrow-right.svg";
import { ReactComponent as SuccessIcon } from "../../assets/images/icon/success.svg";
import { ReactComponent as Sort } from "../../assets/images/icon/sorting.svg";
import { ReactComponent as Downarrow } from "../../assets/images/icon/chevron-down.svg";
// import { CareeraccordianList } from "../../../src/data/faq/accordianList";
import CustomLink from "../../components/Common/CustomLink";
import CustomAccordian from "../../components/CustomAccordion";
import BannerImage from "../../assets/images/careers/career-detail-banner.png";

import { careerActions } from "../../Stores/Actions/careerActions";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

import { useDebounce } from "../../hooks/useDebounce";
import dompurify from "dompurify";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/css/pages/career-detail.scss";
import useOutsideHandler from "../../hooks/useOutsideHandler";
const Option = Select.Option;

const itemRender = (_, type, originalElement) => {
  if (type === "prev") {
    return (
      <a>
        <LeftArrow2 />
      </a>
    );
  }

  if (type === "next") {
    return (
      <a>
        <RightArrow2 />
      </a>
    );
  }

  return originalElement;
};
const CareersDetail = (showFilters) => {
  const dispatch = useDispatch();
  const careerDetailsResults = useSelector(
    (store) => store.careerReducer.careerDetailsResults
  );
  const sanitizer = dompurify.sanitize;
  //   console.log("careerDetailsResults", careerDetailsResults);
  const [SuccessPopup, setSuccessPopup] = useState(false);
  const [ToggleClass, setToggleClass] = useState(0);
  const [ToggleMobileFilter, setToggleMobileFilter] = useState(0);
  const scollToSectionRef = useRef(null);
  const [showAccordion, setAccordion] = useState(null);
  const [PopupContent, setOpenPopup] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isShareToggle, setShareToggle] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  let queryID = searchParams.get("id");
  const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
  const loader = useSelector((store) => store.commonReducer.loader);
  //const loader = true;

  const shareDropDownRef = useRef(null);
  // There are two pop up
  useOutsideHandler(shareDropDownRef, isShareToggle, () => {
    setShareToggle(null);
  });

  const [showJDIndex, setShowJDIndex] = useState(
    queryID ? Number(queryID) : null
  );

  const handleWindowSizeChange = () => {
    if (window.innerWidth <= 991) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  const toggleAccordion = (index) => {
    if (showAccordion === index) {
      setAccordion(null);
    } else {
      setAccordion(index);
    }
  };
  const smoothScroll = () => {
    if (window.location.href.includes("current-opportunities")) {
      scollToSectionRef?.current?.scrollIntoView();
    }
  }
  useEffect(() => {
    setTimeout(smoothScroll, 100);
  }, [location]);
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    handleWindowSizeChange();
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const ToggleClick = (index) => {
    setToggleClass(index);
  };
  const ToggleFilterClick = (index) => {
    setToggleMobileFilter(index);
  };
  const ToggleShareClick = (index) => {
    setShareToggle(index);
  };
  const ShowJDClick = (index) => {
    setShowJDIndex(index);
    var element = document.getElementsByClassName("banner-content-wrap")[0];
    window.scrollTo(0, element.offsetHeight);
  };

  ////////////////
  const [payload, setPayload] = useState({
    page: 1,
    order_by: "Latest",
    posts_per_page: 10,
    search_keyword: "",
    filter_by: {
      job_location: [],
      job_types: [],
      degree: [],
      skills: [],
    },
  });

  //   useEffect(() => {
  //     setTimeout(() => {
  //       setShowJDIndex(queryID);
  //     }, 2000);
  //   }, []);

  const [jobType, setJobType] = useState("");
  const handleOnclick = (ele) => {
    setJobType(ele);
  };

  // payload set for pagination
  const handlePagination = (page) => {
    setPayload({ ...payload, page });
    dispatch(careerActions.jobPostAction({ ...payload, page }));
  };
  // payload set for Sort Select
  const handelSortSelect = (order_by) => {
    setPayload({
      ...payload,
      order_by,
    });
    dispatch(careerActions.jobPostAction({ ...payload, order_by }));
  };

  // payload set for  job type filter by

  const handleJobTypeChange = (job_type) => {
    if (payload.filter_by.job_types.includes(job_type)) {
      const res = payload.filter_by.job_types.filter((ele) => {
        return ele !== job_type;
      });
      const temp = { ...payload };
      temp.filter_by.job_types = res;
      temp.page = 1;
      setPayload(temp);
      if (!isMobile) {
        dispatch(careerActions.jobPostAction(temp));
      }
    } else {
      const temp = { ...payload };
      temp.filter_by.job_types.push(job_type);
      temp.page = 1;
      setPayload(temp);
      if (!isMobile) {
        dispatch(careerActions.jobPostAction(temp));
      }
    }
  };

  // payload set for  job type filter by
  const handelDegreeChange = (degree) => {
    if (payload.filter_by.degree.includes(degree)) {
      const res = payload.filter_by.degree.filter((ele) => {
        return ele !== degree;
      });
      const temp = { ...payload };
      temp.filter_by.degree = res;
      temp.page = 1;
      setPayload(temp);
      if (!isMobile) {
        dispatch(careerActions.jobPostAction(temp));
      }
    } else {
      const temp = { ...payload };
      temp.filter_by.degree.push(degree);
      temp.page = 1;
      setPayload(temp);
      if (!isMobile) {
        dispatch(careerActions.jobPostAction(temp));
      }
    }
  };

  // payload set for skills filter by
  const handelSkillsChange = (skills) => {
    // console.log(skills);
    if (payload.filter_by.skills.includes(skills)) {
      const res = payload.filter_by.skills.filter((ele) => {
        return ele !== skills;
      });
      const temp = { ...payload };
      temp.filter_by.skills = res;
      temp.page = 1;
      setPayload(temp);
      if (!isMobile) {
        dispatch(careerActions.jobPostAction(temp));
      }
    } else {
      const temp = { ...payload };
      temp.filter_by.skills.push(skills);
      temp.page = 1;
      setPayload(temp);
      if (!isMobile) {
        dispatch(careerActions.jobPostAction(temp));
      }
    }
  };

  //payload set for job location filter by
  const handelJobLocationChange = (location) => {
    if (payload.filter_by.job_location.includes(location)) {
      const res = payload.filter_by.job_location.filter((ele) => {
        return ele !== location;
      });
      const temp = { ...payload };
      temp.filter_by.job_location = res;
      temp.page = 1;
      setPayload(temp);
      dispatch(careerActions.jobPostAction(temp));
    } else {
      const temp = { ...payload };
      temp.filter_by.job_location.push(location);
      temp.page = 1;
      setPayload(temp);
      dispatch(careerActions.jobPostAction(temp));
    }
  };

  // payload search keyword

  const [searchQuery, setSearchQuery] = useState();

  useDebounce(searchQuery, 600, (bufferedValue) => {
    let updatedPayload = { ...payload };
    if (bufferedValue) {
      updatedPayload = { ...updatedPayload, search_keyword: bufferedValue };
    } else {
      delete updatedPayload.search_keyword;
      updatedPayload = { ...updatedPayload };
    }
    setPayload(updatedPayload);
    if (!isMobile) {
      dispatch(careerActions.jobPostAction(updatedPayload));
    }
  });


  // clear filter

  const handelClearFilter = () => {
    if (isMobile) {
      ToggleFilterClick(0);
    }
    setSearchQuery("");
    setPayload({
      page: 1,
      posts_per_page: 10,
      order_by: "Latest",
      search_keyword: "",
      filter_by: {
        job_location: [],
        job_types: [],
        degree: [],
        skills: [],
      },
    });
    dispatch(
      careerActions.jobPostAction({
        page: 1,
        order_by: "Latest",
        posts_per_page: 10,
        filter_by: {
          job_location: [],
          job_types: [],
          degree: [],
          skills: [],
        },
      })
    );
  };

  //   console.log(payload);
  let sortSelectOption = careerDetailsResults?.available_sort_options.map(
    (ele, index) => {
      return { label: ele.label, value: ele.id };
    }
  );
  const [query, setQuery] = useState("");

  useDebounce(query, 600, (bufferedValue) => {
    let updatedPayload = { ...payload };
    if (bufferedValue) {
      updatedPayload = { ...updatedPayload, job_location: bufferedValue };
    } else {
      delete updatedPayload.job_location;
      updatedPayload = { ...updatedPayload };
    }
    setPayload(updatedPayload);
    if (!isMobile) {
      dispatch(careerActions.jobPostAction(updatedPayload));
    }
  });

  //
  const handelDeleteClick = (location) => {
    const res = payload.filter_by.job_location.filter((ele) => {
      return ele !== location;
    });
    const temp = { ...payload };
    temp.filter_by.job_location = res;
    temp.page = 1;
    setPayload(temp);

    const result = res?.map((ele) => {
      return JSON.parse(ele)?.value;
    });
    if (!isMobile) {
      dispatch(
        careerActions.jobPostAction({
          ...temp,
          filter_by: { ...temp.filter_by, job_location: result },
        })
      );
    }
  };

  //
  const handelSelectLocation = (ele) => {
    const temp = { ...payload };
    temp.filter_by.job_location = ele;
    temp.page = 1;
    setPayload(temp);
    const result = ele?.map((ele) => {
      return JSON.parse(ele)?.value;
    });
    if (!isMobile) {
      dispatch(
        careerActions.jobPostAction({
          ...temp,
          filter_by: { ...temp.filter_by, job_location: result },
        })
      );
    }
  };

  const ShouldChecked = (selectedOptions, currentOpt) => {
    if (!selectedOptions) return;
    if (selectedOptions.includes(currentOpt)) {
      return true;
    } else {
      return false;
    }
  };

  // handelSetURLId
  const handelSetURLId = (id) => {
    ShowJDClick(id);
    let updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set("id", id);
    setSearchParams(updatedSearchParams.toString());
  };

  //
  const handelDoneIsMobile = () => {
    ToggleFilterClick(0);
    dispatch(careerActions.jobPostAction(payload));
  };

  return (
    <div className="wrapper careers-detail-main-wrapper">
      <Helmet>
        <title>
          Find available job openings at Digialpha. Browse all the latest jobs
          and career opportunities. We Build Extraordinary. Join Digialpha.
        </title>
      </Helmet>
      <div className="careers-detail-wrapper">
        <BannerContent
          BackgroundImages={BannerImage}
          title={"Careers at Digialpha"}
          subTitle={"Find your perfect role"}
        ></BannerContent>
        <div ref={scollToSectionRef} className="careers-detail-wrap">
          <div className="container">
            <div className="row no-gutters">
              <div
                className={`${ToggleMobileFilter === 1
                  ? "col left-content-col left-content-col-active"
                  : "col left-content-col"
                  }`}
              >
                <div
                  className={`${showJDIndex !== null
                    ? "left-content-col-wrap left-content-col-wrap-active"
                    : "left-content-col-wrap"
                    }`}
                >
                  <div className="accordion-toggle-wrap">
                    {isMobile && (
                      <div className="mobile-filter-heading">
                        <button
                          className="btn-back-btn"
                          onClick={() => ToggleFilterClick(0)}
                        >
                          <MdOutlineKeyboardBackspace size={26} />
                        </button>
                        <button
                          type="button"
                          onClick={() => handelClearFilter()}
                        >
                          Clear filters
                        </button>
                      </div>
                    )}
                    <div className="filter-wrap">
                      <div className="filter-col">
                        <h4>What do you want to do?</h4>
                        {!isMobile && (
                          <button
                            type="button"
                            onClick={() => handelClearFilter()}
                          >
                            Clear filters
                          </button>
                        )}
                      </div>
                      <div className="input-search-group">
                        <input
                          className="form-control"
                          type="search"
                          placeholder="Software Engineering, Design, Sales"
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="accordion-wrapper">
                      <div className="accordion">
                        <div className="card">
                          <div className={"card-title"}>
                            <button
                              onClick={() => {
                                toggleAccordion(1);
                              }}
                              className={
                                showAccordion === 1
                                  ? "faq-btn open-btn"
                                  : "faq-btn"
                              }
                            >
                              Locations
                            </button>
                          </div>

                          <div
                            className={
                              showAccordion === 1
                                ? `card-content-wrap open-accordion`
                                : "card-content-wrap"
                            }
                          >
                            <div className="card-content">
                              <div className="form-group input-search-group">
                                {/* <input
                                  className="form-control"
                                  type="search"
                                  placeholder="San Francisco, London, Pune"
                                /> */}

                                {payload?.filter_by?.job_location?.map(
                                  (ele) => {
                                    return (
                                      <div className="location-button">
                                        {JSON.parse(ele).label}{" "}
                                        <button
                                          type="button"
                                          className="button-style-removed"
                                          onClick={() => handelDeleteClick(ele)}
                                        >
                                          <IoCloseOutline size={16} />
                                        </button>
                                      </div>
                                    );
                                  }
                                )}

                                <Select
                                  suffixIcon={<LocationIcon color="#d4d5d8" size={22} />}
                                  className="form-control"
                                  showArrow={true}
                                  mode="multiple"
                                  showSearch
                                  optionLabelProp="children"
                                  value={payload.filter_by.job_location}
                                  popupClassName="location-dropdown multi-select-dropdown"
                                  placeholder="All Locations"
                                  maxTagPlaceholder={"All Locations"}
                                  maxTagCount={0}
                                  onSearch={(e) => setQuery(e)}
                                  // filterOption={false}
                                  onChange={(ele) => {
                                    handelSelectLocation(ele);
                                    //debugger
                                  }}
                                  dropdownRender={(menu) => (
                                    <>
                                      <div
                                        className="all-select"
                                        onClick={() => {
                                          const res =
                                            careerDetailsResults?.available_filter_options?.job_location.map(
                                              (ele) => {
                                                return JSON.stringify(ele);
                                              }
                                            );
                                          const temp = { ...payload };
                                          if (
                                            careerDetailsResults
                                              ?.available_filter_options
                                              ?.job_location?.length ===
                                            payload.filter_by.job_location
                                              ?.length
                                          ) {
                                            temp.filter_by.job_location = [];
                                            handelSelectLocation([]);
                                          } else {
                                            temp.filter_by.job_location = res;
                                            handelSelectLocation(res);
                                          }
                                          temp.page = 1;
                                          setPayload(temp);
                                          // dispatch(careerActions.jobPostAction(temp));
                                        }}
                                      >
                                        <input
                                          className="input-field-all"
                                          readOnly
                                          disabled={false}
                                          checked={
                                            careerDetailsResults
                                              ?.available_filter_options
                                              ?.job_location?.length ===
                                            payload.filter_by.job_location
                                              ?.length
                                          }
                                          type="checkbox"
                                        />
                                        <div className="input-content">
                                          <p>All</p>
                                        </div>
                                      </div>

                                      {menu}
                                    </>
                                  )}
                                >
                                  {careerDetailsResults?.available_filter_options?.job_location?.map(
                                    (ele, index) => {
                                      return (
                                        <Option
                                          disabled={false}
                                          key={JSON.stringify(ele)}
                                          value={JSON.stringify(ele)}
                                        >
                                          <div className="all-select">
                                            <input
                                              className="input-field-all"
                                              readOnly
                                              disabled={false}
                                              checked={ShouldChecked(
                                                payload?.filter_by
                                                  ?.job_location,
                                                JSON.stringify(ele)
                                              )}
                                              type="checkbox"
                                            />
                                            <div className="input-content">
                                              <p>{ele?.label}</p>
                                            </div>
                                          </div>
                                        </Option>
                                      );
                                    }
                                  )}
                                </Select>
                              </div>
                              {/* <ul className="common-ul-wrap locations-wrap-ul">
                                {careerDetailsResults?.available_filter_options?.job_location.map(
                                  (ele, index) => {
                                    return (
                                      <li key={index}>
                                        <div className="custom-checkbox">
                                          <input
                                            checked={
                                              payload.filter_by.job_location.includes(
                                                ele.value
                                              )
                                                ? true
                                                : false
                                            }
                                            type="checkbox"
                                            id={ele.value}
                                            onChange={() =>
                                              handelJobLocationChange(ele.value)
                                            }
                                          />
                                          <span className="checkmark"></span>
                                          <label htmlFor={ele.value}>
                                            {ele.label}
                                          </label>
                                        </div>
                                      </li>
                                    );
                                  }
                                )}
                              </ul> */}
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className={"card-title"}>
                            <button
                              onClick={() => {
                                toggleAccordion(2);
                              }}
                              className={
                                showAccordion === 2
                                  ? "faq-btn open-btn"
                                  : "faq-btn"
                              }
                            >
                              Skills & qualifications
                            </button>
                          </div>
                          <div
                            className={
                              showAccordion === 2
                                ? `card-content-wrap open-accordion`
                                : "card-content-wrap"
                            }
                          >
                            <div className="card-content">
                              <ul className="common-ul-wrap skills-wrap-ul">
                                {careerDetailsResults?.available_filter_options?.skills.map(
                                  (ele, index) => {
                                    return (
                                      <li key={index}>
                                        <div className="custom-checkbox">
                                          <input
                                            checked={
                                              payload.filter_by.skills.includes(
                                                ele.value
                                              )
                                                ? true
                                                : false
                                            }
                                            type="checkbox"
                                            id={ele.value}
                                            onChange={() =>
                                              handelSkillsChange(ele.value)
                                            }
                                          />
                                          <span className="checkmark"></span>
                                          <label htmlFor={ele.value}>
                                            {ele.label}
                                          </label>
                                        </div>
                                      </li>
                                    );
                                  }
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className={"card-title"}>
                            <button
                              onClick={() => {
                                toggleAccordion(3);
                              }}
                              className={
                                showAccordion === 3
                                  ? "faq-btn open-btn"
                                  : "faq-btn"
                              }
                            >
                              Degree
                            </button>
                          </div>
                          <div
                            className={
                              showAccordion === 3
                                ? `card-content-wrap open-accordion`
                                : "card-content-wrap"
                            }
                          >
                            <div className="card-content">
                              <ul className="common-ul-wrap degree-wrap-ul">
                                {careerDetailsResults?.available_filter_options?.degree.map(
                                  (ele, index) => {
                                    return (
                                      <li key={index}>
                                        <div className="custom-checkbox">
                                          <input
                                            checked={
                                              payload.filter_by.degree.includes(
                                                ele.value
                                              )
                                                ? true
                                                : false
                                            }
                                            type="checkbox"
                                            id={ele.value}
                                            onClick={() =>
                                              handelDegreeChange(ele.value)
                                            }
                                          />
                                          <span className="checkmark"></span>
                                          <label htmlFor={ele.value}>
                                            {ele.label}
                                          </label>
                                        </div>
                                      </li>
                                    );
                                  }
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className={"card-title"}>
                            <button
                              onClick={() => {
                                toggleAccordion(4);
                              }}
                              className={
                                showAccordion === 4
                                  ? "faq-btn open-btn"
                                  : "faq-btn"
                              }
                            >
                              Job Types
                            </button>
                          </div>
                          <div
                            className={
                              showAccordion === 4
                                ? `card-content-wrap open-accordion`
                                : "card-content-wrap"
                            }
                          >
                            <div className="card-content">
                              <ul className="common-ul-wrap job-types-wrap-ul">
                                {careerDetailsResults?.available_filter_options?.job_types.map(
                                  (ele, index) => {
                                    return (
                                      <li key={ele.value}>
                                        <div className="custom-checkbox">
                                          <input
                                            checked={
                                              payload.filter_by.job_types.includes(
                                                ele.value
                                              )
                                                ? true
                                                : false
                                            }
                                            type="checkbox"
                                            id={ele.value}
                                            // checked={}
                                            onClick={() =>
                                              handleJobTypeChange(ele.value)
                                            }
                                          />
                                          <span className={"checkmark"}></span>
                                          <label htmlFor={ele.value}>
                                            {ele.label}
                                          </label>
                                        </div>
                                      </li>
                                    );
                                  }
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {isMobile && (
                      <div className="mobile-done-btn-wrap ">
                        <button
                          type="button"
                          onClick={() => handelDoneIsMobile()}
                          className="btn-orange"
                        >
                          Done
                        </button>
                      </div>
                    )}
                  </div>
                  {!isMobile && (
                    <div className="second-section-toggle-wrap">
                      <div className="edit-filter-wrap">
                        <div className="filter-col">
                          <button
                            onClick={() => {
                              setShowJDIndex(null);
                              let updatedSearchParams = new URLSearchParams(
                                searchParams.toString()
                              );
                              updatedSearchParams.delete("id");
                              setSearchParams(updatedSearchParams.toString());
                            }}
                            type="button"
                          >
                            <LeftArrowIcon />
                            Edit Filters
                          </button>

                          <div className="sort-filter-wrap">
                            <div className="span-sort-by-wrap">
                              {/* <div className="sort-filter-wrap"> */}
                              <Sort /><span className="span-sort-by">Sort by:</span>
                              <Select
                                popupClassName="career-sort-dropdown"
                                placeholder={`${payload.order_by}`}
                                options={sortSelectOption}
                                isSearchable={false}
                                suffixIcon={<Downarrow size={18} />}	
                              // onChange={() => {debugger}}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="links-wrapper-wrap">
                        {careerDetailsResults?.data?.map((ele, index) => {
                          return (
                            <div className="links-wrapper" key={ele.id}>
                              <button
                                className={`main-link ${showJDIndex === ele.id ? "active" : ""
                                  }`}
                                onClick={() => {
                                  // setShowJDIndex(ele?.id);
                                  handelSetURLId(ele?.id);
                                }}
                              >
                                <div className="job-date">
                                  <div className="job-content">
                                    <h3>{ele?.title}</h3>
                                    <h6>Exp: {ele?.experience}</h6>
                                  </div>
                                  <div className="location-wrap">
                                    <p>
                                      <LocationIcon />
                                      {ele?.job_location} • {ele?.work_location}
                                    </p>
                                  </div>
                                </div>
                              </button>
                            </div>
                          );
                        })}

                        {careerDetailsResults?.data?.length < 1 && (
                          <>
                            <h2>No results.</h2>
                            <h3>
                              <Link onClick={() => handelClearFilter()}>
                                Search again
                              </Link>{" "}
                              or try removing filters.
                            </h3>
                          </>
                        )}

                        {showFilters && careerDetailsResults?.pagination && (
                          <div className="custom-pagination-wrap">
                            <div className="container">
                              <Pagination
                                total={careerDetailsResults?.pagination?.total}
                                // total={Math.ceil(
                                //   (careerDetailsResults?.pagination?.total ||
                                //     1) / payload.posts_per_page
                                // )}
                                itemRender={itemRender}
                                current={
                                  careerDetailsResults?.pagination?.current_page
                                }
                                onChange={(page) => handlePagination(page)}
                                pageSize={payload.posts_per_page || 1}
                                hideOnSinglePage={true}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col right-content-col">
                <div className="right-content-wrap">
                  <div className="sort-btn-wrap">
                    {isMobile && (
                      <div className="mobile-btn-wrap">
                        <button
                          onClick={() => {
                            ToggleFilterClick(1);
                            setShowJDIndex(null);
                          }}
                          type="button"
                          className="btn-edit-filter"
                        >
                          Edit filters
                        </button>
                      </div>
                    )}
                    <div className="sort-filter-wrap">
                      {showJDIndex === null && (
                        <>
                          <div className="span-sort-by-wrap">
                            {/* <div className="sort-filter-wrap"> */}
                            <Sort /><span className="span-sort-by">Sort by:</span>
                            <Select
                              popupClassName="career-sort-dropdown"
                              placeholder={`${payload.order_by}`}
                              options={sortSelectOption}
                              isSearchable={false}
                              onChange={(ele) => handelSortSelect(ele)}
                              suffixIcon={<Downarrow size={18} />}	
                            //onChange={() => {debugger}}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="vacancy-card-wrapper">
                    {/* {(showJDIndex === null || showJDIndex === 0) && ( */}
                    {/* { <Spin indicator={antIcon} />} */}
                    {loader && <>
                      <div className="vacancy-card">
                        <Spin indicator={antIcon} />
                      </div>
                    </>}
                    {showJDIndex &&
                      careerDetailsResults?.data?.map((ele, index) => {
                        if (showJDIndex === ele.id) {
                          return (
                            <div
                              key={ele.id}
                              className={`${showJDIndex === ele.id
                                ? "vacancy-card vacancy-card-active"
                                : "vacancy-card"
                                }`}
                            >
                              <div className="card-heading ">
                                <div className="cardleft">
                                  <h3>
                                    {ele.title}
                                    <span>({ele.experience} exp.)</span>
                                  </h3>
                                  {/* {ele.job_location.map((ele, index) => { */}
                                  {/* return ( */}
                                  <div key={index} className="location-wrap">
                                    <p>
                                      <LocationIcon />
                                      {ele.job_location} • {ele.work_location}
                                    </p>
                                  </div>
                                </div>
                                <div className="cardRight">
                                  <div className="dropdown-main-wrap">
                                    <button
                                      type="button"
                                      onClick={() => ToggleShareClick(ele.id)}
                                    >
                                      <ShareIcon />
                                      Share
                                    </button>
                                    {isShareToggle === ele.id && (
                                      <div
                                        className="custom-dropdown"
                                        ref={shareDropDownRef}
                                      >
                                        <div className="heading">
                                          <h3>Share</h3>
                                          <button
                                            onClick={() =>
                                              ToggleShareClick(null)
                                            }
                                            type="button"
                                            className="button-style-removed"
                                          >
                                            <VscClose />
                                          </button>
                                        </div>
                                        <div className="email-wrap">
                                          <a
                                            href={`mailto:?subject=${ele?.title}&body=Check out this job:${window.location.href}`}
                                          >
                                            Email to a friend
                                          </a>
                                        </div>
                                        <div className="job-link-wrap">
                                          <h4>Job link:</h4>
                                        </div>
                                        <div className="copy-link-wrap">
                                          <input
                                            type="text"
                                            readonly="readonly"
                                            value={window.location.href}
                                          ></input>
                                          <button
                                            type="button"
                                            onClick={() => {
                                              navigator.clipboard.writeText(
                                                window.location.href
                                              );
                                            }}
                                          >
                                            Copy
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="card-body-wrap">
                                <div className="card-body">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: sanitizer(ele?.short_description),
                                    }}
                                  />
                                  <br />
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: sanitizer(ele?.description),
                                    }}
                                  />
                                </div>
                                <div className="expand-btn">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      handelSetURLId(ele.id);
                                    }}
                                  >
                                    Expand
                                  </button>
                                </div>
                              </div>
                              <div className="apply-btn">
                                <button
                                  type="button"
                                  onClick={() => setOpenPopup(true)}
                                >
                                  Apply Now <RightArrow />
                                </button>
                              </div>
                            </div>
                          );
                        }
                      })}

                    {!showJDIndex &&
                      !loader &&
                      careerDetailsResults?.data?.map((ele, index) => {
                        return (
                          <div
                            key={ele.id}
                            className={`${showJDIndex === ele.id
                              ? "vacancy-card vacancy-card-active"
                              : "vacancy-card"
                              }`}
                          >
                            <div className="card-heading 123">
                              <div className="cardleft">
                                <h3>
                                  {ele.title}
                                  <span>({ele.experience} exp.)</span>
                                </h3>
                                {/* {ele.job_location.map((ele, index) => { */}
                                {/* return ( */}
                                <div key={index} className="location-wrap">
                                  <p>
                                    <LocationIcon />
                                    {ele.job_location} • {ele.work_location}
                                  </p>
                                </div>
                              </div>
                              <div className="cardRight">
                                <div className="dropdown-main-wrap">
                                  <button
                                    type="button"
                                    onClick={() => ToggleShareClick(ele.id)}
                                  >
                                    <ShareIcon />
                                    Share
                                  </button>
                                  {isShareToggle === ele.id && (
                                    <div
                                      className="custom-dropdown"
                                      ref={shareDropDownRef}
                                    >
                                      <div className="heading">
                                        <h3>Share</h3>
                                        <button
                                          onClick={() => ToggleShareClick(null)}
                                          type="button"
                                          className="button-style-removed"
                                        >
                                          <VscClose />
                                        </button>
                                      </div>
                                      <div className="email-wrap">
                                        <a
                                          href={`mailto:?subject=${ele?.title}&body=Check out this job:${window.location.href}?id=${ele.id}`}
                                        >
                                          Email to a friend
                                        </a>
                                      </div>
                                      <div className="job-link-wrap">
                                        <h4>Job link:</h4>
                                      </div>
                                      <div className="copy-link-wrap">
                                        <input
                                          type="text"
                                          readonly="readonly"
                                          value={`${window.location.href}?id=${ele.id}`}
                                        ></input>
                                        <button
                                          type="button"
                                          onClick={() => {
                                            navigator.clipboard.writeText(
                                              `${window.location.href}/?id=${ele.id}`
                                            );
                                          }}
                                        >
                                          Copy
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="card-body-wrap">
                              <div className="card-body">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: sanitizer(ele?.short_description),
                                  }}
                                />
                                <br />
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: sanitizer(ele?.description),
                                  }}
                                />
                              </div>
                              <div className="expand-btn">
                                <button
                                  type="button"
                                  onClick={() => {
                                    handelSetURLId(ele.id);
                                  }}
                                >
                                  Expand
                                </button>
                              </div>
                            </div>
                            <div className="apply-btn">
                              <button
                                type="button"
                                onClick={() => setOpenPopup(true)}
                              >
                                Apply Now <RightArrow />
                              </button>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  {showJDIndex !== null ||
                    (showFilters && careerDetailsResults?.pagination && (
                      <div className="custom-pagination-wrap">
                        <div className="container">
                          <Pagination
                            total={careerDetailsResults?.pagination?.total}
                            // total={Math.ceil(
                            //   careerDetailsResults?.pagination?.total /
                            //     payload.posts_per_page
                            // )}
                            itemRender={itemRender}
                            current={
                              careerDetailsResults?.pagination?.current_page
                            }
                            onChange={(page) => handlePagination(page)}
                            pageSize={payload.posts_per_page || 1}
                            // pageSize={2}
                            hideOnSinglePage={true}
                          />
                        </div>
                      </div>
                    ))}
                </div>
                {careerDetailsResults?.data?.length < 1 && (
                  <div className="data-not-found-wrap text-center">
                    <h2>No results.</h2>
                    <h3>
                      <Link onClick={() => handelClearFilter()}>
                        Search again
                      </Link>{" "}
                      or try removing filters.
                    </h3>
                  </div>
                )}
              </div>
            </div>
          </div>
          {PopupContent && (
            <div className="popup contact-form-popup career-detail-form-popup">
              <div className="popup-content">
                <div className="popup-title">
                  <button
                    type="button"
                    className="btn close"
                    onClick={() => setOpenPopup(false)}
                  >
                    <VscClose />
                  </button>
                </div>
                <div className="content-box">
                  <h2>Please fill out the form below</h2>
                  <form>
                    <div className="form-group">
                      <div className="form-group-row row no-gutters">
                        <div className="col number-col">
                          <h6>01</h6>
                        </div>
                        <div className="col input-box">
                          <h5>What’s your name?</h5>
                          <input
                            type="text"
                            placeholder="John Doe *"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="form-group-row row no-gutters">
                        <div className="col number-col">
                          <h6>02</h6>
                        </div>
                        <div className="col input-box">
                          <h5>What's your email?</h5>
                          <input
                            type="text"
                            placeholder="john@doe.com *"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="form-group-row row no-gutters">
                        <div className="col number-col">
                          <h6>03</h6>
                        </div>
                        <div className="col input-box">
                          <h5>What's your contact number?</h5>
                          <input
                            type="text"
                            placeholder="+91 9876543432"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group file-group">
                      <div className="file-group-wrap">
                        <div className="form-group-row row no-gutters">
                          <div className="col number-col">
                            <h6>
                              <PaperClipIcon />
                            </h6>
                          </div>
                          <div className="col input-box">
                            <h5>Attach your resume here *</h5>
                            <input
                              type="file"
                              className="form-control"
                              accept=".doc, .docx,.txt,.pdf"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group checkbox-group">
                      <div className="form-group-row row no-gutters">
                        <div className="custom-checkbox">
                          <input type="checkbox" id="checkbox" />
                          <span className="checkmark"></span>
                          <label htmlFor="checkbox">
                            I consent to the above details being used to keep me
                            informed about Digialpha’s openings
                          </label>
                        </div>
                        <p>
                          <small>
                            This form collects your name and email so that we
                            can add you to our newsletter list for product
                            updates and useful marketing tips and tricks. You
                            can unsubscribe at any time. Check out our privacy
                            policy and cookie policy
                          </small>
                        </p>
                      </div>
                    </div>
                    {/* <button type="submit" className="submit-btn">Submit<RightArrow className="right-arrow" /></button> */}
                    <button
                      type="button"
                      onClick={() => {
                        setSuccessPopup(true);
                        setOpenPopup(false);
                      }}
                      className="submit-btn"
                    >
                      Submit
                      <RightArrow className="right-arrow" />
                    </button>
                  </form>
                </div>
              </div>
            </div>
          )}
          {SuccessPopup && (
            <div className="popup form-success-popup career-success-popup">
              <div className="popup-content">
                <div className="popup-title">
                  <button
                    type="button"
                    className="btn close"
                    onClick={() => setSuccessPopup(false)}
                  >
                    <VscClose />
                  </button>
                </div>
                <div className="content-box">
                  <div className="img-wrap">
                    <SuccessIcon />
                  </div>
                  <h2>
                    Thank you Pushkraj, <br />
                    for interest!
                  </h2>
                  <h4> Our recruiter will reach you out soon</h4>
                </div>
              </div>
            </div>
          )}
        </div>
        <section
          id="getInTouch"
          className="section-get-in-touch referral-section"
        >
          <div className="container">
            <div className="row">
              <div className="col left-content">
                <div className="content-wrap">
                  <h2>Noticed a perfect match for a Friend?</h2>
                  <p>Get a valuable bonus for a reference</p>
                </div>
              </div>
              <div className="col right-content">
                <div className="get-in-touch-btn">
                  <Link to="/contact-us">
                    Refer a Friend <RightArrow className="svg-animation" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default CareersDetail;
