import React, { useEffect } from "react";
import { gsap } from 'gsap';
import ServiceContentCard from "../components/ServiceContentCard";
import { ScrollTrigger } from "gsap/ScrollTrigger";
const ServiceCardNew = (props) => {
    function animateFrom(elem, direction, index) {
        //console.log(index, "index")
        direction = direction || 1;
        var x = 0,
            y = direction * 100;
        if (elem.classList.contains("gs_reveal_fromLeft")) {
            x = -100;
            y = 0;
        } else if (elem.classList.contains("gs_reveal_fromRight")) {
            x = 100;
            y = 0;
        }
        // elem.style.transform = "translate(" + x + "px, " + y + "px)";
        // elem.style.backgroundPosition = x + "px, " + y + "px";
        elem.style.opacity = "0";
        gsap.fromTo(elem, { x: x, y: y, autoAlpha: 0 }, {
            duration: 1.25,
            x: 0,
            y: 0,
            autoAlpha: 1,
            ease: "expo",
            overwrite: "auto"
        });

    }

    function hide(elem) {
        gsap.set(elem, { autoAlpha: 0 });
    }
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        gsap.utils.toArray(".gs_reveal").forEach(function (elem, index) {
            hide(elem); // assure that the element is hidden when scrolled into view
            ScrollTrigger.create({
                trigger: elem,
                onEnter: function () { animateFrom(elem, null, index) },
                onEnterBack: function () { animateFrom(elem, -1, index) },
                onLeave: function () { hide(elem) } // assure that the element is hidden when scrolled into view
            });
        });
        gsap.utils
            .toArray(".card img")
            .forEach((section, i) => {
                const heightDiff =
                    section.offsetHeight - section.parentElement.offsetHeight;

                gsap.fromTo(
                    section,
                    {
                        y: section.parentElement.offsetHeight - 300
                    },
                    {
                        y: -230,
                        ease: "none",
                        scrollTrigger: {
                            trigger: section.parentElement,
                            scrub: true
                        }
                    }
                );
            });
    }, [props?.data])
    return (
        <div className="section-service-card">
            <div className="container">
                <div className="cInnerContent">
                    <div className="feature-row row">
                        {props?.data?.map((caseStudy) => {
                            console.log(caseStudy, "caseStudy")
                            return <div className="col col1" key={caseStudy.id}>
                                <ServiceContentCard 
                                    clientLogo={caseStudy.logo} 
                                    sectionClass={"gs_reveal"} 
                                    ContentClass={"ipsType_left"} 
                                    cardImage={caseStudy.image} 
                                    title={caseStudy.category} 
                                    subTitle={caseStudy.title} 
                                    content={caseStudy.short_description} 
                                    buttontext={"Learn More"} 
                                    buttonUrl={`/client-success/${caseStudy?.category_slug}/${caseStudy?.slug}/${caseStudy?.id}`}
                                    />
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceCardNew;