export const caseStudyConstants = {
  CASESTUDY_REQUEST: "CASESTUDY_REQUEST",
  CASESTUDY_SUCCESS: "CASESTUDY_SUCCESS",
  CASESTUDY_FAILURE: "CASESTUDY_FAILURE",

  FEATURED_CASESTUDY_REQUEST: "FEATURED_CASESTUDY_REQUEST",
  FEATURED_CASESTUDY_SUCCESS: "FEATURED_CASESTUDY_SUCCESS",
  FEATURED_CASESTUDY_FAILURE: "FEATURED_CASESTUDY_FAILURE",

  CASESTUDY_DETAILS_REQUEST: "CASESTUDY_DETAILS_REQUEST",
  CASESTUDY_DETAILS_SUCCESS: "CASESTUDY_DETAILS_SUCCESS",
  CASESTUDY_DETAILS_FAILURE: "CASESTUDY_DETAILS_FAILURE",

  RESET_FEATURED_CASESTUDY_REQUEST: "RESET_FEATURED_CASESTUDY_REQUEST"
};
