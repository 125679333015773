import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Blog from "../../components/Blog";
import WhyBetaCraft from "../../components/Common/WhyBetaCraft";
import CustomLink from "../../components/Common/CustomLink";
import GetInTouch from "../../components/GetInTouch";
import Offerings from "../../components/Offerings";
import { ReactComponent as RightArrow } from "../../assets/images/icon/right-arrow.svg";
import windowsIcon from "../../assets/images/marque-img/windows.png";
import imbIcon from "../../assets/images/marque-img/ibm.png";
import awsIcon from "../../assets/images/marque-img/aws.png";
import salesForceIcon from "../../assets/images/marque-img/salesforce.png";
import redHatIcon from "../../assets/images/marque-img/red-hat.png";
import offertinImg1 from "../../assets/images/Customer-Ex-offering/Digital-Marketing.jpg";
import offertinImg2 from "../../assets/images/Customer-Ex-offering/CX-strategy.jpg";
import offertinImg3 from "../../assets/images/Customer-Ex-offering/CX-Design.jpg";
import offertinImg4 from "../../assets/images/Customer-Ex-offering/CX-Engg.jpg";
import offertinImg5 from "../../assets/images/Customer-Ex-offering/Design-thinking.jpg";
import companyLogo1 from "../../assets/images/logo/ccr.png";
import companyLogo2 from "../../assets/images/logo/mo.png";
import companyLogo3 from "../../assets/images/logo/vtravel.png";
import companyLogo4 from "../../assets/images/logo/Msx.png";
import { ReactComponent as VideoIcon } from "../../assets/images/icon/video-btn-icon.svg";
import Slider from "react-slick";
import "../../assets/css/pages/home.scss";
import {
  blogActions,
  featuredBlogActions,
  customerBlogActions
} from "../../Stores/Actions/blogAction";

const CustomerExperienceAndDesignPage = (props) => {
  const dispatch = useDispatch();
  const featuredData = useSelector((state) => state?.blogReducer?.featuredBlog?.data);
  const blogData = useSelector((state) => state?.blogReducer?.blogResults);
  const customBlogData = useSelector((state) => state?.blogReducer?.customerBlog);
  const [tabThumbRef, setTabThumbRef] = useState();

  const images = [ companyLogo1,
    companyLogo2,
    companyLogo3,
    companyLogo4]

  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const element = document.getElementById("overviewSection");
    let pos = element.offsetTop - window.innerHeight / 2;
    window.addEventListener("scroll", () => {
      if (!scroll && window.scrollY > pos) {
        setScroll(true);
      }
    });
    let payload = {
      page: 1,
      posts_per_page: 3,
      order_by: "a-z",
    };

    dispatch(featuredBlogActions());
    dispatch(customerBlogActions());
    dispatch(blogActions(payload));
    return () => {
      window.removeEventListener("scroll", () => {
        setScroll(false);
      });
    };
  }, []);
  
  gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);

  let tabSliderSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    center: true,
    autoplay: true,
    autoplaySpeed: 5000,
  };
  const offeringList = [
    {
      id: "slideV21",
      title: "Digital Marketing, Brand,<br/> Content and Creative",
      copy: "Digital Marketing, Brand, Content and Creative - <br />Let our full-stack experience agencies illuminate your<br/> company's personality through design, performance <br/>video production, identity, and strategy.",
      image: offertinImg1,
    },
    {
      id: "slideV22",
      title: "CX Strategy",
      copy: "Create a vision for the experience you want <br />to deliver and form the plan you <br /> need to make it happen.",
      image: offertinImg2,
    },
    {
      id: "slideV23",
      title: "CX Design",
      copy: "At Digialpha, we offer great customer experience creation through our <br />expertise and methodology by understanding the user requirements, <br />issues and challenges. This approach gives business an edge to be <br />right at the first time creating exceptional customer journeys.",
      image: offertinImg3,
    },
    {
      id: "slideV24",
      title: "CX Engineering",
      copy: "Once the CX design is approved by the business we bring in<br/> Technology Engineering to deliver the customer<br/> experience with scalability",
      image: offertinImg4,
    },
    {
      id: "slideV25",
      title: "Design Thinking",
      copy: "<span>Design thinking is a non-linear, iterative process that<br /> teams use to understand users, challenge assumptions,<br /> redefine problems and create innovative solutions to<br />prototype and test. Involving five phases—Empathize,<br />Define, Ideate, Prototype and Test—it is most useful<br />to tackle problems that are ill-defined or unknown.",
      image: offertinImg5,
    },
  ];
  return (
    <div className="wrapper">
      <Helmet>
        <title>Customer Experience & Design | Digialpha</title>

        <title>
          Digialpha - Global technology consulting and services company
        </title>
        <meta
          name="title"
          content="Digialpha - Global technology consulting and services company | Career"
        />
        <meta
          name="description"
          content="Global technology"
        />

        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://demo.digialpha.com/services/customer-experience-and-design"
        />
        <meta
          property="og:title"
          content="Digialpha - Global technology consulting and services company"
        />
        <meta
          property="og:description"
          content="Global technology"
        />
        <meta
          property="og:image"
          content={offertinImg1}
        />
        <meta property="og:image:width" content="755" />
        <meta property="og:image:height" content="422" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://demo.digialpha.com/services/customer-experience-and-design"
        />
        <meta
          property="twitter:title"
          content="Digialpha - Global technology consulting and services company"
        />
        <meta
          property="twitter:description"
          content="Global technology"
        />
        <meta
          property="twitter:image"
          content="https://demo.digialpha.com/static/media/customer-experience-bg.cdd6e1f5a0849b8b0645.jpg"
        ></meta>
      </Helmet>
      <div className="service-wrapper home-wrapper">
        <div className="home-page" ref={ref}>
          <div className="section-1 first">
            <div className="product-banner">
              <div className="banner-section customer-experence-bg">
                <div className="container banner-container">
                  <div className="left-content first-paragraph">
                    <h5 className="tagline">Customer Experience & Design</h5>
                    <h1 className="tagline">
                      Design, deliver, and grow digital products, services, and
                      experiences
                    </h1>
                    <p className="banner-para">
                      Customer Experience focus is a must for the business to be
                      successful today and hence we address it through our
                      experienced professionals and solutions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <CustomLink to="#" className="white-wrapper" renderSvg={false}>
              <h5>Featured Whitepaper </h5>
              <h4>
                <span>A tool-first, automated approach to scaling your </span>
                <span>
                  DevSecOps organization <RightArrow />
                </span>
              </h4>
            </CustomLink>
          </div>
          <div
            className={
              scroll
                ? "overview-section pd-top animate"
                : "overview-section pd-top"
            }
            id="overviewSection"
          >
            <div className="container text-wrap">
              <h5>Overview</h5>
              <div className="overview-para-1">
                <p>
                  Customer Experience is key to the success of any business and
                  also drives the Digital journey of the business very well.
                  Therefore it is important to put customers as the focus and
                  drive through the transformation of the business processes and
                  technology.
                </p>
              </div>
              <div className="overview-para-2">
                <p>
                  We at Digialpha have certified HFI professionals and
                  frameworks to assess, measure and create great customer
                  experience which will increase the business and also bring in
                  customer delight.
                </p>
              </div>
              <div className="float-clear"></div>
            </div>
          </div>
          <Offerings offeringList={offeringList} />
          <div id="successStory" className="success-story-section">
            <div className="container pd-top">
              <h5>Client Success Stories </h5>
              <h1>
                <span>Our Work Drives</span>
                <span>Innovation Forward</span>
              </h1>
            </div>
            <div className="tab-menu" id="tabs">
              <div className="container">
                <Slider
                  asNavFor={tabThumbRef}
                  ref={(slider2) => setTabThumbRef(slider2)}
                  arrows={true}
                  slidesToShow={4}
                  focusOnSelect={true}
                  autoplay={true}
                  speed={500}
                  slidesToScroll={1}
                  autoplaySpeed={5000}
                  pauseOnHover={false}
                >
                  {images.map((ele, index) => {
                    return (
                      <div className="imagesGroupWrap" key={index}>
                        <img src={ele} alt="icon" />
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </div>
            <div className="sotry-tabs tab-container">
              <div className="tab-content">
                <Slider
                  className="tabSlider"
                  {...tabSliderSettings}
                  ref={(tabSliderRef) => {
                    setTabThumbRef(tabSliderRef);
                  }}
                  pauseOnHover={false}
                >
                  {customBlogData?.data?.map((ele, index) => {
                    return (
                      <div key={index}>
                        <div className="tab">
                          <div className="col-6 tab-left-section">
                            <button className="audio-btn">
                              Audio Visual (AV){" "}
                            </button>
                            <div className="mobile-logo">
                              <img src={companyLogo1} alt="company-logo-1" />
                            </div>
                            <h3>
                              <span>Simplifying moving to cloud for a </span>
                              <span>leading North American AV solutions</span>
                              <span>company</span>
                            </h3>
                            <p>
                              Today every software is being viewed and thought
                              of as a Product. Every new Digital application is
                              also thought of as a product hence Product
                              Engineering is becoming the foundation to the
                              Digital world.
                            </p>
                            <div className="progress-bar">
                              <CustomLink
                                to={`/client-success/${ele?.category_slug}/${ele?.slug}/${ele?.id}`}
                                renderSvg={true}
                              >
                                Read the full story
                              </CustomLink>
                            </div>
                          </div>
                          <div className="col-6 tab-right-section">
                            <div className="tab-video ccr">
                              <div className="video-text">
                                <p>
                                  Digialpha offered what we needed out of the
                                  box – plus we wanted a system that we could
                                  reason about, good documentation, and had
                                  existing integrations.
                                </p>
                                <h5>
                                  {ele?.case_study_tag}
                                  <button>
                                    <VideoIcon />
                                  </button>
                                </h5>
                              </div>
                            </div>
                            <div className="progress-bar mobileShow">
                            <CustomLink
                                to={`/client-success/${ele?.category_slug}/${ele?.slug}/${ele?.id}`}
                                renderSvg={true}
                              >
                                Read the full story
                              </CustomLink>
                            </div>
                          </div>
                          <div className="float-clear"></div>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </div>
          </div>
          <WhyBetaCraft />

          <div className="ecosystem-section">
            <div className="row container ">
              <div className="col-1 ecosystem-content">
                <h5>Partner Ecosystem</h5>
                <h1>We are a partnership driven company</h1>
                <p>
                  We form partnerships and strategic alliances with world-class
                  organisations to expand our service offerings and deliver
                  comprehensive solutions to clients. Strategic alliances with
                  the world’s leading companies enable us to provide complete
                  solutions to your business and IT challenges.{" "}
                </p>
                <div className="progress-bar mobileHide">
                  <CustomLink to="#" renderSvg={true}>
                    Learn More
                  </CustomLink>
                  {/* <button className="hoverMebottom button"></button> */}
                </div>
              </div>
              <div className="col-2 ecosystem-img">
                <div className="marque-section ">
                  <div className="area">
                    <ul className="circles">
                      <li className="img-4">
                        <img src={salesForceIcon} alt="sales Force Icon" />
                      </li>
                      <li className="img-2">
                        <img src={imbIcon} alt="Imb Icon" />
                      </li>
                      <li className="img-3">
                        <img src={awsIcon} alt="Aws Icon" />
                      </li>
                      <li className="img-1">
                        <img src={windowsIcon} alt="Windows Icon" />
                      </li>
                      <li className="img-5">
                        <img src={redHatIcon} alt="RedHat Icon" />
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="progress-bar mobileShow">
                  {/* <button className="hoverMebottom button">Discover More</button> */}
                  <CustomLink to="#" renderSvg={true}>
                    Learn More
                  </CustomLink>
                </div>
              </div>
            </div>
          </div>
          <Blog
            showAll={true}
            blogData={blogData}
            featuredData={featuredData}
            className="gray-section"
          />
          <GetInTouch />
          {/*  <div className="insight-scetion">
                    <div className="container">
                        <div className="heading pd-top">
                            <div className="heading-text">
                                <h5>Latest Thinkings </h5>
                                <h1>News and Insights </h1>
                            </div>
                            <div className="slider-btn">
                                <button onClick={prevSlide} className="pre-btn slide-arrow prev-arrow icon-arrow-left"></button>
                                <button onClick={nextSlide} className="next-btn slide-arrow next-arrow icon-arrow-right"></button>
                            </div>
                        </div>
                    </div>
                </div>
            <Blog /> */}
        </div>
      </div>
    </div>
  );
};

export default CustomerExperienceAndDesignPage;
