import { Link } from "react-router-dom";
import { ReactComponent as RightArrow } from "../../assets/images/icon/gray-arrow-right.svg";
// import { ReactComponent as RightArrow } from "../../assets/images/icon/gray-arrow-right.svg";
const CustomLink = ({ to, className, children, renderSvg,id }) => {
  return (
   <Link 
   to={to} 
   className={`custom-link ${className}`}
   onClick={()=>{
                    localStorage.setItem("blogId",id)
                  }}
   >
    {children}
    {renderSvg ? <div className="arrow"><div className="head"></div></div> : null}
   </Link>
  );
};
export default CustomLink;
