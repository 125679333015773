import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import GetInTouch from "../../components/GetInTouch";
import { ReactComponent as RightArrow } from "../../assets/images/icon/gray-arrow-right.svg";
import BannerContent from "../../components/BannerContent";
import CheckboxMenu from "../../components/Common/MultipleSelect";
import CustomLink from "../../components/Common/CustomLink";
import { ReactComponent as RightArrow2 } from "../../assets/images/icon/right-arrow2.svg";
import { ReactComponent as LeftArrow2 } from "../../assets/images/icon/left-arrow2.svg";
import { ReactComponent as Sort } from "../../assets/images/icon/sorting.svg";
import { ReactComponent as Rsvp } from "../../assets/images/icon/rsvp.svg";
import { ReactComponent as ReadMore } from "../../assets/images/icon/readMore.svg";
import { Pagination } from "antd";
import Forbes from "../../assets/images/allInsights/forbes.png";
import Gartner from "../../assets/images/allInsights/gartner.png";

// import "../../assets/css/common.scss";
import "../../assets/css/pages/blog-listing.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  blogActions,
  featuredBlogActions,
  resetBlogActions
} from "../../Stores/Actions/blogAction";
import { isEmpty } from "lodash";

const itemRender = (_, type, originalElement) => {
  if (type === "prev") {
    return (
      <a>
        <LeftArrow2 />
      </a>
    );
  }

  if (type === "next") {
    return (
      <a>
        <RightArrow2 />
      </a>
    );
  }

  return originalElement;
};

const News = () => {
  const [filter, setFilter] = useState([]);
  const dispatch = useDispatch();
  const [blogData, setBlogData] = useState({data: []})
  // blog data from the store
  const blogLocalData = useSelector((state) => state?.blogReducer?.blogResults);

  // featured blog data from the store       // As of now, we are not getting data from api response
  const featuredData = useSelector(
    (state) => state?.blogReducer?.featuredBlog?.data
  );
  useEffect(() => {
    
    if(blogLocalData){
      console.log(blogLocalData, "blogLocalData")
      setBlogData(blogLocalData);
    }else {
      setBlogData([]);
    }
  }, [blogLocalData])
  useEffect(() => {
    let payload = {
      filter_by: [],
      page: 1,
      posts_per_page: 3,
      order_by: "oldest",
    };
    dispatch(featuredBlogActions());
    dispatch(blogActions(payload));

  }, []);

  function searchBlog(sort, filter, page) {
    let payload = {
      filter_by: [],
      page: 1,
      posts_per_page: 3,
      order_by: sort ? sort?.value : "oldest",
    };

    if (filter) {
      let newFilter = new Set(filter); // removing duplicate filter id
      payload.filter_by = [...newFilter];
    }

    if (page) {
      payload.page = page;
    }

    dispatch(blogActions(payload));
  }

  return (
    <div className="wrapper">
      <Helmet>
        <title>Insights | Digialpha</title>
      </Helmet>
      <div className="page-blog-listing blog-listing-wrapper allInsights">
      <div className="banner-main-wrap">
        <div className="banner-content-wrap" >
          
        </div>
        <div className="banner-content banner-bg">
          <div className="container ">
            <div className="section-title">
              <h1>News</h1>
              <h3>All Insights</h3>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-section">
      <div className="container">
        <ul className="menu">
          <li>
            <div className="row no-gutters">
              <div className="col blog-col mobile-hide"></div>
              <div className="col blog-col section-blog-col">
                <div className="section-title">
                  <h3>News</h3>
                  <h2>All Insights</h2>
                </div>
              </div>
              <div className="col blog-col filter-col">
                
                  <div className="sortWrapper-main">
                    <div className="selectWrapper-main p-relative">
                      <div className="selectWrapper">
                        <CheckboxMenu
                          options={[]}
                          // value={["Analyst Insight", "Blog", "Brochure"]}
                          //onChange={onCheckboxChange}
                          placeholder="Insight Type"
                          //onFilterBlur={onFilterBlur}
                        />
                      </div>
                      <div className="selectWrapper">
                        <CheckboxMenu
                          options={[]}
                          // value={["Analyst Insight", "Blog", "Brochure"]}
                          // onChange={onCheckboxChange}
                          placeholder="Service"
                          // onFilterBlur={onFilterBlur}
                        />
                      </div>
                      <div className="selectWrapper">
                        <CheckboxMenu
                          options={[]}
                          // value={["Analyst Insight", "Blog", "Brochure"]}
                          // onChange={onCheckboxChange}
                          placeholder="Industry"
                          // onFilterBlur={onFilterBlur}
                        />
                      </div>
                    </div>
                    <div className="sort-option">
                      <button type="button" className="custom-btn" >
                      <Sort /> Sort by: <strong>Latest</strong>
                      </button>
                      {/* {sortDropdown && sortingFilters && <div className="sortDropdown" ref={dropdownRef}>
                          {sortingFilters.map(ele => {
                            return <button className={`dropdownOption ${sortBy.value === ele.value ? "active" : ""}`} onClick={() => {setSortDropdown(false); handleSelect(ele)}}>{ele.label}</button>
                          })}
                        </div>} */}
                    </div>
                  </div>
                
              </div>
            </div>
          </li>
          <li >
                
                <Link
                  to="#"
                  className="no-gutters row clickable-anchor menu__item"
                >
                  <div className="col blog-col menu__item-text blog-title">
                    <h4>/ Insight — News</h4>
                  </div>
                  <div className="col blog-col menu__item-text">
                    <img className="imageTitle" src={Forbes} alt="Forbes" />
                    <h3>Best practices for devops observability</h3>
                    <h3 className="lightText" >January 23, 2023</h3>
                  </div>
                  <div className="col blog-col menu__item-text">
                    <p>Founded in 2013, Digialpha is a leading global technology consultancy that integrates strategy, design. Founded 2013, Digialpha is a leading global technology consultancy.</p>
                    <Link className="readMoreLink"  to="#">Read More <ReadMore /></Link>
                  </div>
                </Link>
              </li>
              <li >
                
                <Link
                  to="#"
                  className="no-gutters row clickable-anchor menu__item"
                >
                  <div className="col blog-col menu__item-text blog-title">
                    <h4>/ Insight — News</h4>
                  </div>
                  <div className="col blog-col menu__item-text">
                    <img className="imageTitle" src={Forbes} alt="Forbes" />
                    <h3>Best practices for devops observability</h3>
                    <h3 className="lightText" >January 23, 2023</h3>
                  </div>
                  <div className="col blog-col menu__item-text">
                    <p>Founded in 2013, Digialpha is a leading global technology consultancy that integrates strategy, design. Founded 2013, Digialpha is a leading global technology consultancy.</p>
                    <Link className="readMoreLink"  to="#">Read More <ReadMore /></Link>
                  </div>
                </Link>
              </li>
              <li >
                
                <Link
                  to="#"
                  className="no-gutters row clickable-anchor menu__item"
                >
                  <div className="col blog-col menu__item-text blog-title">
                    <h4>/ Insight — News</h4>
                  </div>
                  <div className="col blog-col menu__item-text">
                    <img className="imageTitle" src={Forbes} alt="Forbes" />
                    <h3>Best practices for devops observability</h3>
                    <h3 className="lightText" >January 23, 2023</h3>
                  </div>
                  <div className="col blog-col menu__item-text">
                    <p>Founded in 2013, Digialpha is a leading global technology consultancy that integrates strategy, design. Founded 2013, Digialpha is a leading global technology consultancy.</p>
                    <Link className="readMoreLink"  to="#">Read More <ReadMore /></Link>
                  </div>
                </Link>
              </li>
          
        </ul>
      </div>
      {/* <svg class="cursor" width="80" height="80" viewBox="0 0 80 80">
            <circle class="cursor__inner" cx="40" cy="40" r="20"/>
        </svg> */}
      
        <div className="custom-pagination-wrap">
          <div className="container">
            <Pagination
              // total={blogData?.pagination?.total}
              itemRender={itemRender}
              // pageSize={blogData?.pagination?.posts_per_page}
              // onChange={handlePageClick}
              // current={blogData?.pagination?.current_page}
            />
          </div>
        </div>
      
    </div>

        {/* <Pagination /> */}
        <GetInTouch />
      </div>
    </div>
  );
};
export default News;
