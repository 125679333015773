import { useState, useEffect } from "react";
export const useDebounce = (value, milliSeconds, callback) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
      if(callback) callback(value); // callback function with buffered value
    }, milliSeconds);
    return () => {
      clearTimeout(handler);
    };
  }, [value, milliSeconds]);
  return debouncedValue; // return buffered value
};
