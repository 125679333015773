import React, { useEffect, useState, useRef, } from "react";
import { Link,useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { Helmet } from "react-helmet";
import ServiceContentCard from "../../components/ServiceContentCard";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import CustomLink from "../../components/Common/CustomLink";
import careersBanner from "../../assets/images/careers/careerBanner.png";
import officeLocationMap from "../../assets/images/careers/officeLocationMap.png";
import offertinImg1 from "../../assets/images/Digitla-Prod-Offering/Product-strategy.jpg";
import developmentIcon from "../../assets/images/icon/growth-icon.svg";
import { ReactComponent as ReferIcon } from "../../assets/images/icon/megaphone.svg";
import hi5Icon from "../../assets/images/icon/hand.svg";
import graphIcon from "../../assets/images/icon/party-icon.svg";
import CareIcon from "../../assets/images/icon/care-icon.svg";
import TeamImage from "../../assets/images/careers/team.jpg";

import SupportIcon from "../../assets/images/icon/support.svg";
import FistIcon from "../../assets/images/icon/fist.svg";
import TransparencyIcon from "../../assets/images/icon/transparency.svg";
import HandshakeIcon from "../../assets/images/icon/handshake.svg";
import InnovatingIcon from "../../assets/images/icon/innovating.svg";
import TargetIcon from "../../assets/images/icon/target.svg";

import CardImage from "../../assets/images/services-detail/card/sampleImg.jpeg";

import CardImage1 from "../../assets/images/careers/benefits-images/health-wellness.jpg";
import CardImage2 from "../../assets/images/careers/benefits-images/financial-wellbeing.jpg";
import CardImage3 from "../../assets/images/careers/benefits-images/flexibility.jpg";
import CardImage4 from "../../assets/images/careers/benefits-images/personal-development.jpg";
import CardImage5 from "../../assets/images/careers/benefits-images/betacrafters-extra.jpg";

import CareerCard1 from "../../assets/images/careers/careercard1.png";
import SliderImg1 from "../../assets/images/careers/sliderImg1.png";
import SliderImg2 from "../../assets/images/careers/sliderImg2.png";
import { ReactComponent as LocationIcon } from "../../assets/images/icon/location.svg";
import { ReactComponent as ArrowIcon } from "../../assets/images/icon/arrowIcon.svg";
import { ReactComponent as RightArrow } from "../../assets/images/icon/gray-arrow-right.svg";
import ShiftLeftArrow from "../../assets/images/icon/shift-left-arrow.svg";
import ShiftRightArrow from "../../assets/images/icon/shift-right-arrow.svg";
import "../../assets/css/pages/careers.scss";
import { careerActions } from "../../Stores/Actions/careerActions";
import { useSelector, useDispatch } from "react-redux";

const Careers = () => {
  const [scroll, setScroll] = useState(false);
  const [scrolDenefitsSection, setScrolDenefitsSection] = useState(false);
  const [scrollServicesSlider, setScrollServicesSlider] = useState(false);
  const dispatch = useDispatch();
  const navigateTo = useNavigate();

  

    const [slideIndex, setSlideIndex] = useState(0);
    const sliderRef = useRef();

    useEffect(() => {
        const element = document.getElementById("servicesSliderSection");
        let pos = element.offsetTop - (window.innerHeight / 2);

        window.addEventListener("scroll", () => {
            if (!scroll && window.scrollY > pos) {
                setScrollServicesSlider(true)
            }
        });
        return () => {
            window.removeEventListener('scroll', () => {
                setScrollServicesSlider(false)
            })
        }
    }, []);

    useEffect(() => {
        let pos3 = document.getElementById("denefitsSection").offsetTop - (window.innerHeight / 2);
        window.addEventListener("scroll", () => {
            if (!scroll && window.scrollY > pos3) {
                setScrolDenefitsSection(true);
            }
        });
        return () => {
            window.removeEventListener('scroll', () => {
                setScrolDenefitsSection(false)
            })
        }
    }, []);

    function animateFrom(elem, direction, index) {
        //console.log(index, "index")
        direction = direction || 1;
        var x = 0,
            y = direction * 100;
        if (elem.classList.contains("gs_reveal_fromLeft")) {
            x = -100;
            y = 0;
        } else if (elem.classList.contains("gs_reveal_fromRight")) {
            x = 100;
            y = 0;
        }
        // elem.style.transform = "translate(" + x + "px, " + y + "px)";
        // elem.style.backgroundPosition = x + "px, " + y + "px";
        elem.style.opacity = "0";
        gsap.fromTo(elem, { x: x, y: y, autoAlpha: 0 }, {
            duration: 1.25,
            x: 0,
            y: 0,
            autoAlpha: 1,
            ease: "expo",
            overwrite: "auto"
        });

    }

    function hide(elem) {
        gsap.set(elem, { autoAlpha: 0 });
    }
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        gsap.utils.toArray(".gs_reveal").forEach(function (elem, index) {
            hide(elem); // assure that the element is hidden when scrolled into view
            ScrollTrigger.create({
                trigger: elem,
                onEnter: function () { animateFrom(elem, null, index) },
                onEnterBack: function () { animateFrom(elem, -1, index) },
                onLeave: function () { hide(elem) } // assure that the element is hidden when scrolled into view
            });
        });
        gsap.utils
            .toArray(".card img")
            .forEach((section, i) => {
                const heightDiff =
                    section.offsetHeight - section.parentElement.offsetHeight;

                gsap.fromTo(
                    section,
                    {
                        y: section.parentElement.offsetHeight - 300
                    },
                    {
                        y: -230,
                        ease: "none",
                        scrollTrigger: {
                            trigger: section.parentElement,
                            scrub: true
                        }
                    }
                );
            });
    }, [])

    function NextArrow(props) {
        const { className, onClick } = props;
        return (
            <button type="button" disabled={slideIndex === 4} className={className} onClick={onClick}>
                <img src={ShiftRightArrow} alt="icon" />
            </button>
        );
    }

    function PrevArrow(props) {
        const { className, onClick } = props;
        return (
            <button type="button" disabled={slideIndex === 0} className={className} onClick={onClick}><img src={ShiftLeftArrow} alt="icon" /></button>
        );
    }

    // const settings = {
    //     dots: true,
    //     fade: false,
    //     autoplay: false,
    //     infinite: true,
    //     autoplaySpeed: 4000,
    //     speed: 3000,
    //     slidesToShow: 2,
    //     slidesToScroll: 1,
    //     arrows: true,
    //     centerMode: false,
    //     centerPadding: '0px',
    //     variableWidth: true,
    //     nextArrow: <NextArrow />,
    //     prevArrow: <PrevArrow />,
    //     responsive: [
    //         {
    //             breakpoint: 768,
    //             settings: {
    //                 slidesToShow: 2
    //             }
    //         }
    //     ]

    //   }

    const handelReferFriend = () =>{
      console.log("hi 194")
      navigateTo('/refer-friend')
    }

  useEffect(() => {
    let pos3 =
      document.getElementById("denefitsSection").offsetTop -
      window.innerHeight / 2;
    window.addEventListener("scroll", () => {
      if (!scroll && window.scrollY > pos3) {
        setScrolDenefitsSection(true);
      }
    });
    return () => {
      window.removeEventListener("scroll", () => {
        setScrolDenefitsSection(false);
      });
    };
  }, []);

  function animateFrom(elem, direction, index) {
    //console.log(index, "index")
    direction = direction || 1;
    var x = 0,
      y = direction * 100;
    if (elem.classList.contains("gs_reveal_fromLeft")) {
      x = -100;
      y = 0;
    } else if (elem.classList.contains("gs_reveal_fromRight")) {
      x = 100;
      y = 0;
    }
    // elem.style.transform = "translate(" + x + "px, " + y + "px)";
    // elem.style.backgroundPosition = x + "px, " + y + "px";
    elem.style.opacity = "0";
    gsap.fromTo(
      elem,
      { x: x, y: y, autoAlpha: 0 },
      {
        duration: 1.25,
        x: 0,
        y: 0,
        autoAlpha: 1,
        ease: "expo",
        overwrite: "auto",
      }
    );
  }

  const careerDetailsResults = useSelector(
    (store) => store.careerReducer.careerDetailsResults
  );
// const careerDetailsResults = { data: [...new Array(1)] };
//   console.log("careerDetailsResults", careerDetailsResults);

  useEffect(() => {
    dispatch(careerActions.jobPostAction({}));
  }, []);

  function hide(elem) {
    gsap.set(elem, { autoAlpha: 0 });
  }
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.utils.toArray(".gs_reveal").forEach(function (elem, index) {
      hide(elem); // assure that the element is hidden when scrolled into view
      ScrollTrigger.create({
        trigger: elem,
        onEnter: function () {
          animateFrom(elem, null, index);
        },
        onEnterBack: function () {
          animateFrom(elem, -1, index);
        },
        onLeave: function () {
          hide(elem);
        }, // assure that the element is hidden when scrolled into view
      });
    });
    gsap.utils.toArray(".card img").forEach((section, i) => {
      const heightDiff =
        section.offsetHeight - section.parentElement.offsetHeight;

      gsap.fromTo(
        section,
        {
          y: section.parentElement.offsetHeight - 300,
        },
        {
          y: -230,
          ease: "none",
          scrollTrigger: {
            trigger: section.parentElement,
            scrub: true,
          },
        }
      );
    });
  }, []);

  function NextArrow(props) {
    const { className, onClick } = props;
    return (
      <button
        type="button"
        disabled={slideIndex === 4}
        className={className}
        onClick={onClick}
      >
        <img src={ShiftRightArrow} alt="icon" />
      </button>
    );
  }

  function PrevArrow(props) {
    const { className, onClick } = props;
    return (
      <button
        type="button"
        disabled={slideIndex === 0}
        className={className}
        onClick={onClick}
      >
        <img src={ShiftLeftArrow} alt="icon"/>
      </button>
    );
  }

  const settings = {
    dots: true,
    fade: false,
    autoplay: false,
    infinite: true,
    autoplaySpeed: 4000,
    speed: 3000,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    centerMode: false,
    centerPadding: "0px",
    variableWidth: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  return (
    <div className="wrapper">
      <Helmet>
        <title>
          Careers at Digialpha | Global Technology & IT Careers at Digialpha
        </title>
  
<meta name="title" content="Digialpha - Global technology consulting and services company"/>
<meta name="description" content="Global technology"/>


<meta property="og:type" content="website"/>
<meta property="og:url" content="https://demo.digialpha.com/careers"/>
<meta property="og:title" content="Digialpha - Global technology consulting and services company"/>
<meta property="og:description" content="Global technology"/>
<meta property="og:image" content=""/>
<meta property="og:image:width" content="755" />
<meta property="og:image:height" content="422" />

<meta property="twitter:card" content="summary_large_image"/>
<meta property="twitter:url" content="https://demo.digialpha.com/careers"/>
<meta property="twitter:title" content="Digialpha - Global technology consulting and services company"/>
<meta property="twitter:description" content="Global technology"/>
<meta property="twitter:image" content=""></meta>
      </Helmet>
      <div className="careers-wrapper home-wrapper">
        <div className="careers-page home-page page-about-us">
          <div className="section-1 first">
            <div className="product-banner">
              <div className="banner-section">
                <div className="container banner-container">
                  <div className="left-content first-paragraph">
                    <h5 className="tagline">Careers at Digialpha</h5>
                    <h1 className="tagline">
                      Together we build extraordinary <br />
                      and challenge the status quo
                    </h1>
                    <p className="banner-para">
                      We are a community of innovators, engineers, technology
                      builders, data scientists, operations specialists and
                      more.
                    </p>
                    <CustomLink
                      to="/careers/current-opportunities"
                      renderSvg={true}
                    >
                      <span>Find your perfect role</span>
                    </CustomLink>
                  </div>
                </div>
              </div>
            </div>
            <a className="custom-link white-wrapper" href="#">
              <h5>Featured Whitepaper </h5>
              <h4>
                <span>A tool-first, automated approach to scaling your </span>
                <span>DevSecOps organization</span>
              </h4>
            </a>
          </div>
          <div
            id="denefitsSection"
            className={
              scrolDenefitsSection
                ? "differentiators-section animate"
                : "differentiators-section"
            }
          >
            <div className="container pd-top">
              <h5>
                <div className="line">
                  <div className="lineIn">Our Core Values </div>
                </div>
              </h5>
              <h1>
                <div className="line">
                  <div className="lineIn">
                    Our values are part of everything we built - including
                    careers
                  </div>
                </div>
              </h1>
              <div className="content-wrapper d-flex">
                <div className="row">
                  <div className="col diff-content">
                    <div className="icon-wrap">
                      <img src={TargetIcon} alt="rifht-size-icon" />
                    </div>
                    <h3>Customer centricity</h3>
                    <p>
                      Our customers are our number one priority, and we know if
                      they’re successful, so are we.
                    </p>
                  </div>
                  <div className="col diff-content">
                    <div className="icon-wrap">
                      <img src={InnovatingIcon} alt="icon" />
                    </div>
                    <h3>Never stop innovating</h3>
                    <p>
                      We are a company of builders, and we are driven to
                      confront the hard problems and solve them.
                    </p>
                  </div>
                  <div className="col diff-content">
                    <div className="icon-wrap">
                      <img src={HandshakeIcon} alt="icon" />
                    </div>
                    <h3>Act with integrity</h3>
                    <p>
                      We always treat each other, our customers, our partners,
                      and our extended community with honesty, decency, and
                      respect.
                    </p>
                  </div>
                  <div className="col diff-content">
                    <div className="icon-wrap">
                      <img src={TransparencyIcon} alt="icon" />
                    </div>
                    <h3>Be transparent</h3>
                    <p>
                      We know that maintaining an open flow of information
                      across teams, among individuals, up and down and around
                      the company, is crucial to how we work.
                    </p>
                  </div>
                  <div className="col diff-content">
                    <div className="icon-wrap">
                      <img src={FistIcon} alt="icon" />
                    </div>
                    <h3>Empower our people</h3>
                    <p>
                      We are a diverse, passionate community of people who
                      deeply care about our products, brand, and culture.
                    </p>
                  </div>
                  <div className="col diff-content">
                    <div className="icon-wrap">
                      <img src={SupportIcon} alt="icon" />
                    </div>
                    <h3>Supporting each other</h3>
                    <p>
                      We collaborate and partner to build and solve together.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="careers-location-section">
            <div className="container">
              <div className="row">
                <div className="col ecosystem-content">
                  <h5>Why Digialpha?</h5>
                  <h1>Starting here is just the beginning.</h1>

                  {/* <div className="progress-bar mobileHide">
                                <CustomLink to="#" renderSvg={true}>
                                    Learn More
                                </CustomLink>
                            </div> */}
                  {/* <div className='location-wrap'>
                                India  |  Singapore  |  US  |  UK  |  UAE
                            </div> */}
                  <h6>
                    Five aspects to develop a career you love at Digialpha
                  </h6>
                  <ul>
                    <li>
                      <span className="check-icon"></span>
                      <h4>Exceptional leadership</h4>
                    </li>
                    <li>
                      <span className="check-icon"></span>
                      <h4>Accelerated career development pathway</h4>
                    </li>
                    <li>
                      <span className="check-icon"></span>
                      <h4>Diverse learning opportunities</h4>
                    </li>
                    <li>
                      <span className="check-icon"></span>
                      <h4>Global career paths</h4>
                    </li>
                    <li>
                      <span className="check-icon"></span>
                      <h4>Diversity, equity and inclusion</h4>
                    </li>
                  </ul>
                </div>
                <div className="col ecosystem-img-col">
                  <div className="image-wrap">
                    <img src={TeamImage} alt="image" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-service-card">
            <div className="container">
              <div className="cInnerContent">
                <h5>Digialpha Benefits</h5>
                <h1 className="titleBig">
                  Resources that matter. Because you do.
                </h1>
                <div className="feature-row row">
                  <div className="col col1">
                    <ServiceContentCard
                      sectionClass={"gs_reveal"}
                      ContentClass={"ipsType_left"}
                      cardImage={CardImage1}
                      subTitle={"Health and wellness"}
                      content2={"Keeping Digialphaes healthy is our priority."}
                      ServicesLI={[
                        "Medical, life, and accidental insurance for employees and dependents",
                        "Employee assistance programs focused on mental health",
                        "Second medical opinion for you and your loved ones",
                      ]}
                    />
                  </div>
                  <div className="col col1">
                    <ServiceContentCard
                      sectionClass={"gs_reveal"}
                      ContentClass={"ipsType_left"}
                      cardImage={CardImage2}
                      subTitle={"Financial wellbeing"}
                      content2={
                        "Providing compensation and programs that give our employees financial peace of mind."
                      }
                      ServicesLI={[
                        "Competitive compensation",
                        "Employee Referral Bonus",
                        "Recurring Expense Stipend",
                        "One of the most inclusive ESOP plans",
                        "Compensation for overtimes",
                      ]}
                    />
                  </div>
                  <div className="col col1">
                    <ServiceContentCard
                      sectionClass={"gs_reveal"}
                      ContentClass={"ipsType_left"}
                      cardImage={CardImage3}
                      subTitle={"Flexibility and time off"}
                      content2={
                        "The time you need to recharge, and the flexibility to get your best work done."
                      }
                      ServicesLI={[
                        "Paid time off, including vacation, sick leave, maternity/parental leave, sabbatical, marriage, blood donation, holidays, and wellbeing days",
                        "Hybrid work model — two work from home days each week for most roles",
                        "Remote work opportunities available",
                      ]}
                    />
                  </div>
                  <div className="col col1">
                    <ServiceContentCard
                      sectionClass={"gs_reveal"}
                      ContentClass={"ipsType_left"}
                      cardImage={CardImage4}
                      subTitle={"Personal development"}
                      content2={
                        "Providing room and opportunities to grow — on your own and with your teammates."
                      }
                      ServicesLI={[
                        "Educational reimbursement",
                        "Peer learning and coaching platform",
                        "Participation in conferences",
                        "Expert talks & workshops",
                      ]}
                    />
                  </div>
                  <div className="col col1">
                    <ServiceContentCard
                      sectionClass={"gs_reveal"}
                      ContentClass={"ipsType_left"}
                      cardImage={CardImage5}
                      subTitle={"Digialphaers Extra"}
                      content2={
                        "Programming, spaces, and resources to support your growth, productivity, and wellbeing."
                      }
                      ServicesLI={[
                        "Workspace Equipment Budget",
                        "Meal allowance",
                        "Self-Sponsored Visa Reimbursement",
                        "Discount Programs",
                        "Support in difficult life circumstances",
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="graySection featured-jobs-section">
            <div className="container">
              <div className="row no-gutters section-heading">
                <div className="col left-content">
                  <h5>Find Your Perfect Role</h5>
                  <h1 className="titleBig">Join a team and inspire the work</h1>
                </div>
                <div className="col right-content">
                <button type="button" onClick={()=>handelReferFriend()}>
                    <ReferIcon /> Refer a friend
                  </button>
                  {/* <Link to="/contact-us">
                  <ReferIcon /> Refer a friend
                  </Link> */}
                </div>
                {/* <div className="col right-content">
                <div className="get-in-touch-btn">
                  <Link to="/contact-us">
                    Refer a Friend <RightArrow className="svg-animation" />
                  </Link>
                </div> */}
              {/* </div> */}
              </div>
              <div className="row">
                {careerDetailsResults?.data?.map((ele, index) => {
                  const lengthLimit =
                    careerDetailsResults?.data?.length > 6 ? 5 : 6;
                  if (index < lengthLimit) {
                    return (
                      <React.Fragment key={index}>
                        <div className="col" >
                          <div className="whiteCard">
                            <Link
                              className="full-clickable-link"
                              to={`/careers/current-opportunities/?id=${ele.id}`}
                            ></Link>
                            <h4>{ele?.title}</h4>
                            <p>Exp: {ele?.experience}</p>
                            <p className="cardbtm">
                              <span>
                                <LocationIcon /> {ele?.job_location} • {ele?.work_location}
                              </span>
                              <ArrowIcon className="arrowIcon" />
                            </p>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  }
                })}

                {/* 
                <div className="col">
                  <div className="whiteCard">
                    <Link
                      className="full-clickable-link"
                      to={"/careers/current-opportunities"}
                    ></Link>
                    <h4>Senior Product Designer</h4>
                    <p>Exp: 7-8 years</p>
                    <p className="cardbtm">
                      <span>
                        <LocationIcon /> Pune • In Office{" "}
                      </span>
                      <ArrowIcon className="arrowIcon" />
                    </p>
                  </div>
                </div>
                <div className="col">
                  <div className="whiteCard">
                    <Link
                      className="full-clickable-link"
                      to={"/careers/current-opportunities"}
                    ></Link>
                    <h4>Business Development Executive</h4>
                    <p>Exp: 3-5 years</p>
                    <p className="cardbtm">
                      <span>
                        <LocationIcon /> Singapore • Remote
                      </span>
                      <ArrowIcon className="arrowIcon" />
                    </p>
                  </div>
                </div>
                <div className="col">
                  <div className="whiteCard">
                    <Link
                      className="full-clickable-link"
                      to={"/careers/current-opportunities"}
                    ></Link>
                    <h4>Senior Backend Developer</h4>
                    <p>Exp: 7-8 years</p>
                    <p className="cardbtm">
                      <span>
                        <LocationIcon /> Pune • In Office
                      </span>
                      <ArrowIcon className="arrowIcon" />
                    </p>
                  </div>
                </div>
                <div className="col">
                  <div className="whiteCard">
                    <Link
                      className="full-clickable-link"
                      to={"/careers/current-opportunities"}
                    ></Link>
                    <h4>Quality Analyst Lead</h4>
                    <p>Exp: 7-8 years</p>
                    <p className="cardbtm">
                      <span>
                        <LocationIcon /> Singapore • Remote
                      </span>
                      <ArrowIcon className="arrowIcon" />
                    </p>
                  </div>
                </div>
                <div className="col">
                  <div className="whiteCard">
                    <Link
                      className="full-clickable-link"
                      to={"/careers/current-opportunities"}
                    ></Link>
                    <h4>Quality Analyst Lead</h4>
                    <p>Exp: 3-5 years</p>
                    <p className="cardbtm">
                      <span>
                        <LocationIcon /> Pune • In Office{" "}
                      </span>
                      <ArrowIcon className="arrowIcon" />
                    </p>
                  </div>
                </div> */}
                {/* {careerDetailsResults?.data?.length > 6 && ( */}
                  <div className="col">
                    <div className="whiteCard viewAllLink">
                      {/* <Link to="/careers/current-opportunities" >View all <RightArrow/></Link> */}
                      <CustomLink
                        to="/careers/current-opportunities"
                        renderSvg={true}
                      >
                        <h5>View all </h5>
                      </CustomLink>
                    </div>
                  </div>
                {/* )} */}
              </div>
            </div>
          </div>
          <div
            id="servicesSliderSection"
            className={
              scrollServicesSlider
                ? "services-slider-section animate"
                : "services-slider-section"
            }
          >
            <div className="container">
              <div className="section-title">
                <h2>Life at Digialpha</h2>
              </div>
            </div>
            <div className="slider-wrapper">
              <div className="container">
                <Slider
                  {...settings}
                  ref={sliderRef}
                  afterChange={(index) => {
                    setSlideIndex(index);
                  }}
                >
                  <div className="col slider-item">
                    <img src={SliderImg1} alt="slider image" />
                  </div>
                  <div className="col slider-item">
                    <img src={SliderImg2} alt="slider image" />
                  </div>
                  <div className="col slider-item">
                    <img src={SliderImg1} alt="slider image" />
                  </div>
                  <div className="col slider-item">
                    <img src={SliderImg2} alt="slider image" />
                  </div>
                  <div className="col slider-item">
                    <img src={SliderImg1} alt="slider image" />
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Careers;
