import React, { useEffect,useRef } from "react";

import LandingPage from "./landing/";
import Homepage from "../pages/Home";
import ServiceDetailPage from "../views/Services/ServiceDetail";
import CustomerExperienceAndDesignPage from "../views/Services/Customer-experience-and-design";
import DigitalProductEngineeringPage from "../views/Services/Digital-product-engineering";
import DigitalEngineeringServicesPage from "../views/Services/Digital-engineering-and-services";
import MVPServicesPage from "../views/Services/Mvp-services";
import DataAndAnalyticsPage from "../views/Services/Data-and-analytics";
import ApplicationServices from "../views/Services/Application-services";
import ContactUs from "./contact-us/ContactUs";
import Careers from "./careers/Careers";
import ComingSoon from "./comingSoon/ComingSoon";
import CareersDetail from "./careers/CareersDetail";
import BlogListingPage from "./Blogs/listing";
import Allinsights from "./Blogs/allinsights";
import News from "./Blogs/News";
import Events from "./Blogs/Events";
import Blogs from "./Blogs/Blogs";
import BlogDetailPage from "./Blogs/blog-detail";
import AboutUs from "./about/AboutUs";
import CaseStudy from "./case-study/CaseStudy";
import CaseStudyDetail from "./case-study/CaseStudyDetail";
import Privacy from "./static-pages/privacy";
import Cookie from "./static-pages/cookie-policy";
import DNSMPersonalInfo from "./static-pages/do-not-sell-my-personal-information";
import PageNotFound from "./page-not-found";
import Travel from "./industries/travel";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ReferFriend from "./ReferFriend/Refer-friend";
import { useSelector } from "react-redux";

import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
  Outlet,
} from "react-router-dom";
import Loader from "../components/Common/Loaded";
export function ScrollToTop() {
  const { pathname } = useLocation();
  const scollToSectionRef = useRef(null);

  useEffect(() => {
    setTimeout(function () {
      if (window.location.href.includes("current-opportunities")) {
        scollToSectionRef?.current?.scrollIntoView();
      }
      else {
        window.scrollTo(0, 0);
      }
    }, 100);
  }, [pathname]);

  return null;
}

function Root() {

  return (
   
    <>
    {/* {loader && <Loader/>} */}
      <BrowserRouter> 
        <Header />
        <ScrollToTop />
        <Routes>
          {/* protected routes */}
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/coming-soon" element={<ComingSoon />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/careers" element={<Careers />} />
          <Route
            path="/careers/current-opportunities"
            element={<CareersDetail />}
          />
          <Route path="/careers/current-opportunities/:category_slug/:slug/:id" element={<CaseStudyDetail />} />
          <Route path="/refer-friend" element={<ReferFriend/>} />
          <Route path="/insights" element={<BlogListingPage />} />
          <Route path="/allinsights" element={<Allinsights />} />
          <Route path="/news" element={<News />} />
          <Route path="/events" element={<Events />} />
          <Route path="/press-releases" element={<Blogs />} />
          <Route path="/insights/:category_slug/:slug" element={<BlogDetailPage />} />
          <Route path="/about-digialpha" element={<AboutUs />} />
          <Route path="/client-success" element={<CaseStudy />} />
          <Route path="/client-success/:category_slug/:slug/:id" element={<CaseStudyDetail />} />
          <Route path="/services" element={<ServiceDetailPage />} />
          <Route path="/services/services-detail" element={<Homepage />} />
          <Route
            path="/services/customer-experience-and-design"
            element={<CustomerExperienceAndDesignPage />}
          />
          <Route
            path="/services/digital-product-engineering"
            element={<DigitalProductEngineeringPage />}
          />
          <Route
            path="/services/digital-engineering"
            element={<DigitalEngineeringServicesPage />}
          />
          <Route
            path="/services/data-and-analytics"
            element={<DataAndAnalyticsPage />}
          />
          <Route
            path="/services/application-services"
            element={<ApplicationServices />}
          />
          <Route
            path="/services/performance-marketing"
            element={<MVPServicesPage />}
          />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/cookie-policy" element={<Cookie />} />
          <Route
            path="/do-not-sell-my-personal-information"
            element={<DNSMPersonalInfo />}
          />
          <Route path="/industries/travel" element={<Travel />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Footer />
      </BrowserRouter>
      {/* <Loader showLoader={showLoader} /> */}
    </>
  );
}

export default Root;
