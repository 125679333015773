import React, { useState, useEffect } from "react";
import { Helmet } from 'react-helmet';
import BannerContent from "../../components/BannerContent";
import GetInTouch from "../../components/GetInTouch";
import CheckboxMenu from "../../components/Common/MultipleSelect";
import ServiceCardNew from "../../components/ServiceCardNew";
// import "../../assets/JS/index.js";
import { Pagination } from "antd";
import "antd/dist/antd.css";
import "../../assets/css/menu.scss";
import { ReactComponent as RightArrow } from "../../assets/images/icon/gray-arrow-right.svg";
import { ReactComponent as RightArrow2 } from "../../assets/images/icon/right-arrow2.svg";
import { ReactComponent as LeftArrow2 } from "../../assets/images/icon/left-arrow2.svg";
import Setting from "../../assets/images/icon/setting.svg";
import Close from "../../assets/images/icon/close.svg";
import OfcLogo from "../../assets/images/case-study/official-nft-logo.png";
import CustomLink from "../../components/Common/CustomLink";
import BannerImage from "../../assets/images/case-study/case-study-banner.png";import { useDispatch, useSelector } from "react-redux";
import {
  caseStudyActions,
  featuredCaseStudyActions,
  resetCaseStudyActions
} from "../../Stores/Actions/caseStudyAction";
import "../../assets/css/pages/case-study.scss";

const CaseStudy = () => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState([]);
  const [caseStudyData, setCaseStudyData] = useState({data: []});
  // Case Study data from the store
  const caseStudyLocalData = useSelector((state) => state?.caseStudyReducer?.caseStudyResults);

  // featured Case Study data from the store       // As of now, we are not getting data from api response
  
  const featuredData = useSelector(
    (state) => state?.caseStudyReducer?.featuredCaseStudy?.data
  );

  console.log("featuredData",featuredData);

  useEffect(() => {
    let payload = {
      filter_by: [],
      page: 1,
      posts_per_page: 10,
      order_by: "oldest",
    };
  
    dispatch(caseStudyActions(payload));
    dispatch(featuredCaseStudyActions());
  }, []);

  useEffect(() => {
    
    if(caseStudyLocalData){
      setCaseStudyData(caseStudyLocalData);
    }else {
      setCaseStudyData([]);  
    }
  }, [caseStudyLocalData])

  
  function searchCaseStudy(filter, page) {
    let payload = {
      filter_by: [],
      page: 1,
      posts_per_page: 10,
      order_by: "oldest",
    };
    if (filter) {
      let newFilter = new Set(filter); // removing duplicate filter id
      payload.filter_by = [...newFilter];
    }

    if (page) {
      payload.page = page;
    }

    dispatch(caseStudyActions(payload));
  }

  const onFilterBlur = (closeModal) => {
    searchCaseStudy([...filter]);
    if(closeModal){
      setMobilePopup(false);
    }
  }
    
  const onCheckboxChange = (checkValue) => {
    if(checkValue.checked){
      setFilter([...filter, checkValue.value]);
    } else {
      const updateFilter = filter.filter(ele => ele !== checkValue.value);
      setFilter([...updateFilter]);
    }
  };


  function handlePageClick(pageNumber) {
    const offset = document.getElementsByClassName("cInnerContent")[0].offsetTop;
    window.scrollTo(0, offset);
    searchCaseStudy([...filter], pageNumber);
  }

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return (
        <a>
          <LeftArrow2 />
        </a>
      );
    }

    if (type === "next") {
      return (
        <a>
          <RightArrow2 />
        </a>
      );
    }

    return originalElement;
  };
  const [MobilePopup, setMobilePopup] = useState(false);
  console.log(filter.includes("33"), "filter.includes(ele.id)")
  return (
    <div className="wrapper">
      <Helmet>
        <title>Client Success Stories | Digialpha</title>
      </Helmet>
      <div className="page-case-study">
        <BannerContent
          BackgroundImages={featuredData?.image}
          title={featuredData?.category}
          subTitle={featuredData?.title}
        >
          <div className="content-wrap">
            <p>
              {featuredData?.short_description}
            </p>
            <div className="learn-more-wrap">
              <CustomLink
                to={`/careers/current-opportunities/${featuredData?.category_slug}/${featuredData?.slug}/${featuredData?.id}`}
                
                renderSvg={false}
                className="typeButton"
              >
                Learn More <RightArrow className="right-arrow" />
              </CustomLink>
              {/* <div className="client-logo">
                <img src={OfcLogo} alt="logo" />
              </div> */}
            </div>
          </div>
        </BannerContent>
        <div className="case-study-cards-wrap">
          <div className="container">
            <div className="sortWrapper-main">
              <div className="selectWrapper-main p-relative">
                <div className="case-heading desktop-hide mobile-show">
                  <h2>Case Studies</h2>
                </div>
                <div className="toggle-wrap desktop-hide mobile-show">
                  <button type="button" className="toggle" onClick={() => setMobilePopup(true)}><img src={Setting} alt="icon" /></button>
                  {MobilePopup && <div className="filter-popup">
                    <div className="filter-head">
                      <h3>Filter by</h3>
                      <button className="close-btn" type="button" onClick={() => setMobilePopup(false)}>
                        <img src={Close} alt="icon" />
                      </button>
                    </div>
                    <div className="filter-list-wrap">
                      <div className="filter-list">
                        <h2>Services</h2>
                        <ul>
                        {caseStudyData?.available_filter_options?.[1]?.options?.length > 0 &&
                          caseStudyData?.available_filter_options?.[1]?.options.map(ele=>{
                            return <li key={ele.id}>
                              <input
                                type="checkbox"
                                className="filter-check"
                                checked={filter.includes(ele.id.toString())}
                                value={ele.id}
                                onChange={(e) => onCheckboxChange(e.target)}
                              />
                              {ele.label}
                            </li>
                          })}
                        </ul>
                      </div>
                      <div className="filter-list">
                        <h2>Services</h2>
                        <ul>
                        {caseStudyData?.available_filter_options?.[0]?.options?.length > 0 &&
                          caseStudyData?.available_filter_options?.[0]?.options.map(ele=>{
                            return <li key={ele.id}>
                              <input
                                type="checkbox"
                                className="filter-check"
                                checked={filter.includes(ele.id.toString())}
                                value={ele.id}
                                onChange={(e) => onCheckboxChange(e.target)}
                              />{" "}
                              {ele.label}
                            </li>
                          })}
                        </ul>
                      </div>
                    </div>
                    <div className="apply-btn-wrap">
                      <button className="apply-btn" type="button" onClick={() => onFilterBlur(true)}>
                        Apply
                      </button>
                    </div>
                  </div>
                  }
                </div>

                <div className="selectWrapper mobile-hide">
                  <CheckboxMenu
                    options={
                      caseStudyData
                        ? caseStudyData?.available_filter_options?.[1]?.options
                        : []
                    }
                    onChange={onCheckboxChange}
                    placeholder="Service"
                    onFilterBlur={onFilterBlur}
                  />
                </div>
                <div className="selectWrapper mobile-hide">
                  <CheckboxMenu
                    options={
                      caseStudyData
                        ? caseStudyData?.available_filter_options?.[0]?.options
                        : []
                    }
                    onChange={onCheckboxChange}
                    placeholder="Industry"
                    onFilterBlur={onFilterBlur}
                  />
                </div>
              </div>
            </div>
            {caseStudyData?.data && <><div className="case-study-wrapper">
              <ServiceCardNew {...caseStudyData}/>
            </div>
            {caseStudyData?.pagination?.last_page > 1 && (
              <div className="custom-pagination-wrap">
                <div className="container">
                  <Pagination
                    total={caseStudyData?.pagination?.total}
                    itemRender={itemRender}
                    pageSize={caseStudyData?.pagination?.posts_per_page}
                    onChange={handlePageClick}
                    current={caseStudyData?.pagination?.current_page}
                  />
                </div>
              </div>
            )}
            </>}
          </div>
        </div>
        <GetInTouch />
      </div>
    </div>
  );
};

export default CaseStudy;
