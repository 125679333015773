import CustomLink from "./CustomLink";
import salesForceIcon from "../../assets/images/marque-img/salesforce.png";
import redHatIcon from "../../assets/images/marque-img/red-hat.png";
import windowsIcon from "../../assets/images/marque-img/windows.png";
import imbIcon from "../../assets/images/marque-img/ibm.png";
import awsIcon from "../../assets/images/marque-img/aws.png";
import "../../assets/css/component/partnerEcosystem.scss";

const PartnerEchoSystem = () => {
    return (
        <div className="ecosystem-section">
            <div className="row container ">
                <div className="col-1 ecosystem-content">
                    <h5>Partner Ecosystem</h5>
                    <h1>We are a partnership driven company
                    </h1>
                    <p>We form partnerships and strategic alliances with world-class
                        organisations to expand our service offerings and deliver
                        comprehensive solutions to clients. Strategic alliances with the
                        world’s leading companies enable us to provide complete
                        solutions to your business and IT challenges. </p>
                    <div className="progress-bar mobileHide">
                        <CustomLink to="#" renderSvg={true}>
                            Learn More
                        </CustomLink>
                        {/* <button className="hoverMebottom button"></button> */}
                    </div>
                </div>
                <div className="col-2 ecosystem-img">
                    <div className="marque-section ">
                        <div className="area">
                            <ul className="circles">
                                <li className="img-4"><img src={salesForceIcon}  alt="sales Force Icon"/></li>
                                <li className="img-2"><img src={imbIcon}  alt=" Imb Icon"/></li>
                                <li className="img-3"><img src={awsIcon}  alt="Aws Icon"/></li>
                                <li className="img-1"><img src={windowsIcon}  alt="Windows Icon"/></li>
                                <li className="img-5"><img src={redHatIcon}  alt="Red Hat Icon" /></li>
                            </ul>
                        </div>
                    </div>
                    <div className="progress-bar mobileShow">
                        {/* <button className="hoverMebottom button">Discover More</button> */}
                        <CustomLink to="#" renderSvg={true}>
                            Learn More
                        </CustomLink>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PartnerEchoSystem;