import { Link } from "react-router-dom";
import "../../assets/css/pages/comingSoon.scss";
import { ReactComponent as Instagram } from "../../assets/images/icon/instagram.svg";
import logoPath from "../../assets/images/logo/DG_alpha_light.svg";
import { FaYoutube } from "react-icons/fa";
import { AiFillFacebook, AiFillLinkedin, AiOutlineTwitter } from "react-icons/ai";
import Countdown from 'react-countdown';

const ComingSoon = () => {
    const time = 1032500000;
    return <div className="comingSoon" >
        <span className="siteLogo" >
            <img src={logoPath} alt="icon"/>
        </span>
        
        <div className="countDownWrap" >
            <Countdown className="counter" date={Date.now() + time} />
            <div className="countDownUnits" >
                <span>days</span>
                <span>hours</span>
                <span>mins</span>
                <span>sec</span>
            </div>
        </div>
        
        <div className="middleText" >
            <h3>SITE UNDER CONSTRUCTION</h3>
            <h1>COMING SOON</h1>
        </div>

        <div className="emailNotification" >
            <label> Get Notified!</label>
            <div className="inputWrap" >
                <input type="email" placeholder="Please enter your email" />  
                <button className="btn" >Notify Me</button>
            </div>
        </div>


        <div className="footerStyle" >
            <span className="copyText">&copy; 2022 Digialpha. All Rights Reserved.</span>
            <div className="social-media-icon">
                <ul>
                    <li> <Link target="_blank" to="/"><AiFillFacebook /></Link></li>
                    <li> <Link target="_blank" to="/"><Instagram /> </Link></li>
                    <li> <Link target="_blank" to="/"><AiFillLinkedin /></Link></li>
                    <li> <Link target="_blank" to="/"><AiOutlineTwitter /></Link></li>
                    <li> <Link target="_blank" to="/"><FaYoutube /></Link></li>
                </ul>
            </div>
        </div>
    </div>
}

export default ComingSoon;