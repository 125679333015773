import React from "react";

import { Checkbox, Popover, Button, Row, Col } from "antd";

class CheckboxMenu extends React.Component {
  state = {
    icon: {},
    selectedItems: [],
  };

  componentDidMount = () => {
    if (this.props.value && this.props.value.length) {
      this.setState(
        {
          selectedItems: [...this.props.value],
        },
        () => this.checkIconFilled()
      );
    }
  };

  onChange = (evnt) => {
    if(evnt.target.checked){
      this.setState({ selectedItems: [...this.state.selectedItems, evnt.target.value] }, () => {
        this.checkIconFilled();
      });
    } else {
      const updateFilter = this.state.selectedItems.filter(ele => ele !== evnt.target.value);
      this.setState({ selectedItems: [...updateFilter] }, () => {
        this.checkIconFilled();
    });
  }

    return this.props.onChange(evnt.target);
  };

  checkIconFilled = () => {
    if (this.state.selectedItems.length) {
      this.setState({ icon: { theme: "filled" } });
    } else {
      this.setState({ icon: {} });
    }
  };

  checkboxRender = () => {
    const _this = this;

    const groups = this.props?.options
      ?.map(function (e, i) {
        return i % 10 === 0 ? _this.props.options.slice(i, i + 10) : null;
      })
      .filter(function (e) {
        return e;
      });

    return (
      <Checkbox.Group  value={this.state.selectedItems}>
        <Row>
          {groups?.map((group, i) => {
            return (
              <Col
                key={"checkbox-group-" + i}
                span={Math.floor(24 / groups.length)}
              >
                {group.map((label) => {
                  return (
                    <Checkbox
                      key={label.id}
                      value={label.id}
                      style={{ display: "block", margin: "0" }}
                      onChange={this.onChange}
                    >
                      {label.label}
                    </Checkbox>
                  );
                })}
              </Col>
            );
          })}
        </Row>
      </Checkbox.Group>
    );
  };

  render() {
    const CheckboxRender = this.checkboxRender;
    const { placeholder, onFilterBlur } = this.props;
    return (
      <Popover
        content={<CheckboxRender />}
        trigger="click"
        placement="bottomLeft"
        onVisibleChange={visible => !visible ? onFilterBlur() : {}}
      >
        <Button className="custom-btn">{placeholder}</Button>
      </Popover>
    );
  }
}

export default CheckboxMenu;
