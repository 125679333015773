import React, { useEffect, useRef, useState } from 'react';
import parse from 'html-react-parser';
import { Anchor } from 'antd';
const { Link } = Anchor;

const Offerings = (props) => {
    const [scroll, setScroll] = useState(false);
    const [targetOffset, setTargetOffset] = useState(undefined);

    useEffect(() => {
        const element = document.getElementById("offeringV2-section");
        let pos = element.offsetTop - 70//- (window.innerHeight);

        setTargetOffset(window.innerHeight/2);

        window.addEventListener("scroll", () => {
            // console.log((element.offsetTop + element.offsetHeight - window.innerHeight) + "---" + window.scrollY)
            if (!scroll && window.scrollY > pos) {
                setScroll(true)
            } else {
                setScroll(false);
            }
            if (window.scrollY > (element.offsetTop + element.offsetHeight - window.innerHeight)) {
                setScroll(false);
            }
        });
        return () => {
            window.removeEventListener('scroll', () => {
                setScroll(false)
            })
        }
    }, []);
    return <div id="offeringV2-section" className={scroll ? "offeringV2 sticky" : "offeringV2"} >
    <div className='container'>
        <div className='scrollTabs'>
            <Anchor affix={false}  targetOffset={targetOffset} offsetTop={100} >
                {props.offeringList.map(item => <Link key={item.id} href={"#" + item.id} title={item.id}  />)}            
            </Anchor>
        </div>
        <div className='mainCont'>
            <div className='scrollImages'>
                <Anchor targetOffset={targetOffset} offsetTop={100} >
                    {props.offeringList.map(item => <Link key={item.id} href={"#" + item.id} title={<img src={item.image} alt="image"/>}  />)}
                </Anchor>
            </div>
            {props.offeringList.map(item => <div key={item.id} id={item.id} className="row sectionSpace" >
                <div className='col offeringContent' >
                    <h5 className='offeringTitle2' >Our offerings</h5>
                    <h3 className='offeringTitle' >{parse(item.title)}</h3>
                    <p className='offeringCopy' >{parse(item.copy)}</p>
                </div> 
                <div className='col offeringImg'>
                    <img src={item.image} alt="image" />
                </div>
            </div>)}
        </div>
    </div>
</div>
}
export default Offerings;
