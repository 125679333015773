import React, { useState, useEffect } from "react";
import CustomLink from "../components/Common/CustomLink";
import MarqueeImg1 from "../assets/images/client-logos/zensar.png";
import MarqueeImg2 from "../assets/images/client-logos/bigbinary.png";
import MarqueeImg3 from "../assets/images/client-logos/ccr.png";
import MarqueeImg4 from "../assets/images/client-logos/fixit.png";
import MarqueeImg5 from "../assets/images/client-logos/ford-8.png";
import MarqueeImg6 from "../assets/images/client-logos/forgeahead-main-logo.png";
import MarqueeImg7 from "../assets/images/client-logos/Geoawseome.png";
import MarqueeImg8 from "../assets/images/client-logos/Gimmonix.png";
import MarqueeImg9 from "../assets/images/client-logos/InT.png";
import MarqueeImg10 from "../assets/images/client-logos/Kyocera.png";
import MarqueeImg11 from "../assets/images/client-logos/logo-tavisca-new.png";
import MarqueeImg12 from "../assets/images/client-logos/midnight.png";
import MarqueeImg13 from "../assets/images/client-logos/Msx.png";
const Marquee = () => {
  const [isMobile, setIsMobile] = useState(false);
  const handleWindowSizeChange = () => {
    if (window.innerWidth <= 991) {
      setIsMobile(true);
    }
  }

  const cssMarquee1 = () => {
    if(document.getElementById('cssMarquee1')){

      let ele = document.getElementById('cssMarquee1').getElementsByTagName('li')[0];
      document.getElementById('cssMarquee1').append(ele)
  
      let ele2 = document.getElementById('cssMarquee2').getElementsByTagName('li')[0];
      document.getElementById('cssMarquee2').append(ele2)
    }
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    handleWindowSizeChange();
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  useEffect(() => {
    setInterval(function(){
      cssMarquee1()
  }, 10000)
  cssMarquee1()
  }, []);
  return (
    <div className="home-page marquee-section">
      <div className="container">
        <div className="row no-gutters marquee-wrapper">
          <div className="col col-6 marquee-text">
            <div className="text-wrapper">
              <h5>Over 1,000+ Positive Outcomes</h5>
              <h3>Our Clients</h3>
              <p>
                <span> We are proud to partner with clients of all stages,</span>{" "}
                <span>sizes and industries on meaningful work.</span>{" "}
              </p>
              {!isMobile && <CustomLink className="more-info-arrow" to="/client-success" renderSvg={true}>
                <span>Here are their stories</span>
              </CustomLink>}
            </div>
          </div>
          <div className="col col-6 marquee-img">
           
            <ul id="cssMarquee1" className="cssMarquee" >
              <li><div className="img-box">
                <img src={MarqueeImg1} alt="marqueimg" ></img>
              </div></li>
              <li><div className="img-box">
                <img src={MarqueeImg2} alt="marqueimg"></img>
              </div></li>
              <li><div className="img-box">
                <img src={MarqueeImg3} alt="marqueimg"></img>
              </div></li>
              <li><div className="img-box">
                <img src={MarqueeImg4} alt="marqueimg"></img>
              </div></li>
              <li><div className="img-box">
                <img src={MarqueeImg5} alt="marqueimg"></img>
              </div></li>
              <li><div className="img-box">
                <img src={MarqueeImg6} alt="marqueimg"></img>
              </div></li>
              <li><div className="img-box">
                <img src={MarqueeImg7} alt="marqueimg"></img>
              </div></li>
              <li><div className="img-box">
                <img src={MarqueeImg8} alt="marqueimg"></img>
              </div></li>
              <li><div className="img-box">
                <img src={MarqueeImg9} alt="marqueimg"></img>
              </div></li>
              <li><div className="img-box">
                <img src={MarqueeImg10} alt="marqueimg"></img>
              </div></li>
              <li><div className="img-box">
                <img src={MarqueeImg11} alt="marqueimg"></img>
              </div></li>
              <li><div className="img-box">
                <img src={MarqueeImg12} alt="marqueimg"></img>
              </div></li>
              <li><div className="img-box">
                <img src={MarqueeImg13} alt="marqueimg"></img>
              </div></li>
            </ul>
            
            <ul id="cssMarquee2" className="cssMarquee2" >

              <li><div className="img-box">
                <img src={MarqueeImg1} alt="marqueimg"></img>
              </div></li>
              <li><div className="img-box">
                <img src={MarqueeImg2} alt="marqueimg"></img>
              </div></li>
              <li><div className="img-box">
                <img src={MarqueeImg3} alt="marqueimg"></img>
              </div></li>
              <li><div className="img-box">
                <img src={MarqueeImg4} alt="marqueimg"></img>
              </div></li>
              <li><div className="img-box">
                <img src={MarqueeImg5} alt="marqueimg"></img>
              </div></li>
              <li><div className="img-box">
                <img src={MarqueeImg6} alt="marqueimg"></img>
              </div></li>
              <li><div className="img-box">
                <img src={MarqueeImg7} alt="marqueimg"></img>
              </div></li>
              <li><div className="img-box">
                <img src={MarqueeImg8} alt="marqueimg"></img>
              </div></li>
              <li><div className="img-box">
                <img src={MarqueeImg9} alt="marqueimg"></img>
              </div></li>
              <li><div className="img-box">
                <img src={MarqueeImg10} alt="marqueimg"></img>
              </div></li>
              <li><div className="img-box">
                <img src={MarqueeImg11} alt="marqueimg"></img>
              </div></li>
              <li><div className="img-box">
                <img src={MarqueeImg12} alt="marqueimg"></img>
              </div></li>
              <li><div className="img-box">
                <img src={MarqueeImg13} alt="marqueimg"></img>
              </div></li>

            </ul>
            
          </div>
        </div>
      </div>
      {isMobile && <CustomLink className="more-info-arrow mobile-link" to="#" renderSvg={true}>
        <span>Here are their stories</span>
      </CustomLink>}
    </div>
  );
};

export default Marquee;
