import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import CustomLink from "../components/Common/CustomLink";
import FooterLogoPath from "../assets/images/logo/footer-logo.svg";
import { ReactComponent as RightArrow } from "../assets/images/icon/gray-arrow-right.svg";
import { ReactComponent as RightArrow2 } from "../assets/images/icon/right-arrow2.svg";
import { ReactComponent as LeftArrow2 } from "../assets/images/icon/left-arrow2.svg";
import { ReactComponent as Sort } from "../assets/images/icon/sorting.svg";
import BlogImage from "../assets/images/tmp/blog1.webp";
import BlogMobileImage from "../assets/images/tmp/blog-mobile-image.png";
import CheckboxMenu from "../components/Common/MultipleSelect";
import { Pagination } from "antd";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import loadScript from "../assets/JS/index.js";
import {useOutsideHandler} from "../utils/index";
import {
  resetBlogActions
} from "../Stores/Actions/blogAction";
import "antd/dist/antd.css";
import "../assets/css/component/blog.scss";
import "../assets/css/menu.scss";


const itemRender = (_, type, originalElement) => {
  if (type === "prev") {
    return (
      <a>
        <LeftArrow2 />
      </a>
    );
  }

  if (type === "next") {
    return (
      <a>
        <RightArrow2 />
      </a>
    );
  }

  return originalElement;
};
const dataColors = [{
  dataColor: "#32353A",
  dataTextColor: "#DCDFEA",
},{
  dataColor: "#33251f",
  dataTextColor: "#ffb69c",
},{
  dataColor: "#181f32",
  dataTextColor: "#c1d9ff",
},{
  dataColor: "#181f32",
  dataTextColor: "#c1d9ff",
}]
const Blog = ({
  isHome,
  blogData,
  title,
  showAll,
  className,
  showFilters,
  searchBlog,
  filter,
  setFilter,
  featuredData
}) => {
  const dropdownRef = useRef();
  const dispatch = useDispatch();
  const [isLoaded, setLoaded] = useState(false);
  const [sortBy, setSortBy] = useState({label: "Oldest", value: "oldest"});
  const [sortDropdown, setSortDropdown] = useState(false);
  useOutsideHandler(dropdownRef, sortDropdown, setSortDropdown);
  useEffect(() => {
    if(blogData){
      loadScript()
    }
  }, [blogData])
  useEffect(() => {
    
    return () => {
      dispatch(resetBlogActions());
      if(document.getElementById("blog-script")){
        document.getElementById("blog-script").remove();
      }
    }
  }, [])

  // Custom deboucing function
  // const debounce = (func, delay) => {
  //   let debounceTimer;
  //   return function () {
  //     const context = this;
  //     const args = arguments;
  //     clearTimeout(debounceTimer);
  //     debounceTimer = setTimeout(() => func.apply(context, args), delay);
  //   };
  // };
  // const onFilterBlur = () => {
  //   searchBlog(selectOpt, [...filter]);
  // }
  // const debounceFnc = useCallback(debounce(handleSearch, 350), []);
  // const debounceDestinationFnc = useCallback(
  //   debounce(handleDestinationSearch, 350),
  //   []
  // );
  const onFilterBlur = () => {
    searchBlog(selectOpt, [...filter]);
  }
    
  const onCheckboxChange = (checkValue) => {
    if(checkValue.checked){
      setFilter([...filter, checkValue.value]);
    } else {
      const updateFilter = filter.filter(ele => ele !== checkValue.value);
      setFilter([...updateFilter]);
    }
  };

  const sortingFilters = !isEmpty(blogData?.available_sort_options)
    ? blogData?.available_sort_options.map((ele) => {
        return {
          label: ele.label,
          value: ele.id,
        };
      })
    : [{ label: "Oldest", value: "oldest" }];

  const [selectOpt, setSelectOpt] = useState(sortingFilters[0]);

  function handleSelect(e) {
    setSortBy(e); 
    searchBlog(e, filter, null);
  }

  function handlePageClick(pageNumber) {
    const offset = document.getElementsByClassName("blog-section")[0].offsetTop;
    window.scrollTo(0, offset);
    searchBlog(selectOpt, null, pageNumber);
  }

  return (
    <div className={`blog-section ${className || ""}`}>
      <div className="container">
        <ul className="menu">
          <li>
            <div className="row no-gutters">
              <div className="col blog-col mobile-hide"></div>
              <div className="col blog-col section-blog-col">
                <div className="section-title">
                  <h3>Blogs, News & Events</h3>
                  <h2>{title ? title : "Latest Insights"}</h2>
                </div>
              </div>
              <div className="col blog-col filter-col">
                {showFilters ? (
                  <div className="sortWrapper-main">
                    <div className="selectWrapper-main p-relative">
                      <div className="selectWrapper">
                        <CheckboxMenu
                          options={
                            blogData
                              ? blogData?.available_filter_options?.[1]?.options
                              : []
                          }
                          // value={["Analyst Insight", "Blog", "Brochure"]}
                          onChange={onCheckboxChange}
                          placeholder="Insight Type"
                          onFilterBlur={onFilterBlur}
                        />
                      </div>
                      <div className="selectWrapper">
                        <CheckboxMenu
                          options={
                            blogData
                              ? blogData?.available_filter_options?.[2]?.options
                              : []
                          }
                          // value={["Analyst Insight", "Blog", "Brochure"]}
                          onChange={onCheckboxChange}
                          placeholder="Service"
                          onFilterBlur={onFilterBlur}
                        />
                      </div>
                      <div className="selectWrapper">
                        <CheckboxMenu
                          options={
                            blogData
                              ? blogData?.available_filter_options?.[0]?.options
                              : []
                          }
                          // value={["Analyst Insight", "Blog", "Brochure"]}
                          onChange={onCheckboxChange}
                          placeholder="Industry"
                          onFilterBlur={onFilterBlur}
                        />
                      </div>
                    </div>
                    <div className="sort-option">
                      <button type="button" className={`custom-btn ${sortDropdown ? "active" : ""}`} onClick={() => {setSortDropdown(!sortDropdown)}}>
                      <Sort /> Sort by: <strong>{sortBy.label}</strong>
                      </button>
                      {sortDropdown && sortingFilters && <div className="sortDropdown" ref={dropdownRef}>
                          {sortingFilters.map(ele => {
                            return <button className={`dropdownOption ${sortBy.value === ele.value ? "active" : ""}`} onClick={() => {setSortDropdown(false); handleSelect(ele)}}>{ele.label}</button>
                          })}
                        </div>}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </li>
          {!showFilters && featuredData?.map((blog, index) => {
            var opts = {};
            if (blog.image) {
                opts["data-img"] = blog.image;
            }
            // if (blog.background_color && isHome) {
            //     opts["data-color"] = blog.background_color;

            // }
            // if (blog.text_color && isHome) {
            //     opts["data-text-color"] = blog.text_color;

            // }
            if (blog.id) {
                opts["data-id"] = blog.id;
            }
            return (
              <li key={blog?.id}>
                {/* <a
                  href="/blog-detail"
                  className="no-gutters row clickable-anchor menu__item"
                > */}
                <Link
                  // to={`/insights/${blog?.category_slug}/${blog?.slug}/${blog?.id}`}
                  to={`/insights/${blog?.category_slug}/${blog?.slug}`}
                  onClick={()=>{
                    localStorage.setItem("blogId",blog?.id)
                  }}
                  className="no-gutters row clickable-anchor menu__item"
                  {...opts}
                >
                  <div className="col blog-col blog-mobile-image">
                    {/* <img src={blog.thumbImage} /> */}
                    {/* <img src={blog?.image} alt="" /> */}
                  </div>
                  {/* <div className="row"> */}
                  <div className="col blog-col menu__item-text blog-title">
                    <h4>{blog.category}</h4>
                  </div>
                  <div className="col blog-col menu__item-text">
                    <h3>{blog.title}</h3>
                  </div>
                  <div className="col blog-col menu__item-text">
                  {blog?.short_description ? <p>{blog?.short_description?.substring(0, 230) + (blog?.short_description?.length > 200 ? "..." : "")}</p> : <p></p>}
                  </div>
                  {/* </div> */}
                  {/* <span className="hover-img">
                                <img src={BlogImage} alt="hover image" />
                            </span> */}
                </Link>
                {/* </a> */}
              </li>
            );
          })}
          {blogData?.data?.map((blog, index) => {
            var opts = {};
            if (blog.image) {
                opts["data-img"] = blog.image;
            }
            
            // if (blog.background_color && isHome) {
            //   opts["data-color"] = blog.background_color;
            // }
            // if (blog.text_color && isHome) {
            //     opts["data-text-color"] = blog.text_color;
            // }
            if (blog.id && isHome) {
                opts["data-id"] = blog.id;
            }
            return (
              <li key={blog?.id}>
                {/* <a
                  href="/blog-detail"
                  className="no-gutters row clickable-anchor menu__item"
                > */}
                <Link
                onClick={()=>{
                    localStorage.setItem("blogId",blog?.id)
                  }}
                  to={`/insights/${blog?.category_slug}/${blog?.slug}`}
                  className="no-gutters row clickable-anchor menu__item"
                  {...opts}
                >
                  <div className="col blog-col blog-mobile-image">
                    {/* <img src={blog.thumbImage} /> */}
                    {/* <img src={blog?.image} alt="" /> */}
                  </div>
                  {/* <div className="row"> */}
                  <div className="col blog-col menu__item-text blog-title">
                    <h4>{blog.category}</h4>
                  </div>
                  <div className="col blog-col menu__item-text">
                    <h3>{blog.title}</h3>
                  </div>
                  <div className="col blog-col menu__item-text">
                    {blog?.short_description ? <p>{blog?.short_description?.substring(0, 230) + (blog?.short_description?.length > 200 ? "..." : "")}</p> : <p></p>}
                  </div>
                  {/* </div> */}
                  {/* <span className="hover-img">
                                <img src={BlogImage} alt="hover image" />
                            </span> */}
                </Link>
                {/* </a> */}
              </li>
            );
          })}
          {!blogData?.data?.length && <li className="noRecordsFound"><h5>No results found for the selected search criteria</h5></li>}
        </ul>
        {showAll && (
          <div className="explore-btn">
            <div className="row no-gutters">
              <div className="col"></div>
              <div className="col">
                <CustomLink to="/insights" renderSvg={true}>
                  <h5>Explore all Insights </h5>
                </CustomLink>
                {/* <Link to="/blogs"> Explore the journal <RightArrow /></Link> */}
              </div>
              <div className="col"></div>
            </div>
          </div>
        )}
      </div>
      {/* <svg class="cursor" width="80" height="80" viewBox="0 0 80 80">
            <circle class="cursor__inner" cx="40" cy="40" r="20"/>
        </svg> */}
      {showFilters && blogData?.pagination?.last_page > 1 && (
        <div className="custom-pagination-wrap">
          <div className="container">
            <Pagination
              total={blogData?.pagination?.total}
              itemRender={itemRender}
              pageSize={blogData?.pagination?.posts_per_page}
              onChange={handlePageClick}
              current={blogData?.pagination?.current_page}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Blog;
