import React, { useEffect, useRef, useState } from 'react';
import { gsap } from "gsap";
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from "react-redux";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Blog from "../../components/Blog";
import WhyBetaCraft from "../../components/Common/WhyBetaCraft";
import CustomLink from "../../components/Common/CustomLink";
import GetInTouch from "../../components/GetInTouch";
import Offerings from "../../components/Offerings";
import { ReactComponent as RightArrow } from "../../assets/images/icon/right-arrow.svg"
import companyLogo1 from "../../assets/images/logo/ccr.png"
import companyLogo2 from "../../assets/images/logo/mo.png"
import companyLogo3 from "../../assets/images/logo/vtravel.png"
import companyLogo4 from "../../assets/images/logo/Msx.png"
import windowsIcon from "../../assets/images/marque-img/windows.png"
import imbIcon from "../../assets/images/marque-img/ibm.png"
import awsIcon from "../../assets/images/marque-img/aws.png"
import salesForceIcon from "../../assets/images/marque-img/salesforce.png"
import redHatIcon from "../../assets/images/marque-img/red-hat.png"
import offertinImg1 from "../../assets/images/Customer-Ex-offering/Digital-Marketing.jpg"
import offertinImg2 from "../../assets/images/Customer-Ex-offering/CX-strategy.jpg"
import offertinImg3 from "../../assets/images/Customer-Ex-offering/CX-Design.jpg"
import offertinImg4 from "../../assets/images/Customer-Ex-offering/CX-Engg.jpg"
import offertinImg5 from "../../assets/images/Customer-Ex-offering/Design-thinking.jpg"

import { ReactComponent as VideoIcon } from "../../assets/images/icon/video-btn-icon.svg";
import { blogData } from "../../pages/data";
import Slider from "react-slick";
import "../../assets/css/pages/home.scss";
import "../../assets/css/pages/industries.scss";

import { blogActions, featuredBlogActions } from "../../Stores/Actions/blogAction";
import { Anchor } from 'antd';
const { Link } = Anchor;

const Travel = (props) => {
    // const tabSliderRef = useRef();
    const dispatch = useDispatch()
    const featuredData = useSelector(
        (state) => state?.blogReducer?.featuredBlog?.data
    );
    const blogData = useSelector((state) => state?.blogReducer?.blogResults);
    const sliderRef = useRef();

    const [tabSliderRef, setTabSliderRef] = useState();
    const [tabThumbRef, setTabThumbRef] = useState();
    const [indexInit, setindexInit] = useState(0);
    const [images, setImages] = useState([companyLogo1, companyLogo2, companyLogo3, companyLogo4]);


    const [scroll, setScroll] = useState(false);
    const [scrollSuccessStory, setScrollSuccessStory] = useState(false);
    const [scrollContainerAnimate, setScrollContainerAnimate] = useState(false);
    const [scrollContainerAnimate2, setScrollContainerAnimate2] = useState(false);
    const [scrolDifferentiatorsSection, setScrolDifferentiatorsSection] = useState(false);

    const [targetOffset, setTargetOffset] = useState(undefined);
    useEffect(() => {
        setTargetOffset(window.innerHeight / 2);
    }, []);

    useEffect(() => {
        const element = document.getElementById("overviewSection");
        let pos = element.offsetTop - (window.innerHeight / 2);
        window.addEventListener("scroll", () => {
            if (!scroll && window.scrollY > pos) {
                setScroll(true)
            }
        });
        let payload = {
            // filter_by: [11],
            page: 1,
            posts_per_page: 3,
            order_by: "a-z",
        };

        dispatch(featuredBlogActions());
        dispatch(blogActions(payload));
        return () => {
            window.removeEventListener('scroll', () => {
                setScroll(false)
            })
        }
    }, []);
    useEffect(() => {
        const element2 = document.getElementById("successStory");
        let pos2 = element2.offsetTop - (window.innerHeight / 1.2);
        window.addEventListener("scroll", () => {
            if (window.scrollY > pos2) {
                setScrollSuccessStory(true);
            } else {
                setScrollSuccessStory(false)
            }
        });
    }, []);

    useEffect(() => {
        let pos3 = document.getElementById("differentiatorsSection").offsetTop - (window.innerHeight / 2);
        window.addEventListener("scroll", () => {
            if (!scroll && window.scrollY > pos3) {
                setScrolDifferentiatorsSection(true);
            }
        });
        return () => {
            window.removeEventListener('scroll', () => {
                setScrolDifferentiatorsSection(false)
            })
        }
    }, []);
    gsap.registerPlugin(ScrollTrigger);
    const ref = useRef(null);



    function hide(elem) {
        gsap.set(elem, { autoAlpha: 0 });
    }


    let tabSliderSettings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        center: true,
        autoplay: true,
        autoplaySpeed: 5000
    }
    const offeringList = [
        {
            id: "slideV21",
            title: "Digital Marketing, Brand,<br/> Content and Creative",
            copy: "Digital Marketing, Brand, Content and Creative - <br />Let our full-stack experience agencies illuminate your<br/> company's personality through design, performance <br/>video production, identity, and strategy.",
            image: offertinImg1
        },
        {
            id: "slideV22",
            title: "CX Strategy",
            copy: "Create a vision for the experience you want <br />to deliver and form the plan you <br /> need to make it happen.",
            image: offertinImg2
        },
        {
            id: "slideV23",
            title: "CX Design",
            copy: "At Digialpha, we offer great customer experience creation through our <br />expertise and methodology by understanding the user requirements, <br />issues and challenges. This approach gives business an edge to be <br />right at the first time creating exceptional customer journeys.",
            image: offertinImg3
        },
        {
            id: "slideV24",
            title: "CX Engineering",
            copy: "Once the CX design is approved by the business we bring in<br/> Technology Engineering to deliver the customer<br/> experience with scalability",
            image: offertinImg4
        },
        {
            id: "slideV25",
            title: "Design Thinking",
            copy: "<span>Design thinking is a non-linear, iterative process that<br /> teams use to understand users, challenge assumptions,<br /> redefine problems and create innovative solutions to<br />prototype and test. Involving five phases—Empathize,<br />Define, Ideate, Prototype and Test—it is most useful<br />to tackle problems that are ill-defined or unknown.",
            image: offertinImg5
        }

    ]
    return <div className='wrapper'>
        <Helmet>
            <title>Travel | Digialpha</title>
        </Helmet>
        <div className='service-wrapper home-wrapper page-travel-industries'>
            <div className="home-page" ref={ref}>
                <div className="section-1 first">
                    <div className="product-banner">
                        <div className="banner-section banner-travel-bg">
                            <div className="container banner-container">
                                <div className="left-content first-paragraph">
                                    <h5 className="tagline">Travel</h5>
                                    <h1 className="tagline">Digitally Transforming the end-to-end Traveler Experience</h1>
                                    <p className="banner-para">Future growth in the travel industry calls for bold action and a courageous mindset.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <CustomLink to="#" className="white-wrapper" renderSvg={false}>
                        <h5>Featured Whitepaper </h5>
                        <h4><span>A tool-first,
                            automated approach to scaling your </span><span>DevSecOps organization{" "}<RightArrow /></span>
                        </h4>
                    </CustomLink>
                </div>
                <div className={scroll ? "overview-section pd-top animate" : "overview-section pd-top"} id="overviewSection">
                    <div className="container text-wrap">
                        <h5>Overview</h5>
                        <div className="overview-para">
                            <p>The  travel and hospitality  industry has been turned upside down. There have been new market entrants, new business models – and new threats in the shape of the global pandemic.</p>
                            <p>As the travel and hospitality industry looks to rebuild, digital may be its saving grace. From chatbots to self-learning booking platforms, providers can create new momentum that both pleases customers and trims costs. We help you explore multiple paths forward and craft a digital strategy for the post-pandemic world.</p>
                        </div>

                        <div className="float-clear"></div>
                    </div>
                </div>
                <Offerings offeringList={offeringList} />

                <div id='successStory' className="success-story-section">
                    <div className="container pd-top">
                        <h5>Client Success Stories </h5>
                        <h1><span>Our Work Drives</span><span>Innovation Forward</span></h1>
                    </div>
                    <div className="tab-menu" id="tabs">
                        <div className='container' >
                            <Slider
                                asNavFor={tabThumbRef}
                                ref={(slider2) => setTabThumbRef(slider2)}
                                beforeChange={(e) => setindexInit(e + 1)}
                                arrows={true}
                                slidesToShow={4}
                                focusOnSelect={true}
                                autoplay={true}
                                speed={500}
                                slidesToScroll={1}
                                autoplaySpeed={5000}
                                pauseOnHover={false}
                            >
                                {images.map((ele, index) => {
                                    return <div className="imagesGroupWrap" key={index}>
                                        <img src={ele} alt="icon" />
                                    </div>
                                })}

                            </Slider>

                        </div>
                    </div>
                    <div className="sotry-tabs tab-container">

                        <div className="tab-content">
                            <Slider
                                className='tabSlider'
                                asNavFor={tabSliderRef}
                                {...tabSliderSettings}
                                ref={(tabSliderRef) => {
                                    setTabThumbRef(tabSliderRef);
                                }}
                                pauseOnHover={false}
                            // autoplay={true}
                            // speed={500}
                            // slidesToScroll={1}
                            // autoplaySpeed={500}
                            >
                                <div>
                                    <div className="tab">
                                        <div className="col-6 tab-left-section"><button className="audio-btn">Audio
                                            Visual (AV) </button>
                                            <div className="mobile-logo">
                                                <img src={companyLogo1} alt="company-logo-1" />
                                            </div>
                                            <h3><span>Simplifying moving to cloud for a </span><span>leading
                                                North American AV solutions</span><span>company</span></h3>
                                            <p>
                                                Today every software is being viewed and thought of as a Product. Every new Digital application is also thought of as a product hence Product Engineering is becoming the foundation to the Digital world.
                                            </p>
                                            <div className="progress-bar">
                                                <CustomLink to="#" renderSvg={true}>
                                                    Read the full story
                                                </CustomLink>
                                                {/* <button className="hoverMebottom button">Read
												the full story.</button> */}
                                            </div>
                                        </div>
                                        <div className="col-6 tab-right-section">
                                            <div className="tab-video ccr">
                                                <div className="video-text">
                                                    <p>Digialpha offered what we needed out of the box – plus we
                                                        wanted a system that we could reason about,
                                                        good documentation,
                                                        and had existing integrations.</p>
                                                    <h5>Andrei Horak,
                                                        CTO at Zensar<button><VideoIcon /></button></h5>
                                                </div>
                                            </div>
                                            <div className="progress-bar mobileShow">
                                                <CustomLink to="#" renderSvg={true}>
                                                    Read the full story
                                                </CustomLink>
                                            </div>
                                        </div>
                                        <div className="float-clear"></div>
                                    </div>
                                </div>
                                <div>
                                    <div className="tab">
                                        <div className="col-6 tab-left-section"><button className="audio-btn">Audio
                                            Visual (AV) </button>
                                            <div className="mobile-logo">
                                                <img src={companyLogo2} alt="company-logo-1" />
                                            </div>
                                            <h3><span>Simplifying moving to cloud for a </span><span>leading
                                                North American AV solutions</span><span>company</span></h3>
                                            <p>
                                                Today every software is being viewed and thought of as a Product. Every new Digital application is also thought of as a product hence Product Engineering is becoming the foundation to the Digital world.
                                            </p>
                                            <div className="progress-bar">
                                                <CustomLink to="#" renderSvg={true}>
                                                    Read the full story
                                                </CustomLink>
                                            </div>
                                        </div>
                                        <div className="col-6 tab-right-section">
                                            <div className="tab-video ccr">
                                                <div className="video-text">
                                                    <p>Digialpha offered what we needed out of the box – plus we
                                                        wanted a system that we could reason about,
                                                        good documentation,
                                                        and had existing integrations.</p>
                                                    <h5>Andrei Horak,
                                                        CTO at Zensar<button><VideoIcon /></button></h5>
                                                </div>
                                            </div>
                                            <div className="progress-bar mobileShow">
                                                <CustomLink to="#" renderSvg={true}>
                                                    Read the full story
                                                </CustomLink>
                                            </div>
                                        </div>
                                        <div className="float-clear"></div>
                                    </div>
                                </div>

                                <div>
                                    <div className="tab">
                                        <div className="col-6 tab-left-section"><button className="audio-btn">Audio
                                            Visual (AV) </button>
                                            <div className="mobile-logo">
                                                <img src={companyLogo3} alt="company-logo-1" />
                                            </div>
                                            <h3><span>Simplifying moving to cloud for a </span><span>leading
                                                North American AV solutions</span><span>company</span></h3>
                                            <p>
                                                Today every software is being viewed and thought of as a Product. Every new Digital application is also thought of as a product hence Product Engineering is becoming the foundation to the Digital world.
                                            </p>
                                            <div className="progress-bar">
                                                <CustomLink to="#" renderSvg={true}>
                                                    Read the full story
                                                </CustomLink>
                                            </div>
                                        </div>
                                        <div className="col-6 tab-right-section">
                                            <div className="tab-video ccr">
                                                <div className="video-text">
                                                    <p>Digialpha offered what we needed out of the box – plus we
                                                        wanted a system that we could reason about,
                                                        good documentation,
                                                        and had existing integrations.</p>
                                                    <h5>Andrei Horak,
                                                        CTO at Zensar<button><VideoIcon /></button></h5>
                                                </div>
                                            </div>
                                            <div className="progress-bar mobileShow">
                                                <CustomLink to="#" renderSvg={true}>
                                                    Read the full story
                                                </CustomLink>
                                            </div>
                                        </div>
                                        <div className="float-clear"></div>
                                    </div>
                                </div>

                                <div>
                                    <div className="tab">
                                        <div className="col-6 tab-left-section"><button className="audio-btn">Audio
                                            Visual (AV) </button>
                                            <div className="mobile-logo">
                                                <img src={companyLogo4} alt="company-logo-1" />
                                            </div>
                                            <h3><span>Simplifying moving to cloud for a </span><span>leading
                                                North American AV solutions</span><span>company</span></h3>
                                            <p>
                                                Today every software is being viewed and thought of as a Product. Every new Digital application is also thought of as a product hence Product Engineering is becoming the foundation to the Digital world.
                                            </p>
                                            <div className="progress-bar">
                                                <CustomLink to="#" renderSvg={true}>
                                                    Read the full story
                                                </CustomLink>
                                            </div>
                                        </div>
                                        <div className="col-6 tab-right-section">
                                            <div className="tab-video ccr">
                                                <div className="video-text">
                                                    <p>Digialpha offered what we needed out of the box – plus we
                                                        wanted a system that we could reason about,
                                                        good documentation,
                                                        and had existing integrations.</p>
                                                    <h5>Andrei Horak,
                                                        CTO at Zensar <button><VideoIcon /></button></h5>
                                                </div>
                                            </div>
                                            <div className="progress-bar mobileShow"><CustomLink to="#" renderSvg={true}>
                                                Read the full story
                                            </CustomLink></div>
                                        </div>
                                        <div className="float-clear"></div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
                <WhyBetaCraft />

                <div className="ecosystem-section">
                    <div className="row container ">
                        <div className="col-1 ecosystem-content">
                            <h5>Partner Ecosystem</h5>
                            <h1>We are a partnership driven company
                            </h1>
                            <p>We form partnerships and strategic alliances with world-class
                                organisations to expand our service offerings and deliver
                                comprehensive solutions to clients. Strategic alliances with the
                                world’s leading companies enable us to provide complete
                                solutions to your business and IT challenges. </p>
                            <div className="progress-bar mobileHide">
                                <CustomLink to="#" renderSvg={true}>
                                    Learn More
                                </CustomLink>
                                {/* <button className="hoverMebottom button"></button> */}
                            </div>
                        </div>
                        <div className="col-2 ecosystem-img">
                            <div className="marque-section ">
                                <div className="area">
                                    <ul className="circles">
                                        <li className="img-4"><img src={salesForceIcon} alt="sales Force Icon" /></li>
                                        <li className="img-2"><img src={imbIcon} alt="Imb Icon" /></li>
                                        <li className="img-3"><img src={awsIcon} alt="Aws Icon" /></li>
                                        <li className="img-1"><img src={windowsIcon} alt="Windows Icon" /></li>
                                        <li className="img-5"><img src={redHatIcon} alt="RedHat Icon" /></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="progress-bar mobileShow">
                                {/* <button className="hoverMebottom button">Discover More</button> */}
                                <CustomLink to="#" renderSvg={true}>
                                    Learn More
                                </CustomLink>
                            </div>
                        </div>
                    </div>
                </div>
                <Blog showAll={true} blogData={blogData} featuredData={featuredData} className="gray-section" />
                <GetInTouch />
                {/*  <div className="insight-scetion">
                    <div className="container">
                        <div className="heading pd-top">
                            <div className="heading-text">
                                <h5>Latest Thinkings </h5>
                                <h1>News and Insights </h1>
                            </div>
                            <div className="slider-btn">
                                <button onClick={prevSlide} className="pre-btn slide-arrow prev-arrow icon-arrow-left"></button>
                                <button onClick={nextSlide} className="next-btn slide-arrow next-arrow icon-arrow-right"></button>
                            </div>
                        </div>
                    </div>
                </div>
            <Blog /> */}
            </div>
        </div>
    </div>
}

export default Travel;