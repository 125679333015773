import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { Helmet } from 'react-helmet';
import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Link } from "react-router-dom";
import Blog from "../../components/Blog";
import CustomLink from "../../components/Common/CustomLink";
import { heroData, servicesData } from "./data";
import Slider from "react-slick";
import { ReactComponent as RightArrow } from "../../assets/images/icon/gray-arrow-right.svg";
import { ReactComponent as Thumb1 } from "../../assets/images/icon/gray-arrow-right.svg";
import { ReactComponent as MouseSvg } from "../../assets/images/icon/mouse.svg";
import homeScrollBanner from "../../assets/images/home/homeScrollBanner.png";
import weBuildText from "../../assets/images/home/Title.svg";
import appleTv from "../../assets/images/home/appleTv.png";
import smartWatch from "../../assets/images/home/smartWatch.png";
import mackBook from "../../assets/images/home/mackBook.png";
import Iphone from "../../assets/images/home/Iphone.png";
import mouse from "../../assets/images/home/mouse.svg";
// import "../../assets/css/common.scss";
import "../../assets/css/pages/landing.scss";
import Marquee from "../../components/Marquee";
import Services from "../../components/Services";
import AboutSection from "../../components/AboutSection";
import Careers from "../../components/Careers";
import GetInTouch from "../../components/GetInTouch";
import { useDispatch, useSelector } from "react-redux";
import { blogActions, featuredBlogActions } from "../../Stores/Actions/blogAction";

const LandingPage = () => {
  const [scroll, setScroll] = useState(false);
  const sliderRef = useRef();
  const featuredData = useSelector(
    (state) => state?.blogReducer?.featuredBlog?.data
  );
  const nextSlide = () => {
    sliderRef.current.slickNext();
  };
  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };
  const settings = {
    dots: false,
    // autoplay: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  // gotoNext = () => {
  //   sliderRef.current.slickNext();
  // }
  useEffect(() => {
    if (window.innerWidth <= 767) {
      document.body.classList.add('headerDark');
      const element = document.getElementById("aboutSectoin");
      let pos = element.offsetTop - (window.innerHeight / 5);
      window.addEventListener("scroll", () => {
        if (!scroll && window.scrollY > pos) {
          document.body.classList.remove('headerDark');
        } else {
          document.body.classList.add('headerDark');
        }
      });
      // return () => {
      //     window.removeEventListener('scroll', () => {

      //     })
      // }
    }
    gsap.registerPlugin(ScrollTrigger);
    if (window.innerWidth >= 768) {
      let headerClass = document.body;
      // let mouseImg = document.getElementsByClassName('mouseImg')[0];
      let titleTextWidth = "33vw";

      let tl1 = gsap.timeline({
        scrollTrigger: {
          trigger: ".scrollHomeBanner",
          start: "0 70px",
          end: "top -100%",
          onEnter: () => { headerClass.classList.add('headerDark') },
          onLeave: () => { headerClass.classList.remove('headerDark') },
          onEnterBack: () => { headerClass.classList.add('headerDark') },
          onLeaveBack: () => { headerClass.classList.remove('headerDark') },
          // markers:true,
          pinSpacing: true,
          pin: true,
          scrub: true,
          duration: 2
        }
      });
      tl1.set(".bannerText img", { width: "90vw", y: "-80%" })
      tl1.to(".bannerText img", {
        width: titleTextWidth,
        y: "0%",
      });
      tl1.to(".bannerText img", {
        width: titleTextWidth,
        y: "0%",
      });


      let tl2 = gsap.timeline({
        scrollTrigger: {
          trigger: ".scrollHomeBanner",
          start: "0 70px",
          end: "top -100%",
          // markers:true,
          pinSpacing: true,
          pin: true,
          scrub: true,
          duration: .5
        }
      })
      tl2.set(".fadInImages", { opacity: 0 });
      tl2.to(".fadInImages", {
        opacity: 0,
      })
      tl2.to(".fadInImages", {
        opacity: 0,
      })
      tl2.to(".fadInImages", {
        opacity: 1,
      })
  
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".scrollHomeBanner",
          start: "0 70px",
          end: "top -100%",
          // markers:true,
          pinSpacing: true,
          pin: true,
          scrub: true,
          duration: .5
        }
      })
      tl.set(".homeBannerImage, .bannerText p", { opacity: 0 });
      tl.to(".homeBannerImage, .bannerText p", {
        opacity: 0,
      })
      tl.to(".homeBannerImage, .bannerText p", {
        opacity: 1,
      })
  
      let imageTopRight = gsap.timeline({
        scrollTrigger: {
          trigger: ".scrollHomeBanner",
          start: "0 70px",
          end: "top -100%",
          // markers:true,
          pinSpacing: true,
          pin: true,
          scrub: true,
          duration: .5
        }
      });
  
      imageTopRight.set(".imageTopRight", { x: 0, y: 0 });
      imageTopRight.to(".imageTopRight", {
        x: 0, y: 0
      })
      imageTopRight.to(".imageTopRight", {
        x: 0, y: 0
      })
      imageTopRight.to(".imageTopRight", {
        x: 0, y: 0
      })
      imageTopRight.to(".imageTopRight", {
        x: -10, y: 10
      })
  
      let imageBottomRight = gsap.timeline({
        scrollTrigger: {
          trigger: ".scrollHomeBanner",
          start: "0 70px",
          end: "top -100%",
          // markers:true,
          pinSpacing: true,
          pin: true,
          scrub: true,
          duration: .5
        }
      });
  
      imageBottomRight.set(".imageBottomRight", { x: 0, y: 0 });
      imageBottomRight.to(".imageBottomRight", {
        x: 0, y: 0
      })
      imageBottomRight.to(".imageBottomRight", {
        x: 0, y: 0
      })
      imageBottomRight.to(".imageBottomRight", {
        x: 0, y: 0
      })
      imageBottomRight.to(".imageBottomRight", {
        x: -10, y: -10
      })
  
      let imageBottomLeft = gsap.timeline({
        scrollTrigger: {
          trigger: ".scrollHomeBanner",
          start: "0 70px",
          end: "top -100%",
          // markers:true,
          pinSpacing: true,
          pin: true,
          scrub: true,
          duration: .5
        }
      });
  
      imageBottomLeft.set(".imageBottomLeft", { x: 0, y: 0 });
      imageBottomLeft.to(".imageBottomLeft", {
        x: 0, y: 0
      })
      imageBottomLeft.to(".imageBottomLeft", {
        x: 0, y: 0
      })
      imageBottomLeft.to(".imageBottomLeft", {
        x: 0, y: 0
      })
      imageBottomLeft.to(".imageBottomLeft", {
        x: 10, y: -10
      })
  
      let mouseImg = gsap.timeline({
        scrollTrigger: {
          trigger: ".scrollHomeBanner",
          start: "0 70px",
          end: "top -100%",
          // markers:true,
          pinSpacing: true,
          pin: true,
          scrub: true,
          duration: .5
        }
      });
  
      mouseImg.set(".mouseImg", { opacity: 1 });
      mouseImg.to(".mouseImg", {
        opacity: 0
      })
      mouseImg.to(".mouseImg", {
        opacity: 0
      })
      mouseImg.to(".mouseImg", {
        opacity: 0
      })
      mouseImg.to(".mouseImg", {
        opacity: 0
      })
      mouseImg.to(".mouseImg", {
        opacity: 0
      })
    }
    if (window.innerWidth <= 767) {
      let headerClass = document.body;
      // let mouseImg = document.getElementsByClassName('mouseImg')[0];
      // let titleTextWidth = "90%";

      // let tl1 = gsap.timeline({
      //   scrollTrigger: {
      //     trigger: ".scrollHomeBanner",
      //     start: "0 70px",
      //     end: "top -100%",
      //     onEnter: () => { headerClass.classList.add('headerDark') },
      //     onLeave: () => { headerClass.classList.remove('headerDark') },
      //     onEnterBack: () => { headerClass.classList.add('headerDark') },
      //     onLeaveBack: () => { headerClass.classList.remove('headerDark') },
      //     // markers:true,
      //     pin: true,
      //     scrub: true,
      //     duration: 2
      //   }
      // });
      // tl1.set(".bannerText img", { width: "60%", y: "-60%" })
      // tl1.to(".bannerText img", {
      //   width: titleTextWidth,
      //   y: "0%",
      // });
      // tl1.to(".bannerText img", {
      //   width: titleTextWidth,
      //   y: "0%",
      // });

    }
    

    // }
  }, []);

  // getting blog data from the store
  const blogData = useSelector((state) => state?.blogReducer?.blogResults);

  const dispatch = useDispatch();

  useEffect(() => {
    let payload = {
      // filter_by: [11],
      page: 1,
      posts_per_page: 3,
      order_by: "a-z",
    };

    dispatch(featuredBlogActions());
    dispatch(blogActions(payload));
    return () => {
      let headerClass = document.body;
      headerClass.classList.remove('headerDark')
    }
  }, []);

  return <div id="page" className="site wrapper">
    <Helmet>
      <title>Digialpha - Global technology consulting and services company</title>
    </Helmet>
    <div id="feather" className="feather"></div>

    <main id="content" className="site-content main-home-page" role="main">
      {/* 
    <section id="intro" className="full-screen pt-5 blue">
      <h1>A cool title</h1>

      <div id="clouds-layer-1" className="clouds"></div>
      <div id="clouds-layer-2" className="clouds"></div>
    </section> */}
      <div className="scrollHomeBanner" >
        <div className="mouseImg" >
          <img src={mouse} alt="icon" />
        </div>
        <div className="fadInImages imageTopLeft" >
          <img src={appleTv} alt="image" />
        </div>
        <div className="fadInImages imageTopRight" >
          <img src={smartWatch} alt="image" />
        </div>
        <div className="fadInImages imageBottomRight" >
          <img src={mackBook} alt="image" />
        </div>
        <div className="fadInImages imageBottomLeft" >
          <img src={Iphone} alt="image" />
        </div>
        <div className="homeBannerImage" >
          <img src={homeScrollBanner} alt="image" />
        </div>
        <div className="bannerTextOuter" >
          <div className="bannerText">
            <h1><img src={weBuildText} alt="We build Extraordinary." /></h1>
            {/* <h1><span>.</span></h1> */}
            <p>Creating value for people and business through <br/> Total Experience (TX).</p>
          </div>
        </div>

      </div>

      <AboutSection />
      <Services data={servicesData} />
      <Marquee />

      <Blog showAll={true} isHome={true} blogData={blogData} featuredData={featuredData} />
      {/* Blog section */}
      <Careers />
      <GetInTouch />
    </main>
  </div >
};

export default LandingPage;
