import { caseStudyApiService } from "../../api/caseStudyService";
import { caseStudyConstants } from "../Constants";

export function caseStudyActions(payload) {
  return (dispatch) => {
    dispatch(request(caseStudyConstants.CASESTUDY_REQUEST));
    caseStudyApiService.caseStudyService(payload).then(
      (response) => {
        dispatch(success(caseStudyConstants.CASESTUDY_SUCCESS, response));
      },
      (error) => {
        dispatch(failure(caseStudyConstants.CASESTUDY_FAILURE, error));
      }
    );
  };
}

export function featuredCaseStudyActions() {
  return (dispatch) => {
    dispatch(request(caseStudyConstants.FEATURED_CASESTUDY_REQUEST));
    caseStudyApiService.featuredCaseStudyService().then(
      (response) => {
        dispatch(success("FEATURED_CASESTUDY_SUCCESS", response));
      },
      (error) => {
        dispatch(failure(caseStudyConstants.FEATURED_CASESTUDY_FAILURE, error));
      }
    );
  };
}
export function resetCaseStudyActions() {
  return (dispatch) => {
    dispatch(request(caseStudyConstants.RESET_FEATURED_CASESTUDY_REQUEST));
  };
}

export function caseStudyDetailsActions(payload) {
  return (dispatch) => {
    dispatch(request(caseStudyConstants.CASESTUDY_DETAILS_REQUEST));
    caseStudyApiService.caseStudyDetailsService(payload).then(
      (response) => {
        dispatch(success(caseStudyConstants.CASESTUDY_DETAILS_SUCCESS, response));
      },
      (error) => {
        dispatch(failure(caseStudyConstants.CASESTUDY_DETAILS_FAILURE, error));
      }
    );
  };
}

function request(action, payload) {
  return { type: action, payload };
}

function success(action, response) {
  return { type: action, response };
}

function failure(action, error) {
  return { type: action, error };
}
