import { careerService } from "../../api/careerService";
import { commonActions } from "./commonActions";
import { careerConstants } from "../Constants/career/careerConstanst";

export const careerActions = {
    jobPostAction,
};

// Get Affiliate Dashboard summary
function jobPostAction(payload) {
    return (dispatch) => {
        dispatch(request(careerConstants.CAREER_DETAILS_REQUEST));
        dispatch(commonActions.showLoader());
        return careerService
            .jobPostService(payload)
            .then((res) => {
                dispatch(
                    success(careerConstants.CAREER_DETAILS_SUCCESS, res)
                );
                dispatch(commonActions.hideLoader());
            })
            .catch((error) => {
                dispatch(
                    failure(careerConstants.CAREER_DETAILS_FAILURE, error)
                );
                dispatch(commonActions.hideLoader());
            });
    };
}



function request(action, payload) {
    return { type: action, payload };
}

function success(action, response) {
    return { type: action, response };
}

function failure(action, error) {
    return { type: action, error };
}
