import { Helmet } from 'react-helmet';
import "../../assets/css/pages/static-pages.scss";

const CookiePolicy = () => {
    return <div className="wrapper" >
        <Helmet>
            <title>Legal -Do Not Sell My Personal Information | Digialpha</title>
        </Helmet>
        <div className="static-pages-wrap page-cookie-policy">
            <div className="content-banner">
                <div className="container">
                    <h1>Digialpha Do not sell my info (CA)</h1>
                </div>
            </div>
            <div className="static-content">
                <div className="container">
                    <div className="content-group-wrap">
                        <div className="content-group">
                            <h3>What is Lorem Ipsum?</h3>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. </p>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        </div>
                        <div className="content-group">
                            <h3>What is Lorem Ipsum?</h3>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. </p>
                            <ul>
                                <li>Pellentesque vehicula sapien vitae efficitur ultricies.</li>
                                <li>Pellentesque et metus a orci vehicula efficitur in a urna.</li>
                                <li>Ut ornare sem a leo mattis consequat.</li>
                                <li>Donec et justo ultrices, scelerisque mauris eget, auctor sem.</li>
                                <li>Aliquam maximus justo in auctor cursus.</li>
                                <li>Nam malesuada lectus sed posuere fermentum.</li>
                                <li>Donec malesuada ligula eget laoreet hendrerit.</li>
                                <li>Integer venenatis erat ut mattis mollis.</li>
                                <li>Curabitur non turpis ut diam cursus aliquet.</li>
                            </ul>
                        </div>
                        <div className="content-group">
                            <h3>What is Lorem Ipsum?</h3>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. </p>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default CookiePolicy;