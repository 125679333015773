import React, { useEffect, useState } from "react";
import corevalues1 from "../assets/images/core-values/1.svg";
import corevalues2 from "../assets/images/core-values/2.svg";
import corevalues3 from "../assets/images/core-values/3.svg";
import corevalues4 from "../assets/images/core-values/4.svg";
import corevalues5 from "../assets/images/core-values/5.svg";
import corevalues6 from "../assets/images/core-values/6.svg";
import "../assets/css/component/core-values.scss";

const CoreValues = () => {
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        const element = document.getElementById("corporate-values");
        let pos = element.offsetTop - (window.innerHeight/2);
        
        window.addEventListener("scroll", () => {
            if (!scroll && window.scrollY > pos) {
                setScroll(true)
            }
        });
        return () => {
            window.removeEventListener('scroll', () => {
                setScroll(false)
            })
        }        
      }, []);
    return (
        <div id="corporate-values" className={scroll ? "core-values-section animate" : "core-values-section"}>
            <div className="container">
                <div className="section-title">
                    <h2>
                        <span className="line" ><span className="lineIn" >Our core values</span></span>
                    </h2>
                    <h3>
                        <span className="line" ><span className="lineIn" >Our values define who we are and how we work together to serve our clients.</span></span>
                    </h3>
                </div>
                <div className="card-group">
                    <div className="row">
                        <div className="col card-col">
                            <div className="card">
                                <div className="card-content">
                                    <div className="image">
                                        <img src={corevalues1} alt="core values" />
                                    </div>
                                    <h4>Customer centricity</h4>
                                    <div className="content">
                                        <p>
                                        Our customers are our number one priority, and we know if they’re successful, so are we.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col card-col">
                            <div className="card">
                                <div className="card-content">
                                    <div className="image">
                                        <img src={corevalues2} alt="core values" />
                                    </div>
                                    <h4>Never stop innovating</h4>
                                    <div className="content">
                                        <p>
                                        We are a company of builders, and we are driven to confront the hard problems and solve them.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col card-col">
                            <div className="card">
                                <div className="card-content">
                                    <div className="image">
                                        <img src={corevalues3} alt="core values" />
                                    </div>
                                    <h4>Act with integrity</h4>
                                    <div className="content">
                                        <p>
                                        We always treat each other, our customers, our partners, and our extended community with honesty, decency, and respect.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col card-col">
                            <div className="card">
                                <div className="card-content">
                                    <div className="image">
                                        <img src={corevalues4} alt="core values" />
                                    </div>
                                    <h4>Be transparent</h4>
                                    <div className="content">
                                        <p>
                                        We know that maintaining an open flow of information across teams, among individuals, up and down and around the company, is crucial to how we work.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col card-col">
                            <div className="card">
                                <div className="card-content">
                                    <div className="image">
                                        <img src={corevalues5} alt="core values" />
                                    </div>
                                    <h4>Empower our people</h4>
                                    <div className="content">
                                        <p>
                                        We are a diverse, passionate community of people who deeply care about our products, brand, and culture.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col card-col">
                            <div className="card">
                                <div className="card-content">
                                    <div className="image">
                                        <img src={corevalues6} alt="core values" />
                                    </div>
                                    <h4>Supporting each other</h4>
                                    <div className="content">
                                        <p>
                                        We collaborate and partner to build and solve together.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CoreValues;