import React, { Component } from "react";
import { Helmet } from 'react-helmet';
import ContactForm from "../../components/ContactForm";
import ContactCard from "../../components/ContactCard";
import "../../assets/css/component/contact-us.scss";

export class ContactUs extends Component {
  render() {
    return (
      <div className="wrapper">
        <Helmet>
          <title>Contact us - Send us a message | Digialpha</title>
        </Helmet>
        <ContactForm />
        <ContactCard />
      </div>
    );
  }
}

export default ContactUs;
