import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { ReactComponent as RightArrow } from "../assets/images/icon/gray-arrow-right.svg";
import "../assets/css/pages/page-not-found.scss";

const PageNotFound = () => {
    return <div className="wrapper">
        <div className="page-not-found">
            <Helmet>
                <title>Digialpha - Global technology consulting and services company</title>
            </Helmet>
            <div className="page-not-found-wrap">
                <div className="container">
                    <div className="page-not-found-box">
                        <h4>Oops! Something went wrong</h4>
                        <h1>The page you’re looking for can’t be found.</h1>
                        <div className="button-wrap">
                            <Link to="/">
                                Go back Home <RightArrow className="svg-animation" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div >
};

export default PageNotFound;
