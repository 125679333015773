import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BsChevronDown, BsChevronLeft } from "react-icons/bs";
import { IoIosCloseCircle } from "react-icons/io";
import CustomLink from "../components/Common/CustomLink";
import logoPath from "../assets/images/logo/DG_alpha_dark.svg";
import logoPath2 from "../assets/images/logo/DG_alpha_light.svg";
import { ReactComponent as Search } from "../assets/images/icon/search.svg";
import MenuImage from "../assets/images/home/header-menu.png";
import DownIcon from "../assets/images/icon/chevron-down.svg";
import { useOutsideHandler } from "../utils/";
const data = {
    "products": [
        {
            mainTitle:"All Services",
            titleLink:"/services",
            label: "Customer Experience (CX) & Design",
            links: [
                {
                    label: "Customer Experience & Design",
                    link: "/services/customer-experience-and-design"
                },
                {
                    label: "Digital Product \n Engineering",
                    link: "/services/digital-product-engineering"
                },
                {
                    label: "Digital Engineering",
                    link: "/services/digital-engineering"
                },
                {
                    label: "Data & \n Analytics",
                    link: "/services/data-and-analytics"
                },
            ]
        },
        {
            label: "Digital Product \n Engineering",
            links: [
                {
                    label: "Application \n Services",
                    link: "/services/application-services"
                },
                {
                    label: "Performance Marketing",
                    link: "/services/performance-marketing"
                },
            ]
        },
        // {
        //     label: "Digital Engineering \n & Services",
        //     links: [
        //         // {
        //         //     label: "Digital Engineering",
        //         //     link: "/services/digital-engineering"
        //         // },
        //         // {
        //         //     label: "Cloud Strategy & Execution",
        //         //     link: "/services/digital-engineering"
        //         // },
        //         // {
        //         //     label: "Transformation Services",
        //         //     link: "/services/digital-engineering"
        //         // },
        //     ]
        // },
        // {
        //     label: "Data & \n Analytics",
        //     links: [
        //         // {
        //         //     label: "Data Engineering",
        //         //     link: "/services/data-and-analytics"
        //         // },
        //         // {
        //         //     label: "Data Visualisation",
        //         //     link: "/services/data-and-analytics"
        //         // },
        //         // {
        //         //     label: "Data Science & Applied AI",
        //         //     link: "/services/data-and-analytics"
        //         // },
        //     ]
        // },
        // {
        //     label: "Application \n Services",
        //     links: [
        //         // {
        //         //     label: "Application Development & Maintenance",
        //         //     link: "/services/application-services"
        //         // },
        //         // {
        //         //     label: "Quality Engineering",
        //         //     link: "/services/application-services"
        //         // },
        //         // {
        //         //     label: "ERP Or Packaged Applications",
        //         //     link: "/services/application-services"
        //         // },
        //     ]
        // },
        {
            label: null,
            links: [],
            image: "",
            para: "A tool-first, automated to scaling your DevSecOps organization",
            linkText: "Learn More"
        }

    ],


    "industries": [
        {
            mainTitle:"All Industries",
            titleLink:"#",
            label: "",
            links: [
                {
                    label: "Travel",
                    link: "/industries/travel"
                },
                {
                    label: "Martech",
                    link: "#"
                },
                {
                    label: "Blockchain",
                    link: "#"
                },
                {
                    label: "Finance",
                    link: "#"
                }
            ]
        },
        {
            label: "",
            links: [
                {
                    label: "GIS",
                    link: "#"
                },
                {
                    label: "E-commerce",
                    link: "#"
                },
                {
                    label: "Software & Hi-Tech",
                    link: "#"
                },
                {
                    label: "Elearning",
                    link: "#"
                }
            ]
        },
        {
            label: null,
            links: [],
            image: "",
            para: "A tool-first, automated to scaling your DevSecOps organization",
            linkText: "Learn More"
        }
    ],
    "company": [
        {
            mainTitle:"About Digialpha",
            titleLink:"/about-digialpha",
            label: "About",
            links: [
                {
                    label: "Company Overview",
                    link: "/about-digialpha"
                },
                {
                    label: "Corporate Values",
                    link: "/about-digialpha/#corporate-values"
                },
                {
                    label: "Mission",
                    link: "/about-digialpha/#our-purpose"
                },
                {
                    label: "Vision",
                    link: "/about-digialpha/#our-vision"
                },
                {
                    label: "Leadership Team ",
                    link: "/about-digialpha/#leadership-team"
                }
            ]
        },
        {
            label: "Insights",
            links: [
                {
                    label: "Corporate Social Responsibility",
                    link: "/about-digialpha/#corporate-social-responsibility"
                },
                {
                    label: "Events",
                    link: "/events"
                },
                {
                    label: "In the News",
                    link: "/news"
                },
                {
                    label: "Press Releases",
                    link: "/press-releases"
                }
            ]
        },
        {
            label: null,
            links: [],
            image: "",
            para: "A tool-first, automated to scaling your DevSecOps organization",
            linkText: "Learn More"
        }
    ]
}


const mainMenu = [
    {
        label: "Services",
        hasChildren: true,
        link: null,
        menuKey: "products"
    },
    // {
    //     label: "Industries",
    //     hasChildren: true,
    //     link: null,
    //     menuKey: "industries"
    // },
    {
        label: "Client Success",
        hasChildren: false,
        link: "/client-success"
    },
    {
        label: "Insights",
        hasChildren: false,
        link: "/insights",
        menuKey: "insights"
    },
    {
        label: "Company",
        hasChildren: true,
        link: null,
        menuKey: "company"
    },
    {
        label: "Careers",
        hasChildren: false,
        link: "/careers"
    }
]
const Header = () => {
    const navigateTo = useNavigate();
    const menuRef = useRef(null);
    const [isMobile, setIsMobile] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [menuIndex, setMenuIndex] = useState(0);
    const [menuOptions, setMenuOptions] = useState(data["products"]);
    const [mobileMenu, setMobileMenu] = useState(false);
    const [SearchBar, setSearchBar] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [isShowLevel, setIsShowLevel] = useState(1);
    const [level3Menu, setLevel3Menu] = useState("");
    const [level2Menu, setLevel2Menu] = useState("");
    const [isFullSearchBar, setFullSearchBar] = useState(false)
    const handleWindowSizeChange = () => {
        if (window.innerWidth <= 991) {
            setIsMobile(true);
        }
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        handleWindowSizeChange();
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    const renderMenu = (menuId, index, menuIndex, label) => {
        if (label) {
            setLevel2Menu(label)
        }
        if (isMobile) {
            setIsShowLevel(menuIndex)
        }
        if (isMenuOpen && !isMobile) {
            setIsMenuOpen(false);
        } else {
            
            setIsMenuOpen(true);
            setMenuIndex(index);
            setMenuOptions(data[menuId]);
        }
    }
    const toggleMenu = (e) => {
        e.preventDefault();
        setMobileMenu(current => !current)
        setIsShowLevel(1)
    }
    const showLevel3 = (label) => {
        setLevel3Menu(label)
        setIsShowLevel(3);
    }
    const SearchBarToggle = () => {
        setSearchBar(true)
    }
    const changePage = (link) => {
        navigateTo(link)
        setMobileMenu(current => !current)
        setIsShowLevel(1)
    }
    // useOutsideHandler(menuRef, isMenuOpen, renderMenu);
    return <header className={mobileMenu ? "navbar showMenu" : "navbar"}>
        <div className="container">
            <div className="navigation-wrap">
                <div className="logo">
                    <a href="/">
                        <img src={logoPath} className="darkLogo" alt="logo" />
                        <img src={logoPath2} className="lightLogo" alt="logo" />
                    </a>
                </div>
                {isMobile && <><div className="mobile-search-btn-wrap">
                    <div className="mobile-search-btn"><button type="button" onClick={() => setFullSearchBar(true)}><Search /></button>
                    </div>
                    {isFullSearchBar && <div className='searchbar-wrapper'>
                        <form>
                            <div className="form-group">
                                <button className="search-btn-input" type="submit"><Search/></button>
                                <input type="text" placeholder='| Search Digialpha' className='form-control' />
                                <button type='button' className='clear-btn' onClick={() => setFullSearchBar(false)}><IoIosCloseCircle /></button>
                            </div>
                        </form>
                    </div>}
                    <button type="button" onClick={toggleMenu} className="mobileMenuToggle">
                        <span></span>
                    </button></div></>}
                <div className="nav-menu">
                    {isShowLevel === 1 && <ul>
                        {mainMenu.map((topLink, index) => {
                            return <li key={topLink.label}>
                                {
                                topLink.hasChildren ?
                                    <button className={isMenuOpen && menuIndex === index ? "active" : ""} type="button" onClick={() => renderMenu(topLink.menuKey, index, 2, topLink.label)} onMouseOver={() => renderMenu(topLink.menuKey, index, 2, topLink.label)}>{topLink.label}
                                        <BsChevronDown />
                                    </button>
                                    : <button onClick={()=>{
                                            // debugger;
                                            setMenuIndex(index)
                                            navigateTo(topLink.link)
                                        // }} className={menuIndex === index ? "active" : ""} href={topLink.link}>{topLink.label}</a>
                                    }} className={menuIndex === index ? "active" : ""} >{topLink.label}</button>
                                }
                            </li>
                        })}
                        <li className="search-btn">
                            {!isFullSearchBar && <button className='search-btn-main' onClick={() => setFullSearchBar(true)} ><Search /></button>}
                            {(!isMobile && isFullSearchBar) && <div className='searchbar-wrapper'>
                                <form>
                                    <div className='form-group'>
                                        <button className='search-btn-input' type='submit'><Search/></button>
                                        <input type="text" placeholder='| Search Digialpha' className='form-control' />
                                        <button type='button' className='clear-btn' onClick={() => setFullSearchBar(false)}><IoIosCloseCircle /></button>
                                    </div>
                                </form>
                            </div>}
                        </li>
                        <li className="orange-btn"><a href="/contact-us">CONTACT US</a>
                        </li>
                    </ul>}
                    {isMobile && isShowLevel === 2 && <><h3><button type="button" onClick={() => setIsShowLevel(1)}><BsChevronLeft /></button><span>{level2Menu}</span></h3><ul className="sub-menu">
                    {menuOptions.map(menu => {
                            return menu.links.map(link => {
                                return (<li>
                                    <button key={link.label} onClick={() => changePage(link.link)} className={""} type="button">
                                        {link.label}
                                    </button>
                                </li>)
                           })
                        })}
                        <li className="orange-btn"><a href="/contact-us">CONTACT US</a></li>
                    </ul>
                    </>}
                </div>
            </div>
        </div>
        {/* {!isMobile && isMenuOpen &&  */}
        {!isMobile && isMenuOpen && <div className="menu-options-wrapper" ref={menuRef} onMouseLeave={() => setIsMenuOpen(false)}>
            <h3 className='submenu-title'>
                <CustomLink to={menuOptions[0].titleLink} renderSvg={true}>
                    <span> {menuOptions[0].mainTitle}</span>
                </CustomLink>
            </h3>
            <div className='menu-options-wrap'>
                {menuOptions.map(menu => {
                    if (menu.label !== null) {
                        return (
                            <div className={`col ${menu.label === "" ? "empty-label" : ""}`} key={menu.label}>
                                {menu.links && (
                                    <ul>
                                        {menu.links.map(link => {
                                            return <li>
                                                <CustomLink to={link.link} renderSvg={false}>
                                                    {link.label}
                                                </CustomLink>
                                            </li>
                                        })}
                                    </ul>
                                )}

                            </div>
                        )
                    } else {
                        return (
                            <div className="menuBanner" key="banner">
                                <div>
                                    <div className="menuBannerImage">
                                        <img src={MenuImage} alt="icon" />
                                    </div>
                                    <p>{menu.para}</p>
                                </div>
                                <CustomLink to="#" renderSvg={true}>
                                    Learn More
                                </CustomLink>
                            </div>
                        )
                    }

                })}
            </div>
        </div>
        }
        {/* } */}
    </header>
}

export default Header;