import { careerConstants } from "../../Constants/career/careerConstanst";
import { blogConstants } from "../../Constants/career/careerConstanst";

const initialState = {
    blogResults: null,
    loading: false,
    blogErrors: null,
    careerDetailsResults : null
};

const careerReducer = (state = initialState, action) => {
    switch (action.type) {

        case careerConstants.CAREER_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case careerConstants.CAREER_DETAILS_SUCCESS:
        
            return {
                ...state,
                careerDetailsResults: action.response.data,
                loading: false,
            };
        case careerConstants.CAREER_DETAILS_FAILURE:
            return {
                ...state,
                loading: true,
                error: action.error,
                careerDetailsResults: null
            };

        default:
            return state;
    }
};

export default careerReducer;
