import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import GetInTouch from "../../components/GetInTouch";
import { ReactComponent as RightArrow } from "../../assets/images/icon/gray-arrow-right.svg";
import BannerContent from "../../components/BannerContent";
import CustomLink from "../../components/Common/CustomLink";
import Blog from "../../components/Blog";
import "../../assets/css/pages/blog-listing.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  blogActions,
  featuredBlogActions} from "../../Stores/Actions/blogAction";
import { isEmpty } from "lodash";

const BlogListingPage = () => {
  const [filter, setFilter] = useState([]);
  const dispatch = useDispatch();
  const [blogData, setBlogData] = useState({data: []})
  // blog data from the store
  const blogLocalData = useSelector((state) => state?.blogReducer?.blogResults);

  // featured blog data from the store       // As of now, we are not getting data from api response
  const featuredData = useSelector(
    (state) => state?.blogReducer?.featuredBlog?.data
  );
  useEffect(() => {
    
    if(blogLocalData){
      console.log(blogLocalData, "blogLocalData")
      setBlogData(blogLocalData);
    }else {
      setBlogData([]);
    }
  }, [blogLocalData])
  useEffect(() => {
    let payload = {
      filter_by: [],
      page: 1,
      posts_per_page: 3,
      order_by: "oldest",
    };
    dispatch(featuredBlogActions());
    dispatch(blogActions(payload));

  }, []);

  function searchBlog(sort, filter, page) {
    let payload = {
      filter_by: [],
      page: 1,
      posts_per_page: 3,
      order_by: sort ? sort?.value : "oldest",
    };

    if (filter) {
      let newFilter = new Set(filter); // removing duplicate filter id
      payload.filter_by = [...newFilter];
    }

    if (page) {
      payload.page = page;
    }

    dispatch(blogActions(payload));
  }

  console.log("featuredData?.[0]?.id",featuredData?.[0]?.id)
  return (
    <div className="wrapper">
      <Helmet>
        <title>Insights | Digialpha</title>
      </Helmet>
      <div className="page-blog-listing blog-listing-wrapper">
        {!isEmpty(featuredData) ? (
          <BannerContent
            BackgroundImages={featuredData?.[0]?.image}
            title={featuredData?.[0]?.category}
            subTitle={featuredData?.[0]?.title.length > 60 ? (featuredData?.[0]?.title.substring(0, 80) + (featuredData?.[0]?.title.length > 80 ? "..." : "")) : featuredData?.[0]?.title}
          >
            <CustomLink to={`/insights/${featuredData?.[0]?.category_slug}/${featuredData?.[0]?.slug}`} 
            renderSvg={false} 
            className="typeButton" 
            id={featuredData?.[0]?.id}
            >
              Read the blog <RightArrow className="right-arrow" />
            </CustomLink>
          </BannerContent>
        ) : (
          <div></div>
        )}

        <Blog
          blogData={blogData}
          title={"All Insights"}
          // showAll
          className="gray-section"
          showFilters={true}
          searchBlog={searchBlog}
          filter={filter}
          setFilter={setFilter}
        />

        {/* <Pagination /> */}
        <GetInTouch />
      </div>
    </div>
  );
};
export default BlogListingPage;
