import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CustomLink from "../components/Common/CustomLink";
import { ReactComponent as RightArrow } from "../assets/images/icon/gray-arrow-right.svg";
// import "../assets/css/menu.scss";
import "../assets/JS/services/index.js";
const Services = ({ data }) => {
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        const element = document.getElementById("seviceSectoin");
        let pos = element.offsetTop - (window.innerHeight / 2);

        window.addEventListener("scroll", () => {
            if (!scroll && window.scrollY > pos) {
                setScroll(true)
            }
        });
        return () => {
            window.removeEventListener('scroll', () => {
                setScroll(false)
            })
        }
    }, []);
    return (
        <div id="seviceSectoin" className={scroll ? "services-section animate" : "services-section"}>
            <div className="container">
                <div className="section-title">
                    <h2><span className="line" ><span className="lineIn" >Digialpha Services</span></span></h2>
                    <div className="content">
                        <p>
                            <span className="line" ><span className="lineIn" >Discover how Digialpha services and solutions can help transform your business.</span></span>
                        </p>
                    </div>
                </div>
                <ul className="services_menu">
                    {
                        data.map((service, index) => {

                            var opts = {};
                            if (service.thumbImage) {
                                opts["data-img"] = service.thumbImage;
                            }
                            if (service.dataColor) {
                                opts["data-color"] = service.dataColor;

                            }
                            if (service.dataTextColor) {
                                opts["data-text-color"] = service.dataTextColor;

                            }
                            if (service.dataId) {
                                opts["data-id"] = service.dataId;
                            }
                            if (index === 0 || index === 5) {
                                return <li key={index}>
                                    <a href={service?.href} className="clickable-anchor services_menu__item" {...opts}>
                                        <span className="line" ><span className="lineIn" >
                                            {service.title}
                                        </span></span>
                                        <sup><span className="line" ><span className="lineIn" >0{index + 1}</span></span></sup>
                                    </a>
                                    {index === 5 && <div className="explore-btn">
                                        <CustomLink to="/services" renderSvg={true}>
                                            <span>Explore all services </span>
                                        </CustomLink>
                                    </div>}
                                </li>
                            } else if (index === 1 || index === 3) {

                                return <li key={index}>
                                    <a href={service?.href} className="clickable-anchor services_menu__item" {...opts}>
                                        <span className="line" ><span className="lineIn" >
                                            {data[index].title}
                                        </span></span>
                                        <sup><span className="line" ><span className="lineIn" >0{index + 1}</span></span></sup>
                                    </a>
                                    <span className="slash-border"></span>
                                    <a href={data[index + 1]?.href} className="clickable-anchor services_menu__item"
                                        data-img={data[index + 1].thumbImage}
                                        data-color={data[index + 1].dataColor}
                                        data-text-color={data[index + 1].dataTextColor}
                                        data-id={data[index + 1].dataId}
                                    >
                                        <span className="line" ><span className="lineIn" >
                                            {data[index + 1].title}

                                        </span></span>
                                        <sup><span className="line" ><span className="lineIn" >0{index + 2}</span></span></sup>
                                    </a>
                                </li>
                            }


                        })
                    }

                </ul>
            </div>
        </div>
    );
};

export default Services;
