import { combineReducers } from "redux";
import commonReducer from "./commonReducer";
import currencyLanguageReducer from "./languageCurrency";
import blogReducer from "./blogReducer";
import careerReducer from "./career/careerReducer";
import caseStudyReducer from "./caseStudyReducer"

const reducers = (state, action) => {
  switch (action.type) {
    default: {
      const combinedReducer = combineReducers({
        caseStudyReducer,
        commonReducer,
        currencyLanguageReducer,
        blogReducer,
        careerReducer
      });
      return combinedReducer(state, action);
    }
  }
};
export default reducers;
