import React, { Children } from "react";
function CustomAccordian({
    className,
    children,
    title,
    openedAccordian,
    id,
    handleOpenClose,
}) {
    //debugger;
    return (
        <div className="card" key={title}>
            <div className={'card-title'}>
                <button
                    onClick={() => handleOpenClose(id)}
                    className={id === openedAccordian ? 'faq-btn open-btn' : 'faq-btn'}>
                    {title}
                </button>
            </div>
            <div className={id === openedAccordian ? `${className} open-accordion` : className}>
                <div className="card-content">
                    {children}
                </div>
            </div>
        </div>
    );
}

export default CustomAccordian;
