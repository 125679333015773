import { blogApiService } from "../../api/blogService";
import { blogConstants } from "../Constants";

export function blogActions(payload) {
  return (dispatch) => {
    dispatch(request(blogConstants.BLOG_REQUEST));
    blogApiService.blogService(payload).then(
      (response) => {
        dispatch(success(blogConstants.BLOG_SUCCESS, response));
      },
      (error) => {
        dispatch(failure(blogConstants.BLOG_FAILURE, error));
      }
    );
  };
}

export function featuredBlogActions() {
  return (dispatch) => {
    dispatch(request(blogConstants.FEATURED_BLOG_REQUEST));
    blogApiService.featuredBlogService().then(
      (response) => {
        dispatch(success(blogConstants.FEATURED_BLOG_SUCCESS, response));
      },
      (error) => {
        dispatch(failure(blogConstants.FEATURED_BLOG_FAILURE, error));
      }
    );
  };
}

//

export function customerBlogActions() {
  return (dispatch) => {
    dispatch(request(blogConstants.CUSTOMER_BLOG_REQUEST));
    blogApiService.customerBlogService().then(
      (response) => {
        dispatch(success(blogConstants.CUSTOMER_BLOG_SUCCESS, response));
      },
      (error) => {
        dispatch(failure(blogConstants.CUSTOMER_BLOG_FAILURE, error));
      }
    );
  };
}

export function resetBlogActions() {
  return (dispatch) => {
    dispatch(request(blogConstants.RESET_FEATURED_BLOG_REQUEST));
  };
}

export function blogDetailsActions(payload) {
  return (dispatch) => {
    dispatch(request(blogConstants.BLOG_DETAILS_REQUEST));
    blogApiService.blogDetailsService(payload).then(
      (response) => {
        dispatch(success(blogConstants.BLOG_DETAILS_SUCCESS, response));
      },
      (error) => {
        dispatch(failure(blogConstants.BLOG_DETAILS_FAILURE, error));
      }
    );
  };
}

function request(action, payload) {
  return { type: action, payload };
}

function success(action, response) {
  return { type: action, response };
}

function failure(action, error) {
  return { type: action, error };
}
