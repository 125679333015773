import React, { Component } from "react";

export class ContactCard extends Component {
  render() {
    return (
      <div className="card-wrapper">
        <div className="container">
          <div className="section-heading">
            <h3>Office Locations</h3>
            <h2>Meet us at..</h2>
          </div>
          <div className="card-section">
            <div className="card card-1 ">
              <div className="card-content">
                <h3>HQ - Pune, India</h3>
                <span>5th Floor, Unit No. 505, Baner, </span>
                {/* <span>Supreme Headquarters,</span> */}
                <span> Pune Bangalore Highway,</span>
                <span> Pune, MH, India 411045</span>
                {/* <a href="tel:+61 2 1223 1500">Phone: +61 2 1223 1500</a> */}
              </div>
            </div>

            <div className="card card-2 ">
              <div className="card-content">
                <h3>San Diego, USA</h3>
                <span>Unit no. 107, El Cortez,</span>
                <span> 702 Ash Street,</span>
                <span>San Diego, CA , USA 92101</span>
                {/* <a href="tel:+61 2 1223 1500">Phone: +61 2 1223 1500</a> */}
              </div>
            </div>
            <div className="card card-3 ">
              <div className="card-content">
                <h3>Singapore, Singapore</h3>
                <span>45 Lengkok Bahru, #03-229, </span>
                <span> Singapore 150045</span>
                {/* <a href="tel:+61 2 1223 1500">Phone: +61 2 1223 1500</a> */}
              </div>
            </div>
            <div className="card card-5 ">
              <div className="card-content">
                <h3>Dubai, UAE</h3>
                <span> Sharjah Media City ‑ Shams,</span>
                <span> Sharjah, United Arab Emirates</span>
                {/* <a href="tel:+61 2 1223 1500"> Phone: +61 2 1223 1500</a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactCard;
