import React, { useEffect, useState } from "react";
import BannerContent from "../../components/BannerContent";
import GetInTouch from "../../components/GetInTouch";
import ServiceCardNew from "../../components/ServiceCardNew";
import { VscClose } from "react-icons/vsc";

import {
    FacebookShareButton,
    InstapaperShareButton,
    LinkedinShareButton,
    TwitterShareButton
  } from "react-share";
import 'antd/dist/antd.css';
import "../../assets/css/menu.scss";
import { ReactComponent as Instagram } from "../../assets/images/icon/instagram.svg";
import { AiFillFacebook, AiFillLinkedin, AiOutlineTwitter } from "react-icons/ai";
import { ReactComponent as RightArrow } from "../../assets/images/icon/gray-arrow-right.svg";
import CustomLink from "../../components/Common/CustomLink";
import BannerImage2 from "../../assets/images/case-study/case-study-detail-banner1.png";

import { useDispatch, useSelector } from "react-redux";
import { caseStudyDetailsActions } from "../../Stores/Actions/caseStudyAction";
import { useParams } from "react-router-dom";

import "../../assets/css/pages/case-study.scss";
const CaseStudyDetail = () => {
    const [PopupContent, setOpenPopup] = useState(false);
    const [SuccessPopup, setSuccessPopup] = useState(false);
    const dispatch = useDispatch();
    const params = useParams();
  
    // get Case Study details data from store
    const caseStudyDetail = useSelector(
      (state) => state?.caseStudyReducer?.caseStudyDetails?.data
    );
    useEffect(() => {
      dispatch(caseStudyDetailsActions(params?.id));
    }, [dispatch, params]);
    return (
        <div className="wrapper">
            <div className="page-case-study page-case-study-detail">
                <BannerContent BackgroundImages={BannerImage2} title={caseStudyDetail?.category} subTitle={caseStudyDetail?.title}>
                    <div className="content-wrap">
                        <div className="learn-more-wrap">
                            <div className="client-logo" >
                                <img src={caseStudyDetail?.logo} alt="logo" />
                            </div>
                        </div>
                    </div>
                </BannerContent>
                <div className="banner-content-img-wrap">
                    <div className="container">
                        <div className="banner-content-img" style={{ backgroundImage: `url(${caseStudyDetail?.image})` }}></div>
                    </div>
                </div>
                <div className="case-study-cards-wrap">
                    <div className="container">
                        <div className="case-study-wrapper">
                            <div className="case-study-content-wrap">
                                <div className="case-study-content">
                                    <div className="row">
                                        <div className="col left-content">
                                            <div className="content">
                                                <p>{caseStudyDetail?.short_description}</p>
                                                <div className="content-link">
                                                    <a href="#">Read the case study to know more!</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col right-content">
                                            <div className="content-wrap">
                                                <span onClick={() => setOpenPopup(true)}>
                                                    <CustomLink to="" renderSvg={false} className="typeButton">
                                                        Download Case Study<RightArrow className="right-arrow" />
                                                    </CustomLink>
                                                </span>
                                                <div className="tag-info">
                                                    <h4 className="heading-title">
                                                        Tags:
                                                    </h4>
                                                    <p>{caseStudyDetail?.case_study_tag || "-"}</p>
                                                </div>
                                                <div className="social-info">
                                                    <h4 className="heading-title">
                                                        Share:
                                                    </h4>
                                                    <ul>
                                                        <li>
                                                            <FacebookShareButton 
                                                                size={32} 
                                                                url={window.location.href}
                                                                title={caseStudyDetail?.title}
                                                            >
                                                                <AiFillFacebook />
                                                            </FacebookShareButton>
                                                        </li>
                                                        <li>
                                                            <InstapaperShareButton 
                                                                url={window.location.href}
                                                                size={32}
                                                                title={caseStudyDetail?.title}
                                                                >
                                                                <Instagram />
                                                            </InstapaperShareButton>
                                                        </li>
                                                        <li>
                                                            <LinkedinShareButton 
                                                                size={32} 
                                                                url={window.location.href}
                                                                title={caseStudyDetail?.title}
                                                            >
                                                                <AiFillLinkedin />
                                                            </LinkedinShareButton>
                                                        </li>
                                                        <li>
                                                            <TwitterShareButton 
                                                                size={32} 
                                                                url={window.location.href}
                                                                title={caseStudyDetail?.title}
                                                                via={"digialpha"}
                                                            >
                                                                <AiOutlineTwitter />
                                                            </TwitterShareButton>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                {caseStudyDetail?.related_post?.length > 0 && <><h4 className="heading-title">Also read:</h4><ServiceCardNew data={caseStudyDetail?.related_post} /></>}
                            </div>
                        </div>
                    </div>
                </div>
                <GetInTouch />
            </div >

            {/* Popup start */}
            {PopupContent && <div className="popup contact-form-popup">
                <div className="popup-content">
                    <div className="popup-title">
                        <button type="button" className="btn close" onClick={() => setOpenPopup(false)}>
                            <VscClose />
                        </button>
                    </div>
                    <div className="content-box">
                        <h2>Please fill out the form below</h2>
                        <form>
                            <div className="form-group">
                                <div className="form-group-row row no-gutters">
                                    <div className="col number-col">
                                        <h6>01</h6>
                                    </div>
                                    <div className="col input-box">
                                        <h5>What’s your name?</h5>
                                        <input type="text" placeholder="John Doe *" className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-group-row row no-gutters">
                                    <div className="col number-col">
                                        <h6>02</h6>
                                    </div>
                                    <div className="col input-box">
                                        <h5>What's your work email?</h5>
                                        <input type="text" placeholder="john@doe.com *" className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-group-row row no-gutters">
                                    <div className="col number-col">
                                        <h6>03</h6>
                                    </div>
                                    <div className="col input-box">
                                        <h5>From which country you are?</h5>
                                        <input type="text" placeholder="Select Country*" className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group checkbox-group">
                                <div className="form-group-row row no-gutters">
                                    <div className="custom-checkbox">
                                        <input type="checkbox" id="checkbox" />
                                        <span className="checkmark"></span>
                                        <label htmlFor="checkbox">I consent to the above details being used to keep me informed about Digialpha products, services and events.
                                        </label>
                                    </div>
                                    <p>
                                        <small>This form collects your name and email so that we can add you to our newsletter list for product updates and useful marketing tips and tricks. You can unsubscribe at any time. Check out our privacy policy and cookie policy</small>
                                    </p>
                                </div>
                            </div>
                            {/* <button type="submit" className="submit-btn">Submit<RightArrow className="right-arrow" /></button> */}
                            <button type="button" onClick={() => setSuccessPopup(true)} className="submit-btn">Submit<RightArrow className="right-arrow" /></button>
                        </form>
                    </div>
                </div>
            </div>}
            {SuccessPopup && <div className="popup form-success-popup">
                <div className="popup-content">
                    <div className="popup-title">
                        <button type="button" className="btn close" onClick={() => setSuccessPopup(false)}>
                            <VscClose />
                        </button>
                    </div>
                    <div className="content-box">
                        <h2>Thank you
                            <span>Sachin, for your interest!</span></h2>
                            <h3> You can download the asset by clicking the link below</h3>
                            <button type="button" className="btn-orange">Download<RightArrow className="right-arrow" /></button>
                    </div>
                </div>
            </div>}
        </div >
    );
}

export default CaseStudyDetail;
