import http from "./http";

function caseStudyService(payload) {
  return http
    .post(
      `${process.env.REACT_APP_BASE_URL}/wp-json/blogs/v1/case-studies`,
      payload
    )
    .then((response) => response);
}

function featuredCaseStudyService() {
  return http
    .get(
      `${process.env.REACT_APP_BASE_URL}/wp-json/case-study/v1/featured`
    )
    .then((response) => response);
}

function caseStudyDetailsService(id) {
  return http
    .get(`${process.env.REACT_APP_BASE_URL}/wp-json/case-study/v1/detail/${id}`)
    .then((response) => response);
}

export const caseStudyApiService = {
  caseStudyService,
  featuredCaseStudyService,
  caseStudyDetailsService,
};
