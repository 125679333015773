import React, { useEffect, useRef, useState } from 'react';
import { gsap } from "gsap";
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from "react-redux";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import debounce from 'lodash.debounce'
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import Blog from "../../components/Blog";
import WhyBetaCraft from "../../components/Common/WhyBetaCraft";
import CustomLink from "../../components/Common/CustomLink";
import GetInTouch from "../../components/GetInTouch";
import { ReactComponent as RightArrow } from "../../assets/images/icon/right-arrow.svg"
import companyLogo1 from "../../assets/images/logo/ccr.png"
import companyLogo2 from "../../assets/images/logo/mo.png"
import companyLogo3 from "../../assets/images/logo/vtravel.png"
import companyLogo4 from "../../assets/images/logo/Msx.png"
import rightSizeIcon from "../../assets/images/icon/right-size.svg"
import humanIcon from "../../assets/images/icon/human.svg"
import recordGraphic from "../../assets/images/icon/record-graph.svg"
import regionalIcon from "../../assets/images/icon/regional.svg"
import windowsIcon from "../../assets/images/marque-img/windows.png"
import imbIcon from "../../assets/images/marque-img/ibm.png"
import awsIcon from "../../assets/images/marque-img/aws.png"
import salesForceIcon from "../../assets/images/marque-img/salesforce.png"
import redHatIcon from "../../assets/images/marque-img/red-hat.png"
import offertinImg1 from "../../assets/images/Data-Analytics-offering/Data-engg.jpg"
import offertinImg2 from "../../assets/images/Data-Analytics-offering/Data-visualisation.jpg"
import offertinImg3 from "../../assets/images/Data-Analytics-offering/Data-Science-AI.jpg"

import { ReactComponent as VideoIcon } from "../../assets/images/icon/video-btn-icon.svg";
import { blogData } from "../../pages/data";
import Slider from "react-slick";
import { blogActions, featuredBlogActions } from "../../Stores/Actions/blogAction";
import "../../assets/css/pages/home.scss";

import { Anchor } from 'antd';
const { Link } = Anchor;

const DataAndAnalyticsPage = () => {
    const dispatch = useDispatch()
    const featuredData = useSelector(
        (state) => state?.blogReducer?.featuredBlog?.data
    );
    const blogData = useSelector((state) => state?.blogReducer?.blogResults);
    const sliderRef = useRef();
    const OfferingsliderRef = useRef();
    const circleSliderRef = useRef();
    const circleSliderContentRef = useRef();
    // const tabSliderRef = useRef();

    const navigatiorClickHistoryRef = useRef(false);
    const [tabSliderRef, setTabSliderRef] = useState();
    const [tabThumbRef, setTabThumbRef] = useState();
    const [indexInit, setindexInit] = useState(0);
    const [scrollOn, setScrollOn] = useState(false);
    const [scrollDown, setScrollDown] = useState(false);
    const [scrollUp, setScrollUp] = useState(false);
    const [images, setImages] = useState([companyLogo1, companyLogo2, companyLogo3, companyLogo4]);
    const [isShown, setIsShown] = useState(false);
    const [stopBodyScroll, setStopBodyScroll] = useState(false);

    const [scroll, setScroll] = useState(false);
    const [scrollSuccessStory, setScrollSuccessStory] = useState(false);
    const [scrollContainerAnimate, setScrollContainerAnimate] = useState(false);
    const [scrollContainerAnimate2, setScrollContainerAnimate2] = useState(false);
    const [scrolDifferentiatorsSection, setScrolDifferentiatorsSection] = useState(false);

    const [targetOffset, setTargetOffset] = useState(undefined);
    useEffect(() => {
        setTargetOffset(window.innerHeight / 2);
    }, []);

    useEffect(() => {
        const element = document.getElementById("overviewSection");
        let pos = element.offsetTop - (window.innerHeight / 2);
        window.addEventListener("scroll", () => {
            if (!scroll && window.scrollY > pos) {
                setScroll(true)
            }
        });
        let payload = {
            // filter_by: [11],
            page: 1,
            posts_per_page: 3,
            order_by: "a-z",
        };

        dispatch(featuredBlogActions());
        dispatch(blogActions(payload));
        return () => {
            window.removeEventListener('scroll', () => {
                setScroll(false)
            })
        }
    }, []);
    useEffect(() => {
        const element2 = document.getElementById("successStory");
        let pos2 = element2.offsetTop - (window.innerHeight * 1.268);
        window.addEventListener("scroll", () => {
            if (window.scrollY > pos2) {
                setScrollSuccessStory(true);
            } else {
                setScrollSuccessStory(false)
            }
        });
    }, []);
    useEffect(() => {
        const element3 = document.getElementById("containerAnimate");
        let pos3 = element3.offsetTop - (window.innerHeight / 4);
        window.addEventListener("scroll", () => {
            if (window.scrollY > pos3) {
                setScrollContainerAnimate(true)
            } else {
                setScrollContainerAnimate(false)
            }
        });
    }, []);

    useEffect(() => {

        let pos5 = document.getElementById("containerAnimate").offsetTop + 71;
        let btmPos = document.getElementById("successStory").offsetTop + (window.innerHeight);
        window.addEventListener("scroll", () => {
            if (window.scrollY > pos5) {
                setScrollContainerAnimate2(true)
            } else {
                setScrollContainerAnimate2(false)
            }
        });
    }, []);

    useEffect(() => {
        let pos3 = document.getElementById("differentiatorsSection").offsetTop - (window.innerHeight / 2);
        window.addEventListener("scroll", () => {
            if (!scroll && window.scrollY > pos3) {
                setScrolDifferentiatorsSection(true);
            }
        });
        return () => {
            window.removeEventListener('scroll', () => {
                setScrolDifferentiatorsSection(false)
            })
        }
    }, []);
    gsap.registerPlugin(ScrollTrigger);
    const ref = useRef(null);
    const Offeringslider = {
        autoplay: false,
        dots: false,
        fade: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        vertical: true,
        verticalSwiping: true,
        swipeToSlide: true,
        autoplaySpeed: "0",
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    autoplay: true,
                    //settings: "unslick"
                    // vertical: false,
                    // verticalSwiping: false,
                    // swipeToSlide: false,
                    // slidesToShow: 2,
                    // variableWidth: true,
                    //centerMode: true,
                }
            }
        ]
    };
    const onWheelSlider = debounce((e, ref) => {
        //console.log(e);

        if (!OfferingsliderRef?.current) return;

        if (e.deltaY > 0) {
            OfferingsliderRef.current.slickNext();
            //console.log(e);
        } else if (e.deltaY < 0) {
            OfferingsliderRef.current.slickPrev();
            //console.log(ref);
        }
    }, 20);
    // function pageFadeIn() {
    //     const tl = gsap.timeline();
    //     tl.from('body', { opacity: 0, ease: 'linear', duration: 0.8 });
    //     return tl;
    // }

    // function heroAnimation() {
    //     const tl = gsap.timeline(
    //         {
    //             defaults: { opacity: 0, ease: 'ease-in', duration: 0.5 },
    //         }

    //     );
    //     tl.from('.tagline', { x: -20 })
    //     tl.from('.banner-para', { y: -20 })
    //     tl.from('.white-wrapper', { x: 150 });
    //     return tl;
    // }


    //useEffect(() => {
    // gsap.to("#circleSliderWrap", { 
    //     scrollTrigger: {
    //         trigger: "#circleSliderWrap",
    //         toggleActions: "restart none none none",
    //         pin: true,
    //         start: "top",
    //         onEnter: () => {setStopBodyScroll(true);},
    //         onLeave: () => {setStopBodyScroll(false);}
    //     },
    //     y: 0 
    // });
    //}, [stopBodyScroll]);

    // useEffect(() => {

    //const loadTl = gsap.timeline({ defaults: { opacity: 0, ease: 'ease-in', duration: 1 } });
    //loadTl.add(pageFadeIn());
    // loadTl.add(headerLoadAnimation(), '<1');
    //loadTl.add(heroAnimation(), '<1.5');

    //}, []);
    // useEffect(() => {
    //     const element = ref.current;
    //     gsap.from(
    //         element.querySelector("#gsap-logo"),
    //         {
    //             y: -40,
    //             duration: 2,
    //             ease: "none",
    //             scrollTrigger: {
    //                 trigger: element.querySelector(".text-wrap"),
    //                 start: "bottom center",
    //                 end: "bottom top",
    //                 scrub: true
    //             }
    //         }
    //     );
    // }, []);

    // Offering Animation effects 
    function animateFrom(elem, direction, index) {
        //console.log(index, "index")
        direction = direction || 1;
        var x = 0,
            y = direction * 100;
        if (elem.classList.contains("gs_reveal_fromLeft")) {
            x = -100;
            y = 0;
        } else if (elem.classList.contains("gs_reveal_fromRight")) {
            x = 100;
            y = 0;
        }
        // elem.style.transform = "translate(" + x + "px, " + y + "px)";
        // elem.style.backgroundPosition = x + "px, " + y + "px";
        elem.style.opacity = "0";
        gsap.fromTo(elem, { x: x, y: y, autoAlpha: 0 }, {
            duration: 1.25,
            x: 0,
            y: 0,
            autoAlpha: 1,
            ease: "expo",
            overwrite: "auto"
        });

    }

    function hide(elem) {
        gsap.set(elem, { autoAlpha: 0 });
    }
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        gsap.utils.toArray(".gs_reveal").forEach(function (elem, index) {
            hide(elem); // assure that the element is hidden when scrolled into view
            ScrollTrigger.create({
                trigger: elem,
                onEnter: function () { animateFrom(elem, null, index) },
                onEnterBack: function () { animateFrom(elem, -1, index) },
                onLeave: function () { hide(elem) } // assure that the element is hidden when scrolled into view
            });
        });
        gsap.utils
            .toArray(".card img")
            .forEach((section, i) => {
                const heightDiff =
                    section.offsetHeight - section.parentElement.offsetHeight;

                gsap.fromTo(
                    section,
                    {
                        y: section.parentElement.offsetHeight - 300
                    },
                    {
                        y: -150,
                        ease: "none",
                        scrollTrigger: {
                            trigger: section.parentElement,
                            scrub: true
                        }
                    }
                );
            });
    }, [])

    const nextSlide = () => {
        sliderRef.current.slickNext();
    }
    const prevSlide = () => {
        sliderRef.current.slickPrev();
    }
    const scrollDownHandler = () => {
        if (stopBodyScroll) {
            setScrollDown(true);
            setTimeout(() => {
                setScrollDown(false);
            }, 1000);
            circleSliderRef.current.slickPrev();
            circleSliderContentRef.current.slickPrev();
        }
    }

    const scrollUpHandler = () => {
        if (stopBodyScroll) {
            setScrollUp(true);
            setTimeout(() => {
                setScrollUp(false);
            }, 1000);
            circleSliderRef.current.slickNext();
            circleSliderContentRef.current.slickNext();
        }

    }

    let tabSliderSettings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        center: true,
        autoplay: true,
        autoplaySpeed: 5000
    }

    useEffect(() => {
        // const panelsContainer = document.querySelector(".animateOuter");
        // const screenHeight = window.outerHeight;

        // let tl = gsap.timeline({
        //     scrollTrigger: {
        //         trigger: ".animateOuter",
        //         toggleActions: "restart complete reverse resume",
        //         start: "top top",
        //         markers: false,
        //         scrub: 1,
        //         pin: true,
        //         onLeave: () => console.log("END")
        //         // end: () => "+=" + (panelsContainer.offsetHeight - window.innerHeight + 70),
        //     }
        // });

        // tl.from(".p-one", {
        //     autoAlpha: 1,
        //     y: 0,
        //     duration: 2,
        // });
        // tl.from(".p-one", {
        //     autoAlpha: 1,
        //     y: 0,
        // });
        // tl.to(".p-one", {
        //     autoAlpha: 0,
        //     y: -screenHeight,
        //     delay: .5,
        //     duration: 2,
        // });
        // tl.from(".p-two", {
        //     autoAlpha: 1,
        //     y: screenHeight,
        //     duration: 2,
        // });
        // tl.from(".p-two", {
        //     autoAlpha: 1,
        // });
        // tl.to(".p-two", {
        //     autoAlpha: 1,
        //     y: -screenHeight,
        // });
        // tl.from(".p-three", {
        //     autoAlpha: 1,
        //     y: screenHeight,
        //     duration: 2,
        // });
        // tl.from(".p-three", {
        //     autoAlpha: 1,
        //     // y: screenHeight,
        // });
        // tl.to(".p-three", {
        //     autoAlpha: 1,
        //     y: -screenHeight,
        //     // delay: 1,
        // });
        // tl.from(".p-four", {
        //     autoAlpha: 1,
        //     y: screenHeight,
        //     duration: 2,
        // });
        // tl.from(".p-four", {
        //     autoAlpha: 1,
        //     // y: screenHeight,
        // });
        // tl.to(".p-four", {
        //     autoAlpha: 1,
        //     y: 0,
        //     // delay: 1,
        // });


        // let tl2 = gsap.timeline({
        //     scrollTrigger: {
        //         trigger: ".animateOuter",
        //         toggleActions: "restart complete reverse resume",
        //         start: "top top",
        //         markers: false,
        //         scrub: true,
        //         pin: true,
        //         duration: 2
        //     }
        // });
        // tl2.to(".img-one", {
        //     autoAlpha: 1,
        //     x: 0
        // });
        // tl2.to(".img-one", {
        //     autoAlpha: 1
        // });
        // tl2.to(".img-one", {
        //     autoAlpha: 0, duration: 1,
        //     x: 10
        // });
        // tl2.from(".img-two", {
        //     autoAlpha: 0,
        //     x: 10
        // });
        // tl2.to(".img-two", {
        //     autoAlpha: 1,
        // });

        // tl2.to(".img-two", {
        //     autoAlpha: 0,
        //     x: 10
        // });

        // tl2.from(".img-three", {
        //     autoAlpha: 0,
        //     x: 10
        // });
        // tl2.to(".img-three", {
        //     autoAlpha: 1,
        // });

        // tl2.to(".img-three", {
        //     autoAlpha: 0,
        //     x: 10
        // });

        // tl2.from(".img-four", {
        //     autoAlpha: 0,
        //     x: 10
        // });
        // tl2.to(".img-four", {
        //     autoAlpha: 1,
        // });

        // tl.set(
        //     ".img-three",
        //     {
        //         autoAlpha: 0
        //     },
        //     "<"
        // );
        // tl2.to(".img-one", {
        //     autoAlpha: 1,
        //     scale: 1,
        //     duration: 1
        // });
        // tl2.to(".img-one", {
        //     autoAlpha: 0,
        //     scale: 0.5,
        // });
        // tl2.to(".img-one", {
        //     autoAlpha: 0,duration: 1,
        //     x: 10
        // });
        // tl2.from(".img-two", {
        //     autoAlpha: 0,
        //     x: 0
        // });
        // tl2.to(".img-two", {
        //     autoAlpha: 1,
        // });

        // tl2.to(".img-two", {
        //     autoAlpha: 0,
        //     x: 10
        // });
        // tl2.from(".img-three", {
        //     autoAlpha: 0,duration: 1,
        //     x: 10
        // });
        // tl2.to(".img-three", {
        //     autoAlpha: 0,duration: 1,
        //     x: 10
        // });
        // tl2.from(".img-four", {
        //     autoAlpha: 0,duration: 1,
        //     x: 10
        // });
        // tl2.to(".img-four", {
        //     autoAlpha: 1
        // });
    }, [])
    return <div className='wrapper'>
        <Helmet>
            <title>Data and Analytics | Digialpha</title>
        </Helmet>
        <div className='service-wrapper home-wrapper'>
            <div className="home-page" ref={ref}>
                <div className="section-1 first">
                    <div className="product-banner">
                        <div className="banner-section Data-analytics-bg">
                            <div className="container banner-container">
                                <div className="left-content first-paragraph">
                                    <h5 className="tagline"> Data and Analytics</h5>
                                    <h1 className="tagline">Advanced Insights for Critical Decision-Making</h1>
                                    <p className="banner-para">Data and Analytics Services and solutions help build the data foundation to the analytics layer unleashing the power of data in an organisation.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <CustomLink to="#" className="white-wrapper" renderSvg={false}>
                        <h5>Featured Blog </h5>
                        <h4>The Step by Step Flow, Challenges and solutions<span> in the journey to Data Analytics{" "}<RightArrow /></span>
                        </h4>
                    </CustomLink>
                </div>
                <div className={scroll ? "overview-section pd-top animate" : "overview-section pd-top"} id="overviewSection">
                    <div className="container text-wrap">
                        <h5>Overview</h5>
                        <div className="overview-para-1">
                            <p>In today’s world is a Data economy world and Data of an organisation is an asset for them if meaningful information and forecasts/ predictions are built. Therefore organisations need to understand the current state of their readiness, the data availability, the meaningful information creation roadmap, the selection of right analytics and AI tools to process the data etc.</p>
                        </div>
                        <div className="overview-para-2">
                            <p> We offer an entire set of data and analytics services to unlock the potential of data that already exists and benefit the organisation exponentially with a clear roadmap to being a data centric org improving the organisation performance exponentially.</p>
                        </div>
                        <div className="float-clear"></div>
                    </div>
                </div>
                <div className={scrollSuccessStory ? "animateOuter removeFixedPosition" : "animateOuter"} id="animateOuter">
                    <div className={scrollContainerAnimate2 ? "scrollAnimate offering-section scrollBarSticky" : "scrollAnimate offering-section"}>
                        <div className={scrollContainerAnimate ? "containerAnimate showTabs" : "containerAnimate"} id="containerAnimate">
                            <div className='scollTabs'>
                                <Anchor affix={false} targetOffset={targetOffset} offsetTop={100} >
                                    <Link href="#slide1" title="slide1" />
                                    <Link href="#slide2" title="slide2" />
                                    <Link href="#slide3" title="slide3" />
                                </Anchor>
                            </div>


                            <div className="text offering-content ipsSpacer_bottom_double ipsGrid ipsGrid_collapsePhone" onWheel={onWheelSlider}>
                                <Anchor targetOffset={targetOffset} offsetTop={100} >
                                    <Link href="#slide1" title={<img src={offertinImg1} alt="offertin img" />} />
                                    <Link href="#slide2" title={<img src={offertinImg2} alt="offertin img" />} />
                                    <Link href="#slide3" title={<img src={offertinImg3} alt="offertin img" />} />
                                </Anchor>
                                {/* <Slider ref={OfferingsliderRef} {...Offeringslider}> */}
                                <div id="slide1" className='slider-item '>
                                    <div className="p-one row ">
                                        <div className="content-1 col">
                                            <h5>Our offerings </h5>
                                            <h1>Data Engineering</h1>
                                            <p>
                                                <span>Data Engineering is the fundamental / or foundational </span>
                                                <span>set of tasks which helps Organisations to build systems</span>
                                                <span>that collect, manage, and convert raw data into usable </span>
                                                <span>information for data scientists and business analysts</span>
                                                <span> to interpret. The eventual goal is to make data accessible </span>
                                                <span>so that organizations can use it to evaluate and optimise </span>
                                                <span>their performance. We provide a team of data experts across </span>
                                                <span>various capabilities to perform the plumbing and piping work</span>
                                                <span>in order achieve the data goals.</span>
                                            </p>
                                            <div className="offering-li">
                                                <ul>
                                                    <li><span className="check-icon"></span>
                                                        <h4>Data Consulting</h4>
                                                    </li>
                                                    <li><span className="check-icon"></span>
                                                        <h4>Cloud-Native Enterprise Data</h4>
                                                    </li>
                                                    <li><span className="check-icon"></span>
                                                        <h4>Data Governance</h4>
                                                    </li>
                                                </ul>
                                                <ul>
                                                    <li><span className="check-icon"></span>
                                                        <h4>Data operations and management</h4>
                                                    </li>
                                                </ul>
                                                <div className="float-clear"></div>
                                            </div>

                                            {/* <div className="progress-bar"><CustomLink to="#" renderSvg={true}>
                                                    Learn More
                                                </CustomLink></div> */}

                                            {/* <div className="sub-title">
                                                    <h1><span>Product and</span>
                                                        <span>Platform Engineering</span>
                                                    </h1>
                                                </div> */}
                                        </div>
                                        <div className="laptop offering-img col">
                                            <div className='img-one '><div className='img-1 '>
                                                <img src={offertinImg1} alt="offertin img" /></div></div>
                                        </div>
                                    </div>
                                </div>
                                <div id="slide2" className='slider-item '>
                                    <div className="p-two row ">
                                        <div className="content-1 col">
                                            <h5>Our offerings </h5>
                                            <h1>Data Visualisation</h1>
                                            <p>
                                                <span>Data Visualisation is very crucial for the organisations</span>
                                                <span>which involves selection of the right set of tools and  also</span>
                                                <span> selecting formats/templates such as charts, pictures,diagrams</span>
                                                <span>in which the data would be meaningfully represented  so that</span>
                                                <span>effective communication happens and it helps to make  decisions.</span>
                                                <span>We offer advisory services outside the implementation of</span>
                                                <span>the tools / templates to drive the Visualisation.</span>
                                            </p>
                                            <div className="offering-li">
                                                <ul>
                                                    <li><span className="check-icon"></span>
                                                        <h4>Visualization and reporting</h4>
                                                    </li>
                                                    <li><span className="check-icon"></span>
                                                        <h4>Conversational BI</h4>
                                                    </li>

                                                </ul>

                                                <div className="float-clear"></div>
                                            </div>
                                            {/* <div className="progress-bar"><CustomLink to="#" renderSvg={true}>
                                                    Learn More
                                                </CustomLink></div> */}
                                            {/* <div className="sub-title">
                                                    <h1><span>Product and</span>
                                                        <span>Platform Engineering</span>
                                                    </h1>
                                                </div> */}
                                        </div>
                                        <div className="laptop offering-img col">
                                            <div className='img-two '><div className='img-1'>
                                                <img src={offertinImg2} alt="offertin img" /></div></div>
                                        </div>
                                    </div>
                                </div>
                                <div id="slide3" className='slider-item '>
                                    <div className="p-three row ">
                                        <div className="content-1 col">
                                            <h5>Our offerings </h5>
                                            <h1>Data Science and <span>Applied AI </span></h1>
                                            <p>
                                                <span>It is important to understand the entire data operations </span>
                                                <span>from Data Engineering, Data Visualisation, Pre-processing,</span>
                                                <span>Data Quality and finally Prediction which is part of Data Science.</span>
                                                <span> We offer advisory and implementation services with </span>
                                                <span>Applied AI to implement algorithms for Predictions.</span>
                                            </p>
                                            <div className="offering-li">
                                                <ul>
                                                    <li><span className="check-icon"></span>
                                                        <h4>Decision management</h4>
                                                    </li>
                                                    <li><span className="check-icon"></span>
                                                        <h4>Conversational systems and document processing</h4>
                                                    </li>
                                                    <li><span className="check-icon"></span>
                                                        <h4>3D models, images and video processing</h4>
                                                    </li>
                                                </ul>
                                                <ul>
                                                    <li><span className="check-icon"></span>
                                                        <h4>ML Ops</h4>
                                                    </li>
                                                    <li><span className="check-icon"></span>
                                                        <h4>AI consulting & governance</h4>
                                                    </li>
                                                    <li><span className="check-icon"></span>
                                                        <h4>Blockchain</h4>
                                                    </li>
                                                </ul>
                                                <div className="float-clear"></div>
                                            </div>
                                            {/* <div className="progress-bar"><CustomLink to="#" renderSvg={true}>
                                                    Learn More
                                                </CustomLink></div> */}
                                            {/* <div className="sub-title">
                                                    <h1><span>Product and</span>
                                                        <span>Platform Engineering</span>
                                                    </h1>
                                                </div> */}
                                        </div>
                                        <div className="laptop offering-img col">
                                            <div className='img-three '><div className='img-1'>
                                                <img src={offertinImg3} alt="offertin img" /></div></div>
                                        </div>
                                    </div>
                                </div>
                                {/* </Slider> */}
                                {/* <div className="p-two ipsGrid_span5 gs_reveal">
                                    <div className="content-1 gs_reveal">
                                        <h5>Our offerings </h5>
                                        <h1><span>Product and </span><span>Platform Strategy</span></h1>
                                        <p><span>Using modern day design thinking approach and</span>
                                            <span>frameworks,
                                                integrated with devops toolchain and with a </span><span>wide variety
                                                    of technology choices,
                                                    Product Engineering </span><span>is being taken to the next level of
                                                        evolution delivering Digital</span><span>products with great
                                                            transparency.</span>
                                        </p>
                                        <div className="offering-li gs_reveal">
                                            <ul>
                                                <li><span className="check-icon"></span>
                                                    <h4>Design Thinking</h4>
                                                </li>
                                                <li><span className="check-icon"></span>
                                                    <h4>Technology Consulting</h4>
                                                </li>
                                                <li><span className="check-icon"></span>
                                                    <h4>Digital Innovation Labs</h4>
                                                </li>
                                            </ul>
                                            <ul>
                                                <li><span className="check-icon"></span>
                                                    <h4>Agile / Devops / DevSecOps Transformation</h4>
                                                </li>
                                            </ul>
                                            <div className="float-clear"></div>
                                        </div>
                                        <div className="progress-bar gs_reveal"><CustomLink to="#" renderSvg={true}>
                                            Learn More
                                        </CustomLink></div>
                                        <div className="sub-title gs_reveal">
                                            <h1><span>Product and</span>
                                                <span>Platform Engineering</span>
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-three ipsGrid_span5 gs_reveal">
                                    <div className="content-1 gs_reveal">
                                        <h5>Our offerings </h5>
                                        <h1><span>Product and </span><span>Platform Strategy</span></h1>
                                        <p><span>Using modern day design thinking approach and</span>
                                            <span>frameworks,
                                                integrated with devops toolchain and with a </span><span>wide variety
                                                    of technology choices,
                                                    Product Engineering </span><span>is being taken to the next level of
                                                        evolution delivering Digital</span><span>products with great
                                                            transparency.</span>
                                        </p>
                                        <div className="offering-li gs_reveal">
                                            <ul>
                                                <li><span className="check-icon"></span>
                                                    <h4>Design Thinking</h4>
                                                </li>
                                                <li><span className="check-icon"></span>
                                                    <h4>Technology Consulting</h4>
                                                </li>
                                                <li><span className="check-icon"></span>
                                                    <h4>Digital Innovation Labs</h4>
                                                </li>
                                            </ul>
                                            <ul>
                                                <li><span className="check-icon"></span>
                                                    <h4>Agile / Devops / DevSecOps Transformation</h4>
                                                </li>
                                            </ul>
                                            <div className="float-clear"></div>
                                        </div>
                                        <div className="progress-bar gs_reveal"><CustomLink to="#" renderSvg={true}>
                                            Learn More
                                        </CustomLink></div>
                                        <div className="sub-title gs_reveal">
                                            <h1><span>Product and</span>
                                                <span>Platform Engineering</span>
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-four ipsGrid_span5 gs_reveal" >
                                    <div className="content-1 gs_reveal">
                                        <h5>Our offerings </h5>
                                        <h1><span>Product and </span><span>Platform Strategy</span></h1>
                                        <p><span>Using modern day design thinking approach and</span>
                                            <span>frameworks,
                                                integrated with devops toolchain and with a </span><span>wide variety
                                                    of technology choices,
                                                    Product Engineering </span><span>is being taken to the next level of
                                                        evolution delivering Digital</span><span>products with great
                                                            transparency.</span>
                                        </p>
                                        <div className="offering-li gs_reveal">
                                            <ul>
                                                <li><span className="check-icon"></span>
                                                    <h4>Design Thinking</h4>
                                                </li>
                                                <li><span className="check-icon"></span>
                                                    <h4>Technology Consulting</h4>
                                                </li>
                                                <li><span className="check-icon"></span>
                                                    <h4>Digital Innovation Labs</h4>
                                                </li>
                                            </ul>
                                            <ul>
                                                <li><span className="check-icon"></span>
                                                    <h4>Agile / Devops / DevSecOps Transformation</h4>
                                                </li>
                                            </ul>
                                            <div className="float-clear"></div>
                                        </div>
                                        <div className="progress-bar gs_reveal"><CustomLink to="#" renderSvg={true}>
                                            Learn More
                                        </CustomLink></div>
                                    </div>
                                </div> */}
                            </div>
                            {/* <div className="laptop offering-img">
                                <div className='img-one '><div className='img-1 gs_reveal'></div></div>
                                <div className='img-two'><div className='img-1 gs_reveal'></div></div>
                                <div className='img-three'><div className='img-1 gs_reveal'></div></div>
                                <div className='img-four'><div className='img-1 gs_reveal'></div></div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div id='successStory' className="success-story-section">
                    <div className="container pd-top">
                        <h5>Client Success Stories </h5>
                        <h1><span>Our Work Drives</span><span>Innovation Forward</span></h1>
                    </div>
                    <div className="tab-menu" id="tabs">
                        <div className='container' >
                            <Slider
                                asNavFor={tabThumbRef}
                                ref={(slider2) => setTabThumbRef(slider2)}
                                beforeChange={(e) => setindexInit(e + 1)}
                                arrows={true}
                                slidesToShow={4}
                                focusOnSelect={true}
                                autoplay={true}
                                speed={500}
                                slidesToScroll={1}
                                autoplaySpeed={5000}
                                pauseOnHover={false}
                            >
                                {images.map((ele, index) => {
                                    return <div className="imagesGroupWrap" key={index}>
                                        <img src={ele} alt="icon" />
                                    </div>
                                })}

                            </Slider>

                        </div>
                    </div>
                    <div className="sotry-tabs tab-container">

                        <div className="tab-content">
                            <Slider
                                className='tabSlider'
                                asNavFor={tabSliderRef}
                                {...tabSliderSettings}
                                ref={(tabSliderRef) => {
                                    setTabThumbRef(tabSliderRef);
                                }}
                                pauseOnHover={false}
                            // autoplay={true}
                            // speed={500}
                            // slidesToScroll={1}
                            // autoplaySpeed={500}
                            >
                                <div>
                                    <div className="tab">
                                        <div className="col-6 tab-left-section"><button className="audio-btn">Audio
                                            Visual (AV) </button>
                                            <div className="mobile-logo">
                                                <img src={companyLogo1} alt="company-logo-1" />
                                            </div>
                                            <h3><span>Simplifying moving to cloud for a </span><span>leading
                                                North American AV solutions</span><span>company</span></h3>
                                            <p>
                                                Today every software is being viewed and thought of as a Product. Every new Digital application is also thought of as a product hence Product Engineering is becoming the foundation to the Digital world.
                                            </p>
                                            <div className="progress-bar">
                                                <CustomLink to="#" renderSvg={true}>
                                                    Read the full story
                                                </CustomLink>
                                                {/* <button className="hoverMebottom button">Read
												the full story.</button> */}
                                            </div>
                                        </div>
                                        <div className="col-6 tab-right-section">
                                            <div className="tab-video ccr">
                                                <div className="video-text">
                                                    <p>Digialpha offered what we needed out of the box – plus we
                                                        wanted a system that we could reason about,
                                                        good documentation,
                                                        and had existing integrations.</p>
                                                    <h5>Andrei Horak,
                                                        CTO at Zensar<button><VideoIcon /></button></h5>
                                                </div>
                                            </div>
                                            <div className="progress-bar mobileShow">
                                                <CustomLink to="#" renderSvg={true}>
                                                    Read the full story
                                                </CustomLink>
                                            </div>
                                        </div>
                                        <div className="float-clear"></div>
                                    </div>
                                </div>
                                <div>
                                    <div className="tab">
                                        <div className="col-6 tab-left-section"><button className="audio-btn">Audio
                                            Visual (AV) </button>
                                            <div className="mobile-logo">
                                                <img src={companyLogo2} alt="company-logo-1" />
                                            </div>
                                            <h3><span>Simplifying moving to cloud for a </span><span>leading
                                                North American AV solutions</span><span>company</span></h3>
                                            <p>
                                                Today every software is being viewed and thought of as a Product. Every new Digital application is also thought of as a product hence Product Engineering is becoming the foundation to the Digital world.
                                            </p>
                                            <div className="progress-bar">
                                                <CustomLink to="#" renderSvg={true}>
                                                    Read the full story
                                                </CustomLink>
                                            </div>
                                        </div>
                                        <div className="col-6 tab-right-section">
                                            <div className="tab-video ccr">
                                                <div className="video-text">
                                                    <p>Digialpha offered what we needed out of the box – plus we
                                                        wanted a system that we could reason about,
                                                        good documentation,
                                                        and had existing integrations.</p>
                                                    <h5>Andrei Horak,
                                                        CTO at Zensar<button><VideoIcon /></button></h5>
                                                </div>
                                            </div>
                                            <div className="progress-bar mobileShow">
                                                <CustomLink to="#" renderSvg={true}>
                                                    Read the full story
                                                </CustomLink>
                                            </div>
                                        </div>
                                        <div className="float-clear"></div>
                                    </div>
                                </div>

                                <div>
                                    <div className="tab">
                                        <div className="col-6 tab-left-section"><button className="audio-btn">Audio
                                            Visual (AV) </button>
                                            <div className="mobile-logo">
                                                <img src={companyLogo3} alt="company-logo-1" />
                                            </div>
                                            <h3><span>Simplifying moving to cloud for a </span><span>leading
                                                North American AV solutions</span><span>company</span></h3>
                                            <p>
                                                Today every software is being viewed and thought of as a Product. Every new Digital application is also thought of as a product hence Product Engineering is becoming the foundation to the Digital world.
                                            </p>
                                            <div className="progress-bar">
                                                <CustomLink to="#" renderSvg={true}>
                                                    Read the full story
                                                </CustomLink>
                                            </div>
                                        </div>
                                        <div className="col-6 tab-right-section">
                                            <div className="tab-video ccr">
                                                <div className="video-text">
                                                    <p>Digialpha offered what we needed out of the box – plus we
                                                        wanted a system that we could reason about,
                                                        good documentation,
                                                        and had existing integrations.</p>
                                                    <h5>Andrei Horak,
                                                        CTO at Zensar<button><VideoIcon /></button></h5>
                                                </div>
                                            </div>
                                            <div className="progress-bar mobileShow">
                                                <CustomLink to="#" renderSvg={true}>
                                                    Read the full story
                                                </CustomLink>
                                            </div>
                                        </div>
                                        <div className="float-clear"></div>
                                    </div>
                                </div>

                                <div>
                                    <div className="tab">
                                        <div className="col-6 tab-left-section"><button className="audio-btn">Audio
                                            Visual (AV) </button>
                                            <div className="mobile-logo">
                                                <img src={companyLogo4} alt="company-logo-1" />
                                            </div>
                                            <h3><span>Simplifying moving to cloud for a </span><span>leading
                                                North American AV solutions</span><span>company</span></h3>
                                            <p>
                                                Today every software is being viewed and thought of as a Product. Every new Digital application is also thought of as a product hence Product Engineering is becoming the foundation to the Digital world.
                                            </p>
                                            <div className="progress-bar">
                                                <CustomLink to="#" renderSvg={true}>
                                                    Read the full story
                                                </CustomLink>
                                            </div>
                                        </div>
                                        <div className="col-6 tab-right-section">
                                            <div className="tab-video ccr">
                                                <div className="video-text">
                                                    <p>Digialpha offered what we needed out of the box – plus we
                                                        wanted a system that we could reason about,
                                                        good documentation,
                                                        and had existing integrations.</p>
                                                    <h5>Andrei Horak,
                                                        CTO at Zensar <button><VideoIcon /></button></h5>
                                                </div>
                                            </div>
                                            <div className="progress-bar mobileShow"><CustomLink to="#" renderSvg={true}>
                                                Read the full story
                                            </CustomLink></div>
                                        </div>
                                        <div className="float-clear"></div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
                <WhyBetaCraft />

                <div className="ecosystem-section">
                    <div className="row container ">
                        <div className="col-1 ecosystem-content">
                            <h5>Partner Ecosystem</h5>
                            <h1>We are a partnership driven company
                            </h1>
                            <p>We form partnerships and strategic alliances with world-class
                                organisations to expand our service offerings and deliver
                                comprehensive solutions to clients. Strategic alliances with the
                                world’s leading companies enable us to provide complete
                                solutions to your business and IT challenges. </p>
                            <div className="progress-bar mobileHide">
                                <CustomLink to="#" renderSvg={true}>
                                    Learn More
                                </CustomLink>
                                {/* <button className="hoverMebottom button"></button> */}
                            </div>
                        </div>
                        <div className="col-2 ecosystem-img">
                            <div className="marque-section ">
                                <div className="area">
                                    <ul className="circles">
                                        <li className="img-4"><img src={salesForceIcon} alt="Sales Force Icon" /></li>
                                        <li className="img-2"><img src={imbIcon} alt="Imb Icon" /></li>
                                        <li className="img-3"><img src={awsIcon} alt="Aws Icon" /></li>
                                        <li className="img-1"><img src={windowsIcon} alt="Windows Icon" /></li>
                                        <li className="img-5"><img src={redHatIcon} alt="RedHat Icon" /></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="progress-bar mobileShow">
                                {/* <button className="hoverMebottom button">Discover More</button> */}
                                <CustomLink to="#" renderSvg={true}>
                                    Learn More
                                </CustomLink>
                            </div>
                        </div>
                    </div>
                </div>
                <Blog showAll={true} blogData={blogData} featuredData={featuredData} className="gray-section" />
                <GetInTouch />
                {/*  <div className="insight-scetion">
                    <div className="container">
                        <div className="heading pd-top">
                            <div className="heading-text">
                                <h5>Latest Thinkings </h5>
                                <h1>News and Insights </h1>
                            </div>
                            <div className="slider-btn">
                                <button onClick={prevSlide} className="pre-btn slide-arrow prev-arrow icon-arrow-left"></button>
                                <button onClick={nextSlide} className="next-btn slide-arrow next-arrow icon-arrow-right"></button>
                            </div>
                        </div>
                    </div>
                </div>
            <Blog /> */}
            </div>
        </div>
    </div>
}

export default DataAndAnalyticsPage;