import { caseStudyConstants } from "../Constants";

const initialState = {
  caseStudyResults: null,
  loading: false,
  caseStudyErrors: null,
};

const caseStudyReducer = (state = initialState, action) => {
  switch (action.type) {
    case caseStudyConstants.RESET_FEATURED_CASESTUDY_REQUEST:
      return {
        caseStudyResults: null,
        featuredCaseStudy: null
      };
    case caseStudyConstants.CASESTUDY_REQUEST:
      return {
        ...state,
        loading: true,
        caseStudyErrors: null,
      };
    case caseStudyConstants.CASESTUDY_SUCCESS:
      return {
        ...state,
        caseStudyResults: action.response.data,
        loading: false,
        caseStudyErrors: null,
      };
    case caseStudyConstants.CASESTUDY_FAILURE:
      return {
        ...state,
        caseStudyResults: null,
        loading: true,
        caseStudyErrors: action.error,
      };
    case caseStudyConstants.FEATURED_CASESTUDY_REQUEST:
      return {
        ...state,
        featuredCaseStudy: null,
        loading: true,
        featuredCaseStudyError: null,
      };
    case caseStudyConstants.FEATURED_CASESTUDY_SUCCESS:
      return {
        ...state,
        featuredCaseStudy: action.response.data,
        loading: false,
        featuredCaseStudyError: null,
      };
    case caseStudyConstants.FEATURED_CASESTUDY_FAILURE:
      return {
        ...state,
        featuredCaseStudy: null,
        loading: false,
        featuredCaseStudyError: action.error,
      };
    case caseStudyConstants.CASESTUDY_DETAILS_REQUEST:
      return {
        ...state,
        caseStudyDetails: null,
        loading: true,
        caseStudyDetailsError: null,
      };
    case caseStudyConstants.CASESTUDY_DETAILS_SUCCESS:
      return {
        ...state,
        caseStudyDetails: action.response.data,
        loading: false,
        caseStudyDetailsError: null,
      };
    case caseStudyConstants.CASESTUDY_DETAILS_ERROR:
      return {
        ...state,
        caseStudyDetails: null,
        loading: true,
        caseStudyDetailsError: action.error,
      };
    default:
      return state;
  }
};

export default caseStudyReducer;
